// Import helpers and utils
import { FIELD_TYPES } from 'helpers';
import { useQuestionsFilter } from './useQuestionsFilter';

export const QuestionsFilter = () => {
	const { data, renderFields, values, lang, t, isLoading } =
		useQuestionsFilter();

	if (!data) {
		return null;
	}

	const multiSelectLabelExtractor = (options, currentUuid) =>
		currentUuid === 'all'
			? null
			: options.find(({ uuid }) => uuid === currentUuid)?.name[lang];

	return renderFields({
		type: FIELD_TYPES.MULTI_SELECT_WITH_SEARCH_FILTER,
		name: 'questions',
		options: data,
		showAllOption: true,
		multiSelectLabelExtractor,
		currentMultiSelectValues: values?.questions,
		keyExtractor: ({ uuid }) => uuid,
		labelExtractor: ({ name }) => name[lang],
		md: 'auto',
		xs: 'auto',
		smallFieldStyle: true,
		emptyLabel: t('common.fields.question'),
		searchExtractor: 'children[1]',
		isLoading,
	});
};
