import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const Note = (props) => (
	<SvgIcon width="20" height="20" viewBox="0 0 25 25" fill="none" {...props}>
		<path
			d="M15.0872 10.6667H6.75391"
			stroke="currentColor"
			strokeWidth="1.25"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<rect
			x="18.4219"
			y="18.166"
			width="15"
			height="15"
			rx="3.33333"
			transform="rotate(180 18.4219 18.166)"
			stroke="currentColor"
			strokeWidth="1.25"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M15.0872 13.9987H6.75391"
			stroke="currentColor"
			strokeWidth="1.25"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M15.0885 7.33268H10.9219"
			stroke="currentColor"
			strokeWidth="1.25"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
	</SvgIcon>
);
