import React from 'react';

// Import components
import {
	SectionFilter,
	StatusFilter,
	SubSectionFilter,
} from 'views/HelpCenter/HelpCenterConfiguration/components';

export const AdditionalFilterFields = () => {
	return (
		<>
			<SectionFilter />
			<SubSectionFilter />
			<StatusFilter />
		</>
	);
};
