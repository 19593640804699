import { makeStyles } from '@material-ui/styles';
import { customResponsive } from 'helpers';

export const useStyles = makeStyles((theme) => ({
	container: {
		paddingTop: '12px',
		...customResponsive.max.md({
			display: 'flex',
			justifyContent: 'space-between',
		}),
	},
	inputsContainer: {
		display: 'flex',
		'& > :nth-child(1)': {
			'& div': {
				maxHeight: '49px',
				borderTopRightRadius: 0,
				borderBottomRightRadius: 0,
				borderTopLeftRadius: '8px !important',
				borderBottomLeftRadius: '8px !important',
			},
		},
		'& > :nth-child(2)': {
			'& div': {
				maxHeight: '49px',
				borderTopRightRadius: '8px !important',
				borderBottomRightRadius: '8px !important',
				borderTopLeftRadius: 0,
				borderBottomLeftRadius: 0,
			},
		},
		...customResponsive.max.md({
			width: '70%',
		}),
	},
	label: {
		color: theme.palette.gray.inputLabel,
		fontSize: '14px',
		fontWeight: 600,
		...customResponsive.max.md({
			alignSelf: 'center',
		}),
	},
}));
