export const initialValues = {
	is_active: 'all',
	sections: ['all'],
};

export const formatValues = (values) => {
	return {
		is_active: values.is_active === 'all' ? null : values.is_active,
		sections: values.sections?.includes('all') ? null : values.sections,
	};
};
