// Import helpers
import { FIELD_TYPES } from 'helpers';

export const COORDINATOR_FIELDS = ({ index, prefixes }) => [
	{
		name: `coordinators[${index}].name`,
		type: FIELD_TYPES.TEXT,
		label: 'common.fields.name',
		required: true,
		md: 6,
	},
	{
		type: FIELD_TYPES.COMBINED_INPUTS,
		md: 6,
		label: 'common.fields.contact_person_number',
		required: true,
		combinedFields: [
			{
				type: FIELD_TYPES.SELECT,
				name: `coordinators[${index}].prefix`,
				required: true,
				xs: 3,
				md: 3,
				options: prefixes,
				labelExtractor: (prefix) => prefix,
				keyExtractor: (prefix) => prefix,
			},
			{
				type: FIELD_TYPES.NUMBER,
				name: `coordinators[${index}].phone`,
				required: true,
				xs: 9,
				md: 9,
			},
		],
	},
	{
		type: FIELD_TYPES.TEXT,
		label: 'common.fields.email',
		name: `coordinators[${index}].email`,
		required: true,
		md: 6,
	},
	{
		type: FIELD_TYPES.TEXT,
		label: 'common.fields.coordinator_limit',
		name: `coordinators[${index}].booking_limit_amount_gross`,
		md: 6,
	},
];
