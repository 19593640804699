// Import helpers and utils
import { useSectionFilter } from './useSectionFilter';
import { FIELD_TYPES } from 'helpers';

export const SectionFilter = () => {
	const { data, renderFields, values, lang, t, isLoading } = useSectionFilter();

	if (!data) {
		return null;
	}

	const multiSelectLabelExtractor = (options, currentUuid) =>
		currentUuid === 'all'
			? null
			: options.find(({ uuid }) => uuid === currentUuid)?.name[lang];

	return renderFields({
		type: FIELD_TYPES.MULTI_SELECT_WITH_SEARCH_FILTER,
		name: 'sections',
		options: data,
		showAllOption: true,
		multiSelectLabelExtractor,
		currentMultiSelectValues: values?.sections,
		keyExtractor: ({ uuid }) => uuid,
		labelExtractor: ({ name }) => name[lang],
		md: 'auto',
		xs: 'auto',
		smallFieldStyle: true,
		emptyLabel: t('common.fields.section'),
		searchExtractor: 'children[1]',
		isLoading,
	});
};
