export const ACTION_MENU_POPUP_FIELD_TYPES = {
	TRANSACTIONS_HISTORY: 'transactions_history',
	SUBSCRIPTION_HISTORY: 'subscription_history',
	TRANSFER_BOOKINGS: 'transfer_bookings',
	BOOKINGS_HISTORY: 'bookings_history',
	DOWNLOAD_INVOICE: 'download_invoice',
	ADD_RATING: 'add_rating',
	ACTIVE: 'active',
	EDIT: 'edit',
	CONFIRM_REJECTION: 'confirm_rejection',
	ACCEPT_BOOKING: 'accept_booking',
	CANCEL_BOOKING: 'cancel_booking',
	REDIRECT: 'redirect',
	EDIT_PRICES: 'edit_prices',
	ASSIGN_OFFICES: 'assign_offices',
	CARS_LOG_HISTORY: 'cars_log_history',
	CAR_AVAILABILITY: 'car_availability',
	EXTEND: 'extend',
	CLOSE: 'close',
	CANCEL_PAYMENT: 'cancel_payment',
	VIEW: 'view',
	DELETE: 'delete',
	PRINT_BOOKING: 'print_booking',
};

export const ACTION_MENU_EXTRA_FIELD_TYPES = {
	EDIT: 'edit',
	TRANSACTIONS_HISTORY: 'transactions_history',
	BOOKINGS_HISTORY: 'bookings_history',
};

export const ACTIVE_ACTION_VARIANTS = {
	SWITCH: 'switch',
	TEXT: 'text',
};
