export const COLUMNS_ACCESSORS = {
	CREATED_AT: 'created_at',
	SUBSECTION: 'subsection',
	QUESTIONS: 'questions',
	ACTIONS: 'actions',
	SECTION: 'section',
	STATUS: 'status',
};

export const columns = [
	{
		Header: 'common.fields.questions',
		accessor: COLUMNS_ACCESSORS.QUESTIONS,
	},
	{
		Header: 'common.fields.sections',
		accessor: COLUMNS_ACCESSORS.SECTION,
	},
	{
		Header: 'common.fields.subsections',
		accessor: COLUMNS_ACCESSORS.SUBSECTION,
	},
	{
		Header: 'common.fields.created_at',
		accessor: COLUMNS_ACCESSORS.CREATED_AT,
	},
	{
		Header: 'common.fields.status',
		accessor: COLUMNS_ACCESSORS.STATUS,
	},
	{
		Header: 'common.fields.actions',
		accessor: COLUMNS_ACCESSORS.ACTIONS,
	},
];
