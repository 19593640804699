import React from 'react';
import { bool, func } from 'prop-types';

// Import components
import { ButtonProgress, Grid } from 'components/elements';

// Import utils
import { useTranslations } from 'components/utilities';

// Import assets
import { CheckMarkInsideCircleIcon, WrongInsideCircleIcon } from 'assets/icons';

// Import styles
import { useStyles } from '../../PreviewPopup.styles';

export const ActiveActionText = ({
	activeState,
	isActiveLoading,
	handleOnActiveSwitch,
	activeAction,
}) => {
	const { t } = useTranslations();
	const classes = useStyles();

	const Icon = activeState ? WrongInsideCircleIcon : CheckMarkInsideCircleIcon;

	return (
		<Grid item xs={12}>
			<ButtonProgress
				className={classes.button}
				fullWidth
				startIcon={<Icon className={classes.icon} />}
				onClick={() => handleOnActiveSwitch(activeAction)}
				isLoading={isActiveLoading}
				disabled={isActiveLoading}
			>
				{t(
					activeState
						? 'common.fields.set_as_inactive'
						: 'common.fields.set_as_active'
				)}
			</ButtonProgress>
		</Grid>
	);
};

ActiveActionText.propTypes = {
	activeState: bool.isRequired,
	isActiveLoading: bool.isRequired,
	handleOnActiveSwitch: func.isRequired,
	activeAction: func.isRequired,
};
