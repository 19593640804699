import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const Map = (props) => (
	<SvgIcon width="20" height="20" viewBox="0 0 25 25" fill="none" {...props}>
		<path
			d="M12.9219 3.66602C17.9039 3.66602 21.9219 7.68402 21.9219 12.666C21.9219 17.648 17.9039 21.666 12.9219 21.666"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M12.9219 21.666C7.93988 21.666 3.92188 17.648 3.92188 12.666C3.92188 7.68402 7.93988 3.66602 12.9219 3.66602"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M11.0894 4.72577C8.19937 9.55077 8.19937 15.7818 11.0894 20.6068C11.9354 22.0198 13.9084 22.0198 14.7544 20.6068C17.6444 15.7818 17.6444 9.55077 14.7544 4.72577C13.9074 3.31277 11.9354 3.31277 11.0894 4.72577Z"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M4.93359 8.52344C9.82559 10.0344 16.0216 10.0344 20.9126 8.52344"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M20.9126 16.8071C16.0206 15.2961 9.82459 15.2961 4.93359 16.8071"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
	</SvgIcon>
);
