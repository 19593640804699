import React from 'react';
import { Box } from '@material-ui/core';
import { bool } from 'prop-types';

// Import components
import { Typography, Button } from 'components/elements';
import { IconsDialog } from './components';

// Import utils and helpers
import { useTranslations } from 'components/utilities';
import { useSelectIcon } from './useSelectIcon';

// Import styles
import { useStyles } from './SelectIcon.styles';

export const SelectIcon = ({ disabled = false }) => {
	const classes = useStyles();
	const { t } = useTranslations();
	const { iconsDialogOpen, toggleIconsDialog, ChosenIcon, iconText } =
		useSelectIcon();

	return (
		<Box>
			<Typography className={classes.label}>
				{t('help_center.select_icon')}
				<Typography component="span"> *</Typography>
			</Typography>
			<Box className={classes.iconSelectionContainer}>
				{ChosenIcon ? (
					<Box className={classes.flex}>
						<ChosenIcon />
						<Typography>{iconText}</Typography>
					</Box>
				) : (
					<Typography>{t('help_center.select_an_icon')}</Typography>
				)}
				<Button
					text="common.buttons.select"
					type="primary_text"
					size="tiny"
					onClick={toggleIconsDialog}
					disabled={disabled}
				/>
			</Box>
			<IconsDialog open={iconsDialogOpen} onClose={toggleIconsDialog} />
		</Box>
	);
};

SelectIcon.propTypes = {
	disabled: bool,
};
