import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const Wallet = (props) => (
	<SvgIcon width="20" height="20" viewBox="0 0 25 25" fill="none" {...props}>
		<path
			d="M17.5185 13.791C17.3115 13.792 17.1445 13.96 17.1445 14.167C17.1445 14.374 17.3125 14.542 17.5195 14.541C17.7265 14.541 17.8945 14.373 17.8945 14.166C17.8945 13.959 17.7265 13.791 17.5185 13.791"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M3.92188 5.66602V19.166C3.92188 20.271 4.81687 21.166 5.92188 21.166H19.9219C21.0269 21.166 21.9219 20.271 21.9219 19.166V9.16602C21.9219 8.06102 21.0269 7.16602 19.9219 7.16602H5.42188C4.59387 7.16602 3.92188 6.49402 3.92188 5.66602V5.66602C3.92188 4.83802 4.59387 4.16602 5.42188 4.16602H17.9219"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
	</SvgIcon>
);
