import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const Navigation = (props) => (
	<SvgIcon width="20" height="20" viewBox="0 0 25 25" fill="none" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M14.7671 18.9787L18.8291 8.01973C19.1271 7.21573 18.3441 6.43273 17.5401 6.73073L6.57606 10.7957C5.65206 11.1387 5.72506 12.4687 6.68006 12.7087L11.6201 13.9497L12.8531 18.8737C13.0931 19.8297 14.4241 19.9027 14.7671 18.9787V18.9787Z"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
	</SvgIcon>
);
