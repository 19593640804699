import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const Settings = (props) => (
	<SvgIcon width="20" height="20" viewBox="0 0 25 25" fill="none" {...props}>
		<path
			d="M14.8319 10.7576C15.8863 11.812 15.8863 13.5216 14.8319 14.576C13.7774 15.6304 12.0679 15.6304 11.0135 14.576C9.95905 13.5216 9.95905 11.812 11.0135 10.7576C12.0679 9.70319 13.7774 9.70319 14.8319 10.7576"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M6.17028 12.666C6.17028 12.963 6.19728 13.26 6.23328 13.548L4.64528 14.79C4.29328 15.066 4.19728 15.559 4.42128 15.946L5.83328 18.389C6.05628 18.776 6.53028 18.939 6.94528 18.773L8.36728 18.202C8.64828 18.089 8.96128 18.134 9.21328 18.301C9.43328 18.447 9.66128 18.581 9.89728 18.701C10.1673 18.838 10.3633 19.083 10.4063 19.383L10.6233 20.896C10.6863 21.338 11.0653 21.666 11.5113 21.666H14.3283C14.7743 21.666 15.1533 21.338 15.2163 20.896L15.4333 19.384C15.4763 19.084 15.6743 18.837 15.9453 18.701C16.1803 18.583 16.4073 18.45 16.6263 18.305C16.8803 18.137 17.1943 18.089 17.4763 18.203L18.8953 18.773C19.3093 18.939 19.7833 18.776 20.0073 18.389L21.4193 15.946C21.6433 15.559 21.5473 15.065 21.1953 14.79L19.6073 13.548C19.6433 13.26 19.6703 12.963 19.6703 12.666C19.6703 12.369 19.6433 12.072 19.6073 11.784L21.1953 10.542C21.5473 10.266 21.6433 9.77302 21.4193 9.38602L20.0073 6.94302C19.7843 6.55602 19.3103 6.39302 18.8953 6.55902L17.4763 7.12902C17.1943 7.24202 16.8803 7.19502 16.6263 7.02702C16.4073 6.88202 16.1803 6.74902 15.9453 6.63102C15.6743 6.49502 15.4763 6.24802 15.4333 5.94802L15.2173 4.43602C15.1543 3.99402 14.7753 3.66602 14.3293 3.66602H11.5123C11.0663 3.66602 10.6873 3.99402 10.6243 4.43602L10.4063 5.95002C10.3633 6.24902 10.1663 6.49502 9.89728 6.63202C9.66128 6.75202 9.43328 6.88702 9.21328 7.03202C8.96028 7.19802 8.64728 7.24302 8.36628 7.13002L6.94528 6.55902C6.53028 6.39302 6.05628 6.55602 5.83328 6.94302L4.42128 9.38602C4.19728 9.77302 4.29328 10.267 4.64528 10.542L6.23328 11.784C6.19728 12.072 6.17028 12.369 6.17028 12.666V12.666Z"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
	</SvgIcon>
);
