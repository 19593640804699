import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	card: {
		padding: '24px',
		borderRadius: '16px',
		boxShadow: 'none',

		'& .MuiFormControlLabel-label *': {
			fontWeight: 400,
			fontSize: '16px',
			color: theme.palette.text.inputLabel,
		},
	},
	title: {
		color: theme.palette.text.inputLabel,
		fontSize: '18px',
		fontWeight: 600,
		marginBottom: '8px',
	},
	subtitle: {
		color: theme.palette.text.secondary,
		fontSize: '14px',
		fontWeight: 400,
		marginBottom: '24px',
	},
	search: {
		minWidth: '320px',
	},
	selectedSubtitle: {
		color: theme.palette.text.inputLabel,
		fontSize: '14px',
		fontWeight: 400,
		marginBottom: '24px',
	},
	unselectedSubtite: {
		color: theme.palette.text.inputLabel,
		fontSize: '14px',
		fontWeight: 400,
		marginBottom: '20px',
		marginTop: '32px',
	},
	optionsContainer: ({ optionsContainerHeight }) => ({
		minHeight: `${optionsContainerHeight}px`,
		alignItems: 'flex-start',
	}),
	searchIcon: {
		color: theme.palette.text[1],
	},
	showMoreButton: {
		marginTop: '24px',
	},
	searchContainer: {
		marginBottom: '12px',

		'& .MuiSelect-select': {
			minHeight: '46px',
		},
	},
}));
