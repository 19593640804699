import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	card: {
		padding: '24px',
		borderRadius: '16px',
		boxShadow: 'none',
	},
	title: {
		color: theme.palette.text.inputLabel,
		marginBottom: '8px',
	},
	subtitle: {
		color: theme.palette.text.secondary,
		marginBottom: '24px',
	},
	radioButtonsWrapper: {
		'& .MuiFormControlLabel-root': {
			marginBottom: '16px',
			alignItems: 'flex-start',
		},

		'& .MuiButtonBase-root': {
			paddingTop: 0,
		},
	},
}));
