// Import helpers
import { BOOKINGS_PAGES_TYPES } from 'helpers';

// Import columns
import { bookingsColumns } from './bookingsColumns';
import { extendedColumns } from './extendedColumns';
import { transferredColumns } from './transferredColumns';

export const getExportColumns = ({ pageType, isAdmin }) => {
	const { MAIN, EXTENDED, TRANSFERRED } = BOOKINGS_PAGES_TYPES;

	switch (pageType) {
		case MAIN:
			return bookingsColumns(isAdmin);
		case EXTENDED:
			return extendedColumns(isAdmin);
		case TRANSFERRED:
			return transferredColumns(isAdmin);

		default:
			return [];
	}
};

export const getExtraServicesSubColumns = ({ isOld }) => {
	const columns = [
		{
			Header: 'bookings.table.headers.extra_services_baby_seat',
			accessor: 'extra_services.child_seat',
		},
		{
			Header: 'bookings.table.headers.extra_services_unlimited_km',
			accessor: 'extra_services.unlimitedKm',
		},
		{
			Header: 'bookings.table.headers.extra_services_tam',
			accessor: 'extra_services.tam',
		},
		{
			Header: 'bookings.table.headers.extra_services_extra_driver',
			accessor: 'extra_services.extra_driver',
		},
		{
			Header: 'bookings.table.headers.extra_services_travel_auth',
			accessor: 'extra_services.auth_out_ksa',
		},
		{
			Header: 'bookings.table.headers.extra_services_cdw',
			accessor: 'extra_services.insurance',
		},
		{
			Header: 'bookings.table.headers.extra_services_fast_airport_delivery',
			accessor: 'extra_services.fast_delivery_at_airport',
		},
		{
			Header: 'bookings.table.headers.extra_services_non_smoke',
			accessor: 'extra_services.no_smoking_car',
		},
		{
			Header: 'bookings.table.headers.extra_services_delivery_and_return',
			accessor: 'extra_services.delivery_drop',
		},
		{
			Header: 'bookings.table.headers.extra_services_chauffeur',
			accessor: 'extra_services.driver',
		},
		{
			Header: 'bookings.table.headers.extra_services_intercity_drop_off',
			accessor: 'extra_services.dropCity',
		},
	];

	const convertHeader = ({ header, isOld }) => {
		if (isOld) {
			const splittedHeaderArray = header.split('.');
			const lastElement = splittedHeaderArray.at(-1);
			const convertedLastElement = `old_${lastElement}`;

			splittedHeaderArray.splice(-1, 1, convertedLastElement);

			return splittedHeaderArray.join('.');
		}

		return header;
	};

	return columns.map(({ Header, accessor }) => ({
		Header: convertHeader({ header: Header, isOld }),
		accessor: isOld ? `old_${accessor}` : accessor,
	}));
};
