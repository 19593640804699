import i18next from 'i18next';

// Import helpers
import { FIELD_TYPES } from 'helpers';

export const STATUS_OPTIONS = [
	{ label: 'common.fields.active', value: true },
	{ label: 'common.fields.inactive', value: false },
];

export const GET_STATUS_FIELD = ({ disabled }) => ({
	type: FIELD_TYPES.SELECT,
	name: 'is_active',
	options: STATUS_OPTIONS,
	labelExtractor: ({ label }) => i18next.t(label),
	keyExtractor: ({ value }) => value,
	disabled,
});
