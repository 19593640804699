import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const Location = (props) => (
	<SvgIcon width="20" height="20" viewBox="0 0 25 25" fill="none" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M13.3 15.1007L17.507 20.0087C17.811 20.3637 17.506 20.9037 17.045 20.8277L12.92 20.1397L8.79501 20.8267C8.33401 20.9037 8.02901 20.3627 8.33301 20.0077L12.54 15.0997C12.74 14.8677 13.1 14.8677 13.3 15.1007Z"
			stroke="#69696F"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M8.37688 14.894C8.09087 14.264 7.92188 13.571 7.92188 12.834C7.92188 10.073 10.1609 7.83398 12.9219 7.83398C15.6829 7.83398 17.9219 10.073 17.9219 12.834C17.9219 13.571 17.7529 14.264 17.4669 14.894"
			stroke="#69696F"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M20.4097 17.8339C22.8687 14.1609 22.3367 9.07892 18.8077 6.01892C15.4847 3.13692 10.3627 3.13692 7.03968 6.01892C3.51068 9.07892 2.97868 14.1609 5.43768 17.8339"
			stroke="#69696F"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
	</SvgIcon>
);
