import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const WrongInsideCircleIcon = (props) => (
	<SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
		<path
			id="Path"
			d="M14.828 9.16992L9.16797 14.8299"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			id="Path_2"
			d="M14.828 14.8299L9.16797 9.16992"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			id="Path_3"
			fillRule="evenodd"
			clipRule="evenodd"
			d="M12 21V21C7.029 21 3 16.971 3 12V12C3 7.029 7.029 3 12 3V3C16.971 3 21 7.029 21 12V12C21 16.971 16.971 21 12 21Z"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
	</SvgIcon>
);
