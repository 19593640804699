// Import helpers
import { FIELD_TYPES } from 'helpers';

export const CONTENT_ARTICLE_FIELDS = (disabled) => [
	{
		type: FIELD_TYPES.RICH_TEXT,
		label: 'common.fields.english_text',
		name: 'content.en',
		required: true,
		redAstrick: true,
		md: 12,
		minHeight: '167px',
		maxLength: 200,
		disabled,
	},
	{
		type: FIELD_TYPES.RICH_TEXT,
		label: 'common.fields.arabic_text',
		name: 'content.ar',
		required: true,
		redAstrick: true,
		md: 12,
		minHeight: '167px',
		maxLength: 200,
		disabled,
	},
];

export const QUICK_RESPONSE_FIELDS = (disabled) => [
	{
		type: FIELD_TYPES.TEXT,
		label: 'common.fields.english_text',
		name: 'content.en',
		multiline: true,
		required: true,
		redAstrick: true,
		maxLength: '200',
		minRows: 5,
		disabled,
	},
	{
		type: FIELD_TYPES.TEXT,
		label: 'common.fields.arabic_text',
		name: 'content.ar',
		multiline: true,
		required: true,
		redAstrick: true,
		maxLength: '200',
		minRows: 5,
		disabled,
	},
];
