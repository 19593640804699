import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const Offers = (props) => (
	<SvgIcon width="20" height="20" viewBox="0 0 25 25" fill="none" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M14.7947 17.2411L7.21509 21.0309C6.45231 21.4123 5.53106 21.2628 4.92802 20.6597V20.6597C4.32499 20.0567 4.17549 19.1355 4.55688 18.3727L8.34667 10.7931C8.49042 10.5056 8.76363 10.3047 9.08094 10.2532C9.39825 10.2017 9.72098 10.3059 9.94827 10.5332L15.0546 15.6395C15.2819 15.8668 15.3861 16.1895 15.3346 16.5068C15.2831 16.8241 15.0822 17.0973 14.7947 17.2411Z"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M5.92188 3.66602V4.16602"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M5.92188 7.66602V7.16602"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M7.92188 5.66602H7.42188"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M3.92188 5.66602H4.42188"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M12.9219 3.66602C13.4283 3.91923 13.8016 4.37771 13.9469 4.92493C14.0922 5.47215 13.9956 6.05543 13.6815 6.52654L12.9219 7.66602"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M17.9219 12.6654L19.0614 11.9057C19.5325 11.5917 20.1157 11.495 20.663 11.6404C21.2102 11.7857 21.6687 12.159 21.9219 12.6654"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M19.9219 17.666V18.166"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M19.9219 21.666V21.166"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M21.9219 19.666H21.4219"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M17.9219 19.666H18.4219"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M19.9219 3.66602V4.16602"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M19.9219 7.66602V7.16602"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M21.9219 5.66602H21.4219"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M17.9219 5.66602H18.4219"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M14.9219 10.666L15.9219 9.66602"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
	</SvgIcon>
);
