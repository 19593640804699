import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	iconContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		border: 'solid 1px',
		borderColor: 'rgba(5, 5, 15, 0.10)',
		borderRadius: '8px',
		cursor: 'pointer',
		width: '72px',
		height: '72px',
		padding: '20px',
	},
	chekedIconContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		border: 'solid 2px',
		borderColor: theme.palette.primary.main,
		borderRadius: '8px',
		cursor: 'pointer',
		width: '72px',
		height: '72px',
		padding: '20px',
	},
	icon: {
		color: theme.palette.gray[1],
	},
}));
