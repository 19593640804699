import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	container: {
		display: 'flex',
		alignItems: 'center',
		gap: '8px',
	},
	text: {
		fontSize: '12px',
		fontWeight: '400',
	},
}));
