import i18next from 'i18next';

// Import helpers
import { FIELD_TYPES } from 'helpers';

export const GET_PERMISSION_FIELD = ({ options, disabled }) => ({
	type: FIELD_TYPES.SELECT,
	name: 'permission',
	options: options,
	labelExtractor: ({ label }) => i18next.t(label),
	keyExtractor: ({ value }) => value,
	disabled,
});
