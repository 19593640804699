import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const Train = (props) => (
	<SvgIcon width="20" height="20" viewBox="0 0 25 25" fill="none" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M19.9277 15.263V9.83174C19.9277 7.62182 18.136 5.83008 15.926 5.83008H9.92354C7.71362 5.83008 5.92188 7.62182 5.92188 9.83174V15.263C5.92188 17.4169 7.07636 18.4053 8.94613 19.4738L9.94655 20.045C11.7923 21.0994 14.0573 21.0994 15.903 20.045L16.9034 19.4738C18.7732 18.4053 19.9277 17.4169 19.9277 15.263Z"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M9.92188 2.82833H15.9244"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M11.426 5.82938V2.82812"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M14.426 5.82938V2.82812"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M5.92188 11.8322H19.9277"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M8.94531 15.2634L9.94573 15.8347"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M16.9028 15.2634L15.9023 15.8347"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M18.9264 21.8363L17.2227 19.2812"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M6.92188 21.8363L8.62558 19.2812"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
	</SvgIcon>
);
