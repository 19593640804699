import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const ExtendBooking = (props) => (
	<SvgIcon width="20" height="20" viewBox="0 0 25 25" fill="none" {...props}>
		<path
			d="M21.9219 13.666V16.666C21.9219 19.4274 19.6833 21.666 16.9219 21.666V21.666"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M10.9219 3.66602H8.92188C6.16045 3.66602 3.92188 5.90459 3.92188 8.66602V10.666"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M10.9219 20.166H6.92188V20.666C6.92188 21.2183 6.47416 21.666 5.92188 21.666H4.92188C4.36959 21.666 3.92188 21.2183 3.92188 20.666V18.916C3.92187 17.9495 4.70538 17.166 5.67188 17.166H12.1719C13.1384 17.166 13.9219 17.9495 13.9219 18.916V20.666C13.9219 21.2183 13.4742 21.666 12.9219 21.666H11.9219C11.3696 21.666 10.9219 21.2183 10.9219 20.666V20.166Z"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M5.0625 17.2817L5.6374 14.8243C5.79612 14.1459 6.40117 13.666 7.09796 13.666H10.7485C11.4453 13.666 12.0503 14.1459 12.209 14.8243L12.7839 17.2817"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M5.16103 16.7878L3.92188 16.1653"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M12.6836 16.7878L13.9227 16.1653"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<circle
			cx="18.4219"
			cy="6.66602"
			r="4"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M18.1719 5.26562V6.91562H19.8219"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
	</SvgIcon>
);
