import React from 'react';
import { bool, func } from 'prop-types';

// Import components
import {
	CustomSwitch,
	Grid,
	LoadingWrapper,
	Typography,
} from 'components/elements';

// Import utils
import { useTranslations } from 'components/utilities';

// Import styles
import { useStyles } from '../../PreviewPopup.styles';

export const ActiveActionSwitch = ({
	activeState,
	isActiveLoading,
	handleOnActiveSwitch,
	activeAction,
}) => {
	const { t } = useTranslations();
	const classes = useStyles();

	return (
		<Grid item xs={12}>
			<Grid container justifyContent="space-between" alignItems="center">
				<Grid item xs="auto">
					<Typography className={classes.activeLabel}>
						{t(
							activeState ? 'common.filters.active' : 'common.filters.inactive'
						)}
					</Typography>
				</Grid>
				<Grid item xs="auto">
					{isActiveLoading ? (
						<LoadingWrapper size={24} className={classes.loadingWrapper} />
					) : (
						<CustomSwitch
							onChange={() => handleOnActiveSwitch(activeAction)}
							defaultChecked={activeState}
							color="primary"
						/>
					)}
				</Grid>
			</Grid>
		</Grid>
	);
};

ActiveActionSwitch.propTypes = {
	activeState: bool.isRequired,
	isActiveLoading: bool.isRequired,
	handleOnActiveSwitch: func.isRequired,
	activeAction: func.isRequired,
};
