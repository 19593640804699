import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const Key = (props) => (
	<SvgIcon width="20" height="20" viewBox="0 0 25 25" fill="none" {...props}>
		<path
			d="M15.9531 10.0068C15.9531 9.79981 15.7851 9.63281 15.5781 9.63281C15.3711 9.63381 15.2031 9.80181 15.2031 10.0088C15.2031 10.2158 15.3711 10.3838 15.5781 10.3828C15.7851 10.3828 15.9531 10.2148 15.9531 10.0078"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M4.42188 17.8608L10.1449 12.1248C9.33888 10.0308 9.77087 7.56877 11.4559 5.87977C13.7359 3.59477 17.4329 3.59477 19.7119 5.87977C21.9919 8.16477 21.9919 11.8698 19.7119 14.1558C18.0109 15.8608 15.5229 16.2898 13.4209 15.4518L7.71988 21.1658H4.42188C4.42188 21.1658 4.42188 17.8608 4.42188 17.8608Z"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
	</SvgIcon>
);
