import * as Yup from 'yup';
import i18next from 'i18next';

// Import helpers
import { yupValidator } from 'helpers';

const questionsValidationSchema = () => {
	const requiredMessage = i18next.t('common.fields.required_field');

	return Yup.object().shape({
		name: Yup.object({
			en: Yup.string().required(requiredMessage),
			ar: Yup.string().required(requiredMessage),
		}).required(requiredMessage),
		permission: Yup.string().required(requiredMessage),
		is_active: Yup.bool().required(requiredMessage),
		subsection_uuids: Yup.array().min(1, requiredMessage),
		type: Yup.string().required(requiredMessage),
	});
};

export const validateQuestions = (values) =>
	yupValidator({
		values,
		validationSchema: questionsValidationSchema(),
	});
