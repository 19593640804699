import React from 'react';

// Import components
import { FormWrapper } from '../components';
import { FormContent } from './components';

// Import helpers
import { INITIAL_VALUES, handleSubmit } from './helpers';
import { validateSections } from './validation';

export const SectionsForm = () => {
	return (
		<FormWrapper
			initialValues={INITIAL_VALUES}
			onSubmit={handleSubmit}
			validate={validateSections}
		>
			<FormContent />
		</FormWrapper>
	);
};
