import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	label: {
		color: theme.palette.text.inputLabel,
	},
	description: {
		color: theme.palette.text.secondary,
	},
}));
