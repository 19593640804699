// Import store
import { createSolution, editSolution } from 'store/actions';

// Import helpers
import { HELP_CENTER_TYPES } from 'helpers';
import { helpCenterErrorCallback, helpCenterSuccessCallback } from '../helpers';
import { SOLUTION_PERMISSIONS } from './components/SolutionType/helpers';

export const INITIAL_VALUES = {
	name: { en: '', ar: '' },
	is_active: false,
	type: null,
	content: { en: '', ar: '' },
	question_uuids: [],
	permission_filter: SOLUTION_PERMISSIONS.ALL_AGENTS,
};

export const handleSubmit = ({ values, message, history, isEdit, uuid }) => {
	if (isEdit) {
		return editSolution({
			uuid,
			data: values,
			successCallback: () =>
				helpCenterSuccessCallback({
					history,
					type: HELP_CENTER_TYPES.SOLUTIONS,
					message,
				}),
			errorCallback: (error) => helpCenterErrorCallback({ error, message }),
		});
	}
	return createSolution({
		data: values,
		successCallback: () =>
			helpCenterSuccessCallback({
				history,
				type: HELP_CENTER_TYPES.SOLUTIONS,
				message,
			}),
		errorCallback: (error) => helpCenterErrorCallback({ error, message }),
	});
};
