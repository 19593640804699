import i18next from 'i18next';

// Import assets

import {
	Coupons,
	PaymentsAndRefunds,
	BookingAndModifications,
	DriverDelayed,
	CarTechnicalProblem,
	ReportAnAccident,
	ExtendBooking,
	GeneralInfo,
	OtherBookingIssues,
	Note,
	Call,
	Folder,
	FoldServiceer,
	Navigation,
	Discounts,
	KnowledgeBook,
	Bookmarks,
	Offers,
	Gift,
	Location,
	HandMoney,
	Transfer,
	Train,
	Bank,
	Diamond,
	TwentyFourSeven,
	Bag,
	Suitcase,
	Dashboard,
	Flag,
	Heart,
	Key,
	Lock,
	Settings,
	Privacy,
	Star,
	Checkmark,
	Thunder,
	QuestionMark,
	Building,
	Store,
	Hand,
	Mail,
	Wallet,
	HandHeart,
	Umbrella,
	Map,
	Directions,
	Money,
	Shopping,
	Ticket,
} from 'assets/icons/helpCenter';

// Import helpers
import {
	CHIP_COLORS,
	HELP_CENTER_TYPES,
	HELP_CENTER_FORM_ENTRIES,
	replaceHelpCenterListUrl,
} from 'helpers';
import { URLS } from 'components/routes';

export const STATUS_LIST = [
	{
		label: 'common.filters.active',
		color: CHIP_COLORS.SUCCESS,
		status: 'active',
	},
	{
		label: 'common.filters.inactive',
		color: CHIP_COLORS.ERROR,
		status: 'inactive',
	},
];

export const TABLE_NAVIGATION_LINKS = [
	{
		label: 'common.buttons.sections',
		url: replaceHelpCenterListUrl(HELP_CENTER_TYPES.SECTIONS),
	},
	{
		label: 'common.buttons.subsections',
		url: replaceHelpCenterListUrl(HELP_CENTER_TYPES.SUBSECTIONS),
	},
	{
		label: 'common.buttons.questions',
		url: replaceHelpCenterListUrl(HELP_CENTER_TYPES.QUESTIONS),
	},
	{
		label: 'common.buttons.solutions',
		url: replaceHelpCenterListUrl(HELP_CENTER_TYPES.SOLUTIONS),
	},
];

export const SOLUTION_TYPES = {
	CONTENT_ARTICLE: 'content_article',
	CHAT_RESPONSE: 'chat_response',
};

export const QUESTIONS_TYPES = {
	CHAT_WITH_RENTAL_COMPANY: 'chat_with_rental_company',
	CHAT_WITH_TELGANI: 'chat_with_telgani',
	CONTENT_ARTICLE: 'content_article',
};

export const PERMISSIONS_OPTIONS_DEFAULT = [
	{ label: 'common.fields.all_users', value: 'all' },
	{
		label: 'common.fields.users_with_active_bookings',
		value: 'with_active_booking',
	},
];

export const helpCenterSuccessCallback = ({ history, type, message }) => {
	history.push(URLS.helpCenterConfiguration.replace(':type', type));
	message.success(i18next.t('common.messages.successfully_saved'));
};

export const helpCenterErrorCallback = ({ error, message }) => {
	if (error?.response?.data?.error) {
		message.error(error.response.data.error);
	} else {
		message.error(i18next.t('common.messages.error_message'));
	}
};

export const IconsMap = {
	coupons: Coupons,
	payments_and_refunds: PaymentsAndRefunds,
	booking_and_modifications: BookingAndModifications,
	driver_delayed: DriverDelayed,
	car_technical_problem: CarTechnicalProblem,
	report_an_accident: ReportAnAccident,
	extend_booking: ExtendBooking,
	general_info: GeneralInfo,
	other_booking_issues: OtherBookingIssues,
	note: Note,
	call: Call,
	folder: Folder,
	fold_serviceer: FoldServiceer,
	navigation: Navigation,
	discounts: Discounts,
	knowledge_book: KnowledgeBook,
	bookmarks: Bookmarks,
	offers: Offers,
	gift: Gift,
	location: Location,
	money: Money,
	hand_money: HandMoney,
	transfer: Transfer,
	train: Train,
	bank: Bank,
	diamond: Diamond,
	twenty_four_seven: TwentyFourSeven,
	bag: Bag,
	suitcase: Suitcase,
	dashboard: Dashboard,
	flag: Flag,
	heart: Heart,
	key: Key,
	lock: Lock,
	settings: Settings,
	privacy: Privacy,
	star: Star,
	checkmark: Checkmark,
	thunder: Thunder,
	question_mark: QuestionMark,
	building: Building,
	store: Store,
	hand: Hand,
	mail: Mail,
	wallet: Wallet,
	hand_heart: HandHeart,
	umbrella: Umbrella,
	map: Map,
	directions: Directions,
	shopping: Shopping,
	ticket: Ticket,
};

const getTitlesObj = (type) => ({
	addTitle: `help_center.create_new_${type}`,
	editTitle: `help_center.edit_${type}`,
	viewTitle: `help_center.view_${type}`,
	btnAdd: `common.buttons.create_${type}`,
	btnEdit: `help_center.edit_${type}`,
});

export const getHelpCenterTitles = ({ entry, type }) => {
	const singularType = type.slice(0, -1);
	const { addTitle, editTitle, viewTitle, btnAdd, btnEdit } =
		getTitlesObj(singularType);
	switch (entry) {
		case HELP_CENTER_FORM_ENTRIES.ADD:
			return { title: addTitle, btnText: btnAdd };
		case HELP_CENTER_FORM_ENTRIES.EDIT:
			return { title: editTitle, btnText: btnEdit };
		case HELP_CENTER_FORM_ENTRIES.VIEW:
			return { title: viewTitle, btnText: btnEdit };
		default:
			return { title: addTitle, btnText: btnAdd };
	}
};
