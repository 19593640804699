import * as yup from 'yup';
import { t } from 'i18next';
import phone from 'phone';

const invalidPhoneNumberText = t('common.fields.invalid_phone_number');
const invalidEmailText = t('common.fields.invalid_email');
const requiredText = t('common.fields.required_field');

export const validationSchema = yup.object({
	coordinators: yup.array().of(
		yup.object({
			phone: yup
				.string()
				.required(requiredText)
				.test('mobile', invalidPhoneNumberText, function (value) {
					const prefix = this.parent.prefix || '';
					const fullNumber = `${prefix}${value}`;
					const { isValid } = phone(fullNumber);
					return isValid;
				}),
			name: yup.string().required(requiredText),
			booking_limit_amount_gross: yup.number().required(requiredText),
			email: yup.string().required(requiredText).email(invalidEmailText),
		})
	),
	affiliated_partner_company: yup.string().required(requiredText),
});
