// Import helpers
import { createSection, editSection, setPositions } from 'store/actions';
import { HELP_CENTER_TYPES } from 'helpers';
import { helpCenterErrorCallback, helpCenterSuccessCallback } from '../helpers';

export const NEW_SECTION = 'new_section';

export const INITIAL_VALUES = {
	name: { en: '', ar: '' },
	permission: 'all',
	is_active: false,
	positions: [],
};

const sectionsSuccessCallback = ({
	history,
	type,
	message,
	positions,
	resp,
}) => {
	const data = {
		sections: positions.map(({ id, position }) => ({
			uuid: id === NEW_SECTION ? resp.data.uuid : id,
			position,
		})),
	};
	return setPositions({
		data,
		successCallback: () =>
			helpCenterSuccessCallback({
				history,
				type,
				message,
			}),
		errorCallback: (error) => helpCenterErrorCallback({ error, message }),
	});
};

export const handleSubmit = ({ values, message, history, isEdit, uuid }) => {
	const { positions, ...rest } = values;
	if (isEdit) {
		return editSection({
			uuid,
			data: rest,
			successCallback: (resp) =>
				sectionsSuccessCallback({
					history,
					type: HELP_CENTER_TYPES.SECTIONS,
					message,
					positions,
					resp,
				}),
			errorCallback: (error) => helpCenterErrorCallback({ error, message }),
		});
	}
	return createSection({
		data: rest,
		successCallback: (resp) =>
			sectionsSuccessCallback({
				history,
				type: HELP_CENTER_TYPES.SECTIONS,
				message,
				positions,
				resp,
			}),
		errorCallback: (error) => helpCenterErrorCallback({ error, message }),
	});
};
