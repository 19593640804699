// Import helpers
import { QUESTIONS_TYPES, SOLUTION_TYPES } from 'views/HelpCenter/helpers';
import { SOLUTION_PERMISSIONS } from '../SolutionType/helpers';

const { CHAT_RESPONSE, CONTENT_ARTICLE: SOLUTION_CONTENT_ARTICLE } =
	SOLUTION_TYPES;
const { ALL_AGENTS, TELGANI_AGENTS, COMPANY_AGENTS } = SOLUTION_PERMISSIONS;
const { CHAT_WITH_RENTAL_COMPANY, CHAT_WITH_TELGANI, CONTENT_ARTICLE } =
	QUESTIONS_TYPES;

export const getFilteredQuestions = ({ questions, permission, type }) => {
	switch (true) {
		case type === CHAT_RESPONSE && permission === ALL_AGENTS:
			return questions.filter(
				({ type }) =>
					type === CHAT_WITH_RENTAL_COMPANY || type === CHAT_WITH_TELGANI
			);

		case type === CHAT_RESPONSE && permission === TELGANI_AGENTS:
			return questions.filter(({ type }) => type === CHAT_WITH_TELGANI);

		case type === CHAT_RESPONSE && permission === COMPANY_AGENTS:
			return questions.filter(({ type }) => type === CHAT_WITH_RENTAL_COMPANY);

		case type === SOLUTION_CONTENT_ARTICLE:
			return questions.filter(({ type }) => type === CONTENT_ARTICLE);

		default:
			return [];
	}
};
