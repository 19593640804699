import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	card: {
		padding: '24px',
		borderRadius: '16px',
		boxShadow: 'none',
	},
	title: {
		color: theme.palette.text.inputLabel,
		fontSize: '18px',
		fontWeight: 600,
		marginBottom: '8px',
	},
	subtitle: {
		color: theme.palette.text.secondary,
		fontSize: '14px',
		fontWeight: 400,
		marginBottom: '24px',
	},
	statusContainer: ({ status }) => ({
		'& .MuiSelect-root': {
			background: status
				? theme.palette.success.lighter
				: theme.palette.error.lighter,
			color: status ? theme.palette.success.medium : theme.palette.error.medium,
		},
		'& .MuiSelect-icon': {
			color: status ? theme.palette.success.medium : theme.palette.error.medium,
		},
	}),
}));
