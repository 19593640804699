import React from 'react';
import { Box } from '@material-ui/core';
import { bool, func } from 'prop-types';
import { DialogTitle, DialogContent, DialogActions } from '@material-ui/core';

// Import assets
import { XIcon } from 'assets/icons';

// import components
import { Dialog, Button, Typography } from 'components/elements';
import { IconContainer } from './components';

// Import styles
import { useStyles } from './IconsDialog.styles';

// Import utils and helpers
import { useTranslations } from 'components/utilities';
import { useIconsDialog } from './useIconsDialog';
import { HELP_CENTER_ICONS } from 'helpers';

export const IconsDialog = ({ open, onClose }) => {
	const classes = useStyles();
	const { t } = useTranslations();
	const { handleIconClick, isIconChecked, handleCancel, handleSave } =
		useIconsDialog({ onClose });

	return (
		<Dialog open={open} onClose={onClose} className={classes.dialog}>
			<DialogTitle className={classes.dialogTitle}>
				<Typography className={classes.dialogTitleText}>
					{t('help_center.select_an_icon')}
				</Typography>
				<XIcon className={classes.closeIcon} onClick={handleCancel} />
			</DialogTitle>
			<DialogContent>
				<Box className={classes.iconsContainer}>
					{HELP_CENTER_ICONS.map(({ icon, value }) => {
						return (
							<IconContainer
								key={value}
								icon={icon}
								checked={isIconChecked(value)}
								handleOnClick={() => handleIconClick(value)}
							/>
						);
					})}
				</Box>
			</DialogContent>
			<DialogActions>
				<Button
					text="common.buttons.cancel"
					type="dark_text"
					onClick={handleCancel}
				/>
				<Button text="common.buttons.save" onClick={handleSave} />
			</DialogActions>
		</Dialog>
	);
};

IconsDialog.propTypes = {
	open: bool.isRequired,
	onClose: func.isRequired,
};
