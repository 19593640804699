import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const BookingAndModifications = (props) => (
	<SvgIcon width="20" height="20" viewBox="0 0 25 25" fill="none" {...props}>
		<path
			d="M9.17188 12.166H7.17188"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M18.8047 8.95939L20.9217 7.90039"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M6.03988 8.95939L3.92188 7.90039"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M18.8728 9.32155L17.9091 5.20964C17.6972 4.30538 16.8906 3.66601 15.9618 3.66602H8.88362C7.95486 3.66602 7.14832 4.30539 6.93639 5.20964L5.97266 9.32155"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M15.6719 12.166H17.6719"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M20.9219 12.666V12.166C20.9219 10.5092 19.5787 9.16602 17.9219 9.16602H6.92188C5.26502 9.16602 3.92188 10.5092 3.92188 12.166V16.666C3.92188 17.2183 4.36959 17.666 4.92188 17.666H7.15788C7.71016 17.666 8.15788 17.2183 8.15788 16.666V15.166H13.9219"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M18.9219 15.666V16.916"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M18.9219 21.416V22.666"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M20.8711 20.2902L21.9225 20.8973"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M21.9225 17.4348L20.8711 18.0418"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M18.9219 21.416C17.6792 21.416 16.6719 20.4087 16.6719 19.166C16.6719 17.9234 17.6792 16.916 18.9219 16.916C20.1645 16.916 21.1719 17.9234 21.1719 19.166C21.1719 20.4087 20.1645 21.416 18.9219 21.416"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M16.9733 18.0418L15.9219 17.4348"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M15.9219 20.8973L16.9733 20.2902"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
	</SvgIcon>
);
