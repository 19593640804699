import React from 'react';
import { object, string } from 'prop-types';

// Import components
import { NewActionsMenuTableCell } from 'components/elements';

// Import helpers
import { getActionMenuFields } from './helpers';
import { HELP_CENTER_TYPES } from 'helpers';

export const Actions = ({ cell, type = HELP_CENTER_TYPES.SECTIONS }) => {
	const { uuid, is_active: isActive } = cell.row.original;
	return (
		<NewActionsMenuTableCell
			previewPopupFields={getActionMenuFields({
				isActive,
				uuid,
				type,
			})}
			cell={cell}
		/>
	);
};

Actions.propTypes = {
	cell: object.isRequired,
	type: string,
};
