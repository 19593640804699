import React from 'react';
import { string, bool, node } from 'prop-types';
import { Box } from '@material-ui/core';

// Import comonents
import { Typography } from 'components/elements/atoms';

// Import utils
import { useTranslations } from 'components/utilities';

// Import styles
import { useStyles } from './CombineTwoInputs.styles';

export const CombineTwoInputs = ({ children, label, required = false }) => {
	const { t } = useTranslations();
	const classes = useStyles();

	return (
		<Box className={classes.container}>
			<Typography className={classes.label}>
				{t(label)} {required && '*'}
			</Typography>
			<Box className={classes.inputsContainer}>{children}</Box>
		</Box>
	);
};

CombineTwoInputs.propTypes = {
	children: node.isRequired,
	label: string,
	required: bool,
};
