import { useFormState } from 'react-final-form';

// Import helpers
import { useToggle } from 'components/utilities';
import { IconsMap } from 'helpers';

export const useSelectIcon = () => {
	const { on: iconsDialogOpen, toggle: toggleIconsDialog } = useToggle();

	const {
		values: { icon },
	} = useFormState();

	const ChosenIcon = icon ? IconsMap[icon] : null;

	return { iconsDialogOpen, toggleIconsDialog, ChosenIcon, iconText: icon };
};
