import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const GeneralInfo = (props) => (
	<SvgIcon width="20" height="20" viewBox="0 0 25 25" fill="none" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M8.92188 10.666H5.92188C4.81687 10.666 3.92188 9.77102 3.92188 8.66602V5.66602C3.92188 4.56102 4.81687 3.66602 5.92188 3.66602H8.92188C10.0269 3.66602 10.9219 4.56102 10.9219 5.66602V8.66602C10.9219 9.77102 10.0269 10.666 8.92188 10.666Z"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M19.9219 10.666H16.9219C15.8169 10.666 14.9219 9.77102 14.9219 8.66602V5.66602C14.9219 4.56102 15.8169 3.66602 16.9219 3.66602H19.9219C21.0269 3.66602 21.9219 4.56102 21.9219 5.66602V8.66602C21.9219 9.77102 21.0269 10.666 19.9219 10.666Z"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M8.92188 21.666H5.92188C4.81687 21.666 3.92188 20.771 3.92188 19.666V16.666C3.92188 15.561 4.81687 14.666 5.92188 14.666H8.92188C10.0269 14.666 10.9219 15.561 10.9219 16.666V19.666C10.9219 20.771 10.0269 21.666 8.92188 21.666Z"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M20.9219 16.2168H15.9219"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M15.9219 20.1152H20.9219"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
	</SvgIcon>
);
