import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const Thunder = (props) => (
	<SvgIcon width="20" height="20" viewBox="0 0 25 25" fill="none" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M13.8889 3.66602L5.67188 14.666H12.9219L11.9549 21.666L20.1719 10.666H12.9219L13.8889 3.66602Z"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
	</SvgIcon>
);
