import { useEffect } from 'react';
import { useFormState } from 'react-final-form';

// import utils and helpers
import { FIELD_TYPES } from 'helpers';
import { useSectionsList } from 'queries';
import { useTranslations } from 'components/utilities';

export const useSearchFilter = ({ disabled, setOptions, allOptions }) => {
	const { data: sectionsList } = useSectionsList();
	const {
		i18n: { language: lang },
		t,
	} = useTranslations();
	const {
		values: { searchFilter },
	} = useFormState();

	const sectionOptions = sectionsList.map(({ name, uuid }) => ({
		label: name[lang],
		value: uuid,
	}));
	const options = [
		{ label: t('help_center.all_sections'), value: 'all' },
		...sectionOptions,
	];

	const FILTER = {
		type: FIELD_TYPES.SELECT,
		name: 'searchFilter',
		options: options,
		keyExtractor: ({ value }) => value,
		labelExtractor: ({ label }) => label,
		smallFieldStyle: true,
		md: 'auto',
		xs: 'auto',
		disabled,
	};

	useEffect(() => {
		/* eslint-disable no-mixed-spaces-and-tabs */
		const filteredOptions =
			searchFilter === 'all'
				? allOptions
				: allOptions.filter((option) =>
						option.section_uuids.includes(searchFilter)
				  );

		setOptions(filteredOptions);
		// eslint-disable-next-line
	}, [searchFilter]);

	return { FILTER };
};
