import { array, arrayOf, bool, func, shape, string } from 'prop-types';

// Import helpers
import { useSearchFilter } from './useSearchFilter';
import { useCommonFields } from 'components/utilities';

export const SearchFilter = ({ disabled, setOptions, allOptions }) => {
	const { FILTER } = useSearchFilter({ disabled, setOptions, allOptions });
	const { renderFields } = useCommonFields();

	return renderFields(FILTER);
};

SearchFilter.propTypes = {
	disabled: bool,
	setOptions: func.isRequired,
	allOptions: arrayOf(
		shape({
			label: string.isRequired,
			value: string.isRequired,
			section_uuids: array.isRequired,
		})
	).isRequired,
};
