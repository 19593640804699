import React from 'react';

// Import components
import { FormWrapper } from '../components';
import { FormContent } from './components';

// Import helpers
import { handleSubmit, INITIAL_VALUES } from './helpers';
import { validateSolutions } from './validation';

export const SolutionsForm = () => {
	return (
		<FormWrapper
			title="help_center.create_new_solution"
			initialValues={INITIAL_VALUES}
			submitBtnText="common.buttons.create_solution"
			onSubmit={handleSubmit}
			validate={validateSolutions}
		>
			<FormContent />
		</FormWrapper>
	);
};
