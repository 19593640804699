// Import assets

import {
	Coupons,
	PaymentsAndRefunds,
	BookingAndModifications,
	DriverDelayed,
	CarTechnicalProblem,
	ReportAnAccident,
	ExtendBooking,
	GeneralInfo,
	OtherBookingIssues,
	Note,
	Call,
	Folder,
	FoldServiceer,
	Navigation,
	Discounts,
	KnowledgeBook,
	Bookmarks,
	Offers,
	Gift,
	Location,
	HandMoney,
	Transfer,
	Train,
	Bank,
	Diamond,
	TwentyFourSeven,
	Bag,
	Suitcase,
	Dashboard,
	Flag,
	Heart,
	Key,
	Lock,
	Settings,
	Privacy,
	Star,
	Checkmark,
	Thunder,
	QuestionMark,
	Building,
	Store,
	Hand,
	Mail,
	Wallet,
	HandHeart,
	Umbrella,
	Map,
	Directions,
	Money,
	Shopping,
	Ticket,
} from 'assets/icons/helpCenter';

export const IconsMap = {
	coupons: Coupons,
	payments_and_refunds: PaymentsAndRefunds,
	booking_and_modifications: BookingAndModifications,
	driver_delayed: DriverDelayed,
	car_technical_problem: CarTechnicalProblem,
	report_an_accident: ReportAnAccident,
	extend_booking: ExtendBooking,
	general_info: GeneralInfo,
	other_booking_issues: OtherBookingIssues,
	note: Note,
	call: Call,
	folder: Folder,
	fold_serviceer: FoldServiceer,
	navigation: Navigation,
	discounts: Discounts,
	knowledge_book: KnowledgeBook,
	bookmarks: Bookmarks,
	offers: Offers,
	gift: Gift,
	location: Location,
	money: Money,
	hand_money: HandMoney,
	transfer: Transfer,
	train: Train,
	bank: Bank,
	diamond: Diamond,
	twenty_four_seven: TwentyFourSeven,
	bag: Bag,
	suitcase: Suitcase,
	dashboard: Dashboard,
	flag: Flag,
	heart: Heart,
	key: Key,
	lock: Lock,
	settings: Settings,
	privacy: Privacy,
	star: Star,
	checkmark: Checkmark,
	thunder: Thunder,
	question_mark: QuestionMark,
	building: Building,
	store: Store,
	hand: Hand,
	mail: Mail,
	wallet: Wallet,
	hand_heart: HandHeart,
	umbrella: Umbrella,
	map: Map,
	directions: Directions,
	shopping: Shopping,
	ticket: Ticket,
};

export const HELP_CENTER_ICONS = [
	{ icon: Coupons, value: 'coupons' },
	{ icon: PaymentsAndRefunds, value: 'payments_and_refunds' },
	{ icon: BookingAndModifications, value: 'booking_and_modifications' },
	{ icon: DriverDelayed, value: 'driver_delayed' },
	{ icon: CarTechnicalProblem, value: 'car_technical_problem' },
	{ icon: ReportAnAccident, value: 'report_an_accident' },
	{ icon: ExtendBooking, value: 'extend_booking' },
	{ icon: GeneralInfo, value: 'general_info' },
	{ icon: OtherBookingIssues, value: 'other_booking_issues' },
	{ icon: Note, value: 'note' },
	{ icon: Call, value: 'call' },
	{ icon: Folder, value: 'folder' },
	{ icon: FoldServiceer, value: 'fold_serviceer' },
	{ icon: Navigation, value: 'navigation' },
	{ icon: Discounts, value: 'discounts' },
	{ icon: KnowledgeBook, value: 'knowledge_book' },
	{ icon: Bookmarks, value: 'bookmarks' },
	{ icon: Offers, value: 'offers' },
	{ icon: Gift, value: 'gift' },
	{ icon: Location, value: 'location' },
	{ icon: Money, value: 'money' },
	{ icon: HandMoney, value: 'hand_money' },
	{ icon: Transfer, value: 'transfer' },
	{ icon: Train, value: 'train' },
	{ icon: Bank, value: 'bank' },
	{ icon: Diamond, value: 'diamond' },
	{ icon: TwentyFourSeven, value: 'twenty_four_seven' },
	{ icon: Bag, value: 'bag' },
	{ icon: Suitcase, value: 'suitcase' },
	{ icon: Dashboard, value: 'dashboard' },
	{ icon: Flag, value: 'flag' },
	{ icon: Heart, value: 'heart' },
	{ icon: Key, value: 'key' },
	{ icon: Lock, value: 'lock' },
	{ icon: Settings, value: 'settings' },
	{ icon: Privacy, value: 'privacy' },
	{ icon: Star, value: 'star' },
	{ icon: Checkmark, value: 'checkmark' },
	{ icon: Thunder, value: 'thunder' },
	{ icon: QuestionMark, value: 'question_mark' },
	{ icon: Building, value: 'building' },
	{ icon: Store, value: 'store' },
	{ icon: Hand, value: 'hand' },
	{ icon: Mail, value: 'mail' },
	{ icon: Wallet, value: 'wallet' },
	{ icon: HandHeart, value: 'hand_heart' },
	{ icon: Umbrella, value: 'umbrella' },
	{ icon: Map, value: 'map' },
	{ icon: Directions, value: 'directions' },
	{ icon: Shopping, value: 'shopping' },
	{ icon: Ticket, value: 'ticket' },
];
