export const HELP_CENTER_TYPES = {
	SECTIONS: 'sections',
	SUBSECTIONS: 'subsections',
	QUESTIONS: 'questions',
	SOLUTIONS: 'solutions',
};

export const HELP_CENTER_FORM_ENTRIES = {
	ADD: 'add',
	EDIT: 'edit',
	VIEW: 'view',
};
