import React from 'react';

// import components
import { Grid } from 'components/elements';
import {
	TitleSection,
	PermissionsSection,
	StatusSection,
	SearchSection,
} from 'views/HelpCenter/components';
import { QuestionType, SearchFilter } from './components';

// Import styles
import { useStyles } from './FormContent.styles';

// Import helpers
import { useFormContent } from './useFormContent';

export const FormContent = () => {
	const classes = useStyles();
	const {
		subsectionsOptions,
		isFetching,
		isViewOnly,
		setSubsectionOptions,
		subsectionsAllOptions,
	} = useFormContent();

	return (
		<Grid container spacing={3}>
			<Grid item md={8} xs={12} className={classes.flexCol}>
				<TitleSection
					title="help_center.question_title"
					disabled={isViewOnly}
				/>
				<QuestionType disabled={isViewOnly} />
				<SearchSection
					options={subsectionsOptions}
					name="subsection_uuids"
					title="help_center.assign_subsections"
					subtitle="help_center.assign_subsection_description"
					isFetching={isFetching}
					disabled={isViewOnly}
					filters={
						<SearchFilter
							disabled={isViewOnly}
							setOptions={setSubsectionOptions}
							allOptions={subsectionsAllOptions}
						/>
					}
				/>
			</Grid>
			<Grid item md={4} xs={12} className={classes.flexCol}>
				<PermissionsSection disabled={isViewOnly} />
				<StatusSection disabled={isViewOnly} />
			</Grid>
		</Grid>
	);
};
