import React from 'react';
import { Tooltip } from '@material-ui/core';
import { string } from 'prop-types';

// Import components
import { IconButton } from 'components/elements/atoms';

// Import styles
import { useStyles } from './TooltipComponent.styles';

// Import assets
import { InformationIcon } from 'assets/icons';

export const TooltipComponent = ({ hoverText }) => {
	const classes = useStyles();

	return (
		<Tooltip title={hoverText} placement="top" arrow>
			<IconButton className={classes.button}>
				<InformationIcon className={classes.icon} />
			</IconButton>
		</Tooltip>
	);
};

TooltipComponent.propTypes = {
	hoverText: string,
};
