import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const SixDotsItem = (props) => (
	<SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M9.41422 3.58579C10.1953 4.36684 10.1953 5.63317 9.41422 6.41422C8.63317 7.19527 7.36684 7.19527 6.58579 6.41422C5.80474 5.63317 5.80474 4.36684 6.58579 3.58579C7.36684 2.80474 8.63317 2.80474 9.41422 3.58579Z"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M9.41422 10.5858C10.1953 11.3668 10.1953 12.6332 9.41422 13.4142C8.63317 14.1953 7.36684 14.1953 6.58579 13.4142C5.80474 12.6332 5.80474 11.3669 6.58579 10.5858C7.36684 9.80475 8.63317 9.80475 9.41422 10.5858Z"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M9.41422 17.5858C10.1953 18.3668 10.1953 19.6332 9.41422 20.4142C8.63317 21.1953 7.36684 21.1953 6.58579 20.4142C5.80474 19.6332 5.80474 18.3669 6.58579 17.5858C7.36684 16.8048 8.63317 16.8048 9.41422 17.5858Z"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M17.4142 3.58579C18.1953 4.36684 18.1953 5.63317 17.4142 6.41422C16.6332 7.19527 15.3668 7.19527 14.5858 6.41422C13.8047 5.63317 13.8047 4.36684 14.5858 3.58579C15.3668 2.80474 16.6332 2.80474 17.4142 3.58579Z"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M17.4142 10.5858C18.1953 11.3668 18.1953 12.6332 17.4142 13.4142C16.6332 14.1953 15.3668 14.1953 14.5858 13.4142C13.8047 12.6332 13.8047 11.3668 14.5858 10.5858C15.3668 9.80475 16.6332 9.80475 17.4142 10.5858Z"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M17.4142 17.5858C18.1953 18.3668 18.1953 19.6332 17.4142 20.4142C16.6332 21.1953 15.3668 21.1953 14.5858 20.4142C13.8047 19.6332 13.8047 18.3668 14.5858 17.5858C15.3668 16.8047 16.6332 16.8047 17.4142 17.5858Z"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	</SvgIcon>
);
