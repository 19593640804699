import { bool } from 'prop-types';

// Import utils and helpers
import { useCommonFields } from 'components/utilities';
import { PERMISSION_FIELD } from './helpers';

export const PermissionFilter = ({ disabled = false }) => {
	const { renderFields } = useCommonFields();

	return renderFields(PERMISSION_FIELD({ disabled }));
};

PermissionFilter.propTypes = {
	disabled: bool,
};
