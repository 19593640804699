import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const HandMoney = (props) => (
	<SvgIcon width="20" height="20" viewBox="0 0 25 25" fill="none" {...props}>
		<path
			d="M13.9048 14.8338H21.4267C22.2554 14.8338 22.9273 14.162 22.9273 13.3332V7.3307C22.9273 6.50193 22.2554 5.83008 21.4267 5.83008H11.4225C10.5937 5.83008 9.92188 6.50193 9.92188 7.3307V13.8334"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M15.3633 9.27043C15.95 8.68917 16.8962 8.69139 17.4802 9.2754C18.0642 9.8594 18.0664 10.8056 17.4852 11.3923"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M9.92089 8.83203H7.62812C7.1622 8.83203 6.70268 8.9405 6.28595 9.14887L5.7005 9.4416C5.19 9.69683 4.76287 10.0923 4.46921 10.5818L2.91797 13.1671"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M3.91797 21.8361L9.68224 19.7532C10.4868 19.4625 11.177 18.9216 11.6516 18.2099L14.6457 13.7201C15.0812 13.067 14.9952 12.1974 14.4401 11.6424V11.6424C13.7973 10.9995 12.755 10.9994 12.112 11.6422L9.92047 13.8328H7.91964"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
	</SvgIcon>
);
