import * as yup from 'yup';
import { t } from 'i18next';

// Import helpers
import { yupValidator } from 'helpers';

const requiredText = t('common.fields.required_field');

export const validationSchema = yup.object({
	name: yup
		.object()
		.shape({
			en: yup.string().required(requiredText),
			ar: yup.string().required(requiredText),
		})
		.required(requiredText),
	is_active: yup.boolean().required(requiredText),
	type: yup.string().required(requiredText),
	content: yup
		.object()
		.shape({
			en: yup.string().required(requiredText),
			ar: yup.string().required(requiredText),
		})
		.required(requiredText),
});

export const validateSolutions = (values) =>
	yupValidator({
		values,
		validationSchema: validationSchema,
	});
