import { useHistory, useParams } from 'react-router-dom';

// import utils nad helpers
import { useMessage } from 'components/utilities';
import { HELP_CENTER_FORM_ENTRIES } from 'helpers';
import { getHelpCenterTitles } from 'views/HelpCenter/helpers';

export const useFormWrapper = () => {
	const { message } = useMessage();
	const history = useHistory();
	const { entry, id: uuid, form } = useParams();
	const isEdit = entry === HELP_CENTER_FORM_ENTRIES.EDIT;

	const { title, btnText } = getHelpCenterTitles({ entry, type: form });

	return { message, history, isEdit, uuid, title, btnText };
};
