// Import helpers
import {
	ACTION_MENU_POPUP_FIELD_TYPES,
	ACTIVE_ACTION_VARIANTS,
	replaceHelpCenterEditFormUrl,
} from 'helpers';
import {
	deleteHelpCenterRecord,
	updateActiveHelpCenterRecord,
} from 'store/actions';

const { ACTIVE, DELETE, VIEW, EDIT } = ACTION_MENU_POPUP_FIELD_TYPES;

const handleActiveAction = ({ isActive, uuid, type }) =>
	updateActiveHelpCenterRecord({ isActive, uuid, type });

export const getActionMenuFields = ({ isActive, uuid, type }) => [
	{
		type: ACTIVE,
		activeAction: () => handleActiveAction({ isActive, uuid, type }),
		activeState: isActive,
		isDivider: true,
		variant: ACTIVE_ACTION_VARIANTS.TEXT,
	},
	{ type: VIEW },
	{
		type: EDIT,
		isDivider: true,
		editViewUrl: replaceHelpCenterEditFormUrl({
			uuid,
			form: type,
		}),
	},
	{
		type: DELETE,
		deleteAction: () => deleteHelpCenterRecord({ uuid, type }),
	},
];
