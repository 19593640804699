import { convertFormDates, SERVICES_TYPES } from 'helpers';

export const initialValues = {
	license_number: '',
	is_kiosk: 'all',
	date_type: 'all',
	service_type: 'all',
	airport_uuid: 'all',
	pick_date: null,
	pick_date_to: null,
	period: 'all',
	has_rating: 'all',
	has_driver_assigned: 'all',
};

export const formatValues = (values) => ({
	...values,
	is_kiosk: values.is_kiosk === 'all' ? null : values.is_kiosk,
	service_type: values.service_type === 'all' ? null : values.service_type,
	date_type: values.date_type === 'all' ? null : values.date_type,
	period: values.period === 'all' ? null : values.period,
	airport_uuid:
		values.airport_uuid === 'all' ||
		values.service_type !== SERVICES_TYPES.AIRPORT
			? null
			: values.airport_uuid,
	has_rating: values.has_rating === 'all' ? null : values.has_rating,
	has_driver_assigned:
		values.has_driver_assigned === 'all' ? null : values.has_driver_assigned,
	...convertFormDates(values, ['pick_date']),
});
