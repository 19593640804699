import { number, oneOfType, string } from 'prop-types';

// Import utilities and helpers
import {
	useCommonFields,
	useTranslations,
	useProfile,
} from 'components/utilities';
import { FIELD_TYPES } from 'helpers';
import { getRoleOptions } from './helpers';

export const BookingCreatedByFilter = ({ label = '', name, xs, md }) => {
	const { renderFields } = useCommonFields();
	const { t } = useTranslations();
	const { isAdmin } = useProfile();

	const roleOptions = getRoleOptions({ isAdmin });

	return renderFields({
		type: FIELD_TYPES.SELECT,
		options: roleOptions,
		keyExtractor: ({ value }) => value,
		labelExtractor: ({ label }) => t(label),
		name,
		label,
		md,
		xs,
	});
};

BookingCreatedByFilter.propTypes = {
	label: string,
	name: string.isRequired,
	md: oneOfType([string, number]),
	xs: oneOfType([string, number]),
};
