import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const CheckMarkInsideCircleIcon = (props) => (
	<SvgIcon width="25" height="24" viewBox="0 0 25 24" fill="none" {...props}>
		<path
			id="Path"
			fillRule="evenodd"
			clipRule="evenodd"
			d="M12.75 21V21C7.779 21 3.75 16.971 3.75 12V12C3.75 7.029 7.779 3 12.75 3V3C17.721 3 21.75 7.029 21.75 12V12C21.75 16.971 17.721 21 12.75 21Z"
			stroke="currentColor"
			fill="none"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			id="Path_2"
			d="M16.75 10L11.75 15L8.75 12"
			stroke="currentColor"
			fill="none"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</SvgIcon>
);
