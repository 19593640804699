import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const Transfer = (props) => (
	<SvgIcon width="20" height="20" viewBox="0 0 25 25" fill="none" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M12.9199 21.834V21.834C7.95088 21.834 3.92188 17.805 3.92188 12.836V12.832C3.92188 7.86298 7.95088 3.83398 12.9199 3.83398H12.9239C17.8929 3.83398 21.9219 7.86298 21.9219 12.832V12.832C21.9219 17.804 17.8919 21.834 12.9199 21.834Z"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M18.4102 12.334L16.9102 10.834L15.4102 12.334"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M7.42188 13.334L8.92188 14.834L10.4219 13.334"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M8.92188 14.834V10.834C8.92188 9.72898 9.81687 8.83398 10.9219 8.83398H13.9219"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M16.9109 10.834V14.834C16.9109 15.934 16.0109 16.834 14.9109 16.834H11.9219"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
	</SvgIcon>
);
