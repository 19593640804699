import { useSelector } from 'react-redux';
import { format } from 'date-fns';

// Import utilities and helpers
import { BOOKING_EXTENSION_STATUS, DEFAULT_DATE_FORMAT } from 'helpers';

// Import store
import { previewBookingSelector } from 'store/selectors';

export const useCustomerAndBookingInfo = () => {
	const booking = useSelector(previewBookingSelector);
	const { customer, item } = booking;
	const { full_name: customerName } = customer || {};
	const { created_at: createdAt } = item || {};

	const isExtended =
		booking.extension?.extension_state === BOOKING_EXTENSION_STATUS.ACCEPTED ||
		booking.extension?.extension_state === BOOKING_EXTENSION_STATUS.PENDING;

	const bookingCreatedAt = createdAt
		? format(new Date(createdAt), DEFAULT_DATE_FORMAT)
		: '';

	return {
		isExtended,
		booking,
		customerName,
		bookingCreatedAt,
	};
};
