import { useState } from 'react';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, ContentState, convertToRaw } from 'draft-js';

export const useDraftWysiwygFieldAdapter = ({ input, maxLength, meta }) => {
	const setInitialValue = () => {
		if (input.value) {
			const blocksFromHtml = htmlToDraft(input.value);
			const { contentBlocks, entityMap } = blocksFromHtml;
			const contentState = ContentState.createFromBlockArray(
				contentBlocks,
				entityMap
			);
			return EditorState.createWithContent(contentState);
		} else {
			return EditorState.createEmpty();
		}
	};

	const [editorState, setEditorState] = useState(setInitialValue());

	const onEditorStateChange = (editorState) => {
		setEditorState(editorState);
		input.onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())));
	};

	const error = meta.touched && (meta.error || meta.submitError) ? true : false;

	const getPlainTextLength = (html) => {
		const div = document.createElement('div');
		div.innerHTML = html;
		return div.textContent.trim().length;
	};

	const plainTextLength = getPlainTextLength(input.value);

	const handleBeforeInput = () => {
		if (maxLength) {
			if (plainTextLength >= maxLength) {
				return 'handled';
			}
		}
	};

	const handlePastedText = (text) => {
		if (maxLength) {
			const remainingLength = maxLength - plainTextLength;
			if (remainingLength <= 0) {
				return 'handled';
			}

			if (text.length > remainingLength) {
				const truncatedText = text.substring(0, remainingLength);
				const contentState = ContentState.createFromText(truncatedText);
				const newEditorState = EditorState.push(
					editorState,
					contentState,
					'insert-characters'
				);
				onEditorStateChange(newEditorState);
				return 'handled';
			}
		}
		return 'not-handled';
	};

	return {
		handleBeforeInput,
		handlePastedText,
		editorState,
		onEditorStateChange,
		error,
		plainTextLength,
	};
};
