import React from 'react';
import { Button } from 'components/elements';
import { Link } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { string } from 'prop-types';

// Import assets
import { PlusIcon } from 'assets/icons';

// Import helpers
import { HELP_CENTER_TYPES } from 'helpers';

export const AddNewButton = ({ to, type = HELP_CENTER_TYPES.SECTIONS }) => {
	return (
		<Link component={RouterLink} to={to}>
			<Button
				text={`common.buttons.new_${type.slice(0, -1)}`}
				type="outlined_and_dark"
				icon={PlusIcon}
			/>
		</Link>
	);
};

AddNewButton.propTypes = {
	to: string.isRequired,
	type: string.isRequired,
};
