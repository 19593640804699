import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const KnowledgeBook = (props) => (
	<SvgIcon width="20" height="20" viewBox="0 0 25 25" fill="none" {...props}>
		<path
			d="M12.9219 5.66664C10.6919 3.43664 7.21887 3.20764 4.73387 4.97964C4.21587 5.34864 3.92188 5.96064 3.92188 6.59664V19.5526C3.92188 20.3266 4.74687 20.7756 5.42988 20.4116C7.83688 19.1286 10.8939 19.5016 12.9219 21.5306V5.66664C15.1519 3.43664 18.6249 3.20764 21.1099 4.97964C21.6279 5.34864 21.9219 5.96064 21.9219 6.59664V19.5526C21.9219 20.3266 21.0969 20.7766 20.4139 20.4116C18.0069 19.1286 14.9499 19.5016 12.9219 21.5306"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
	</SvgIcon>
);
