import { useForm, useFormState } from 'react-final-form';

export const usePositionsDialog = () => {
	const {
		values: { positions: sectionPositions },
	} = useFormState();
	const { change } = useForm();

	const sortPositions = (items) =>
		items.sort((a, b) => a.position - b.position);

	const getItemIndex = (array, item) =>
		array.findIndex((elem) => elem.id === item.id);

	const handleDragEnd = (event) => {
		const { active, over } = event;

		const positions = [...sectionPositions];
		const activeIdx = getItemIndex(positions, active);
		const overIdx = getItemIndex(positions, over);
		const activeOldPosition = sectionPositions[activeIdx].position;
		const overOldPosition = sectionPositions[overIdx].position;

		positions[activeIdx].position = overOldPosition;
		positions[overIdx].position = activeOldPosition;

		const sortedPositions = sortPositions(positions);

		change('positions', sortedPositions);
	};

	return { sectionPositions, handleDragEnd };
};
