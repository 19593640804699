import { useFormState } from 'react-final-form';

// Import queries
import { useSectionsList } from 'queries';

// Import utils
import { useCommonFields, useTranslations } from 'components/utilities';

export const useSectionFilter = () => {
	const { data, isLoading } = useSectionsList();
	const { values } = useFormState();

	const { renderFields } = useCommonFields();

	const {
		t,
		i18n: { language: lang },
	} = useTranslations();

	return {
		renderFields,
		isLoading,
		values,
		data,
		lang,
		t,
	};
};
