import React from 'react';

// Import components
import { BaseSelectFilter } from 'components/elements';

// Import helpers
import { STATUS_FILTER_OPTIONS } from './helpers';

export const StatusFilter = () => {
	return (
		<BaseSelectFilter
			allLabel="common.fields.status"
			options={STATUS_FILTER_OPTIONS}
			smallFieldStyle
			name="is_active"
			xs="auto"
			md="auto"
		/>
	);
};
