import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const ReportAnAccident = (props) => (
	<SvgIcon width="20" height="20" viewBox="0 0 25 25" fill="none" {...props}>
		<circle
			cx="12.9219"
			cy="12.666"
			r="6.5"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M12.9219 12.416V10.166"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M12.9719 15.1153C12.9718 15.1429 12.9494 15.1653 12.9218 15.1652C12.8942 15.1652 12.8719 15.1428 12.8719 15.1152C12.8719 15.0876 12.8942 15.0653 12.9218 15.0652C12.9494 15.0652 12.9718 15.0876 12.9719 15.1152V15.1153"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M20.0625 19.666C23.8762 15.7785 23.8762 9.55351 20.0625 5.66602"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M5.78217 19.666C1.96845 15.7785 1.96845 9.55351 5.78217 5.66602"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
	</SvgIcon>
);
