import React from 'react';
import { Card } from '@material-ui/core';
import { bool } from 'prop-types';
import { useFormState } from 'react-final-form';

// Import components
import { Grid, Typography } from 'components/elements';

// Import styles
import { useStyles } from './StatusSection.styles';

// Import utils and helpers
import { useCommonFields, useTranslations } from 'components/utilities';
import { GET_STATUS_FIELD } from './helpers';

export const StatusSection = ({ disabled = false }) => {
	const {
		values: { is_active: status },
	} = useFormState();
	const classes = useStyles({ status });
	const { renderFields } = useCommonFields();
	const { t } = useTranslations();
	const STATUS_FIELD = GET_STATUS_FIELD({ disabled });

	return (
		<Card className={classes.card}>
			<Typography className={classes.title}>
				{t('help_center.status')}
			</Typography>
			<Typography className={classes.subtitle}>
				{t('help_center.status_section_description')}
			</Typography>
			<Grid container className={classes.statusContainer}>
				{renderFields(STATUS_FIELD)}
			</Grid>
		</Card>
	);
};

StatusSection.propTypes = {
	disabled: bool,
};
