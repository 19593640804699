import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

// Import utils and helpers
import { useSubsectionsList } from 'queries';
import { useTranslations } from 'components/utilities';
import { HELP_CENTER_FORM_ENTRIES } from 'helpers';
import { useQuestionDetails } from 'queries';
import { useHelpCenterContextProvider } from 'views/HelpCenter/context/HelpCenterContext';

export const useFormContent = () => {
	const { id: uuid, entry } = useParams();
	const [subsectionsOptions, setSubsectionOptions] = useState([]);

	const {
		i18n: { language: lang },
	} = useTranslations();

	const { setInitialValues } = useHelpCenterContextProvider();

	const { data: questionDetails } = useQuestionDetails({
		enabled: !!uuid,
		uuid,
	});

	const { data: subsectionsList, isFetching } = useSubsectionsList();

	const subsectionsAllOptions = subsectionsList.map(
		({ name, uuid, section_uuids }) => ({
			label: name[lang],
			value: uuid,
			section_uuids,
		})
	);

	const isViewOnly = entry === HELP_CENTER_FORM_ENTRIES.VIEW;

	useEffect(() => {
		if (questionDetails) {
			setInitialValues({
				name: questionDetails.name,
				type: questionDetails.type,
				permission: questionDetails.permission,
				subsection_uuids: questionDetails.subsection_uuids,
				is_active: questionDetails.is_active,
			});
		}
		// eslint-disable-next-line
	}, [questionDetails]);

	useEffect(() => {
		setSubsectionOptions(subsectionsAllOptions);
		// eslint-disable-next-line
	}, [isFetching]);

	return {
		subsectionsOptions,
		isFetching,
		entry,
		isViewOnly,
		setSubsectionOptions,
		subsectionsAllOptions,
	};
};
