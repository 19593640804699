import { t } from 'i18next';

// Import helpers
import { FIELD_TYPES } from 'helpers';
import { SOLUTION_PERMISSIONS } from '../../../SolutionType/helpers';

const QUICK_RESPONSE_OPTIONS = [
	{
		label: 'help_center.permissions_filter.all_agents',
		value: SOLUTION_PERMISSIONS.ALL_AGENTS,
	},
	{
		label: 'help_center.permissions_filter.telgani_agents',
		value: SOLUTION_PERMISSIONS.TELGANI_AGENTS,
	},
	{
		label: 'help_center.permissions_filter.company_agents',
		value: SOLUTION_PERMISSIONS.COMPANY_AGENTS,
	},
];

export const PERMISSION_FIELD = ({ disabled }) => ({
	type: FIELD_TYPES.SELECT,
	name: 'permission_filter',
	md: 'auto',
	xs: 'auto',
	options: QUICK_RESPONSE_OPTIONS,
	keyExtractor: ({ value }) => value,
	labelExtractor: ({ label }) => t(label),
	smallFieldStyle: true,
	disabled,
});
