import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const Money = (props) => (
	<SvgIcon width="20" height="20" viewBox="0 0 25 25" fill="none" {...props}>
		<path
			d="M3.92188 9.83398C3.92188 10.939 6.15987 11.834 8.92188 11.834"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M8.92088 15.834C6.15988 15.834 3.92188 14.939 3.92188 13.834"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M13.9214 5.83398H13.9224V8.83398"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M11.9219 16.834C11.9219 17.939 14.1599 18.834 16.9219 18.834C19.6839 18.834 21.9219 17.939 21.9219 16.834"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M11.9219 12.834C11.9219 13.939 14.1599 14.834 16.9219 14.834C19.6839 14.834 21.9219 13.939 21.9219 12.834"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M16.9238 10.834C14.1638 10.834 11.9238 11.729 11.9258 12.834"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M11.9219 12.834V20.834C11.9219 21.939 14.1599 22.834 16.9219 22.834"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M16.9219 10.834C19.6819 10.834 21.9219 11.729 21.9199 12.834"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M21.9219 12.834V20.834C21.9219 21.939 19.6839 22.834 16.9219 22.834"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M8.92188 19.834C6.16088 19.834 3.92188 18.939 3.92188 17.834"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M8.92188 7.83398C11.6833 7.83398 13.9219 6.93855 13.9219 5.83398C13.9219 4.72941 11.6833 3.83398 8.92188 3.83398C6.16045 3.83398 3.92188 4.72941 3.92188 5.83398C3.92188 6.93855 6.16045 7.83398 8.92188 7.83398Z"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M3.92188 5.83398V17.834"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
	</SvgIcon>
);
