import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const HandHeart = (props) => (
	<SvgIcon width="20" height="20" viewBox="0 0 25 25" fill="none" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M6.90587 21.666H4.92188C4.36988 21.666 3.92188 21.218 3.92188 20.666V14.666C3.92188 14.114 4.36988 13.666 4.92188 13.666H6.90587C7.45787 13.666 7.90587 14.114 7.90587 14.666V20.666C7.90587 21.218 7.45787 21.666 6.90587 21.666Z"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M13.8822 17.6651H16.2063C16.6373 17.6651 17.0562 17.5251 17.4012 17.2651L19.8073 15.4531C20.4083 15.0011 21.2483 15.0611 21.7793 15.5941V15.5941C22.3683 16.1861 22.3683 17.1451 21.7793 17.7361L19.7153 19.8091C19.1593 20.3671 18.4512 20.7481 17.6792 20.9031L14.7603 21.4891C14.1823 21.6051 13.5852 21.5911 13.0122 21.4471L10.3733 20.7851C10.0583 20.7051 9.73325 20.6651 9.40725 20.6651H7.90625"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M13.8822 17.666H15.3612C16.1942 17.666 16.8702 16.988 16.8702 16.151V15.848C16.8702 15.153 16.3993 14.547 15.7273 14.379L13.4443 13.806C13.0733 13.713 12.6922 13.666 12.3102 13.666V13.666C11.3872 13.666 10.4843 13.94 9.71625 14.455L7.90625 15.666"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M16.2092 4.32669L15.8602 4.69969L15.5102 4.32669C14.7432 3.50869 13.4712 3.44169 12.6232 4.17369V4.17369C11.7562 4.92169 11.6532 6.21869 12.3662 7.11669C13.3862 8.40069 14.5552 9.66869 15.8602 10.6657C17.1982 9.64369 18.3922 8.33769 19.4302 7.02069C20.1042 6.16569 19.9652 4.92469 19.1432 4.21369L19.1052 4.18069C18.2492 3.44169 16.9762 3.50869 16.2092 4.32669V4.32669Z"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
	</SvgIcon>
);
