import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

// Import utils and helpers
import { useSectionsList } from 'queries';
import { useTranslations } from 'components/utilities';
import { HELP_CENTER_FORM_ENTRIES } from 'helpers';
import { useSubsectionDetails } from 'queries';
import { useHelpCenterContextProvider } from 'views/HelpCenter/context/HelpCenterContext';

export const useFormContent = () => {
	const { id: uuid, entry } = useParams();
	const { setInitialValues } = useHelpCenterContextProvider();
	const {
		i18n: { language: lang },
	} = useTranslations();

	const { data: sections, isFetching } = useSectionsList();

	const sectionOptions = sections.map(({ name, uuid }) => ({
		label: name[lang],
		value: uuid,
	}));

	const { data: subsectionDetails } = useSubsectionDetails({
		enabled: !!uuid,
		uuid,
	});

	const isViewOnly = entry === HELP_CENTER_FORM_ENTRIES.VIEW;

	useEffect(() => {
		if (subsectionDetails) {
			setInitialValues({
				name: subsectionDetails.name,
				permission: subsectionDetails.permission,
				section_uuids: subsectionDetails.section_uuids,
				is_active: subsectionDetails.is_active,
				icon: subsectionDetails.icon,
			});
		}
		// eslint-disable-next-line
	}, [subsectionDetails]);

	return { sectionOptions, isFetching, isViewOnly };
};
