import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	container: {
		padding: theme.spacing(4),
		position: 'relative',
		minHeight: '100vh',
	},
	title: {
		color: theme.palette.text.inputLabel,
		fontSize: '28px',
		fontWeight: 500,
		marginBottom: '28px',
	},
}));
