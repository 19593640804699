import { useState } from 'react';
import { useFormState, useForm } from 'react-final-form';

export const useIconsDialog = ({ onClose }) => {
	const {
		values: { icon },
	} = useFormState();
	const { change } = useForm();
	const [selectedIcon, setSelectedIcon] = useState(icon);
	const handleIconClick = (value) => {
		setSelectedIcon(value);
	};

	const isIconChecked = (value) => value === selectedIcon;

	const handleCancel = () => {
		setSelectedIcon(icon);
		onClose();
	};

	const handleSave = () => {
		change('icon', selectedIcon);
		onClose();
	};

	return {
		handleIconClick,
		isIconChecked,
		handleCancel,
		handleSave,
	};
};
