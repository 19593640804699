import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	icon: {
		width: '16px',
		height: '16px',
		color: theme.palette.primary.main,
	},
	button: {
		padding: 0,
	},
}));
