import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const QuestionMark = (props) => (
	<SvgIcon width="20" height="20" viewBox="0 0 25 25" fill="none" {...props}>
		<circle
			cx="12.9217"
			cy="12.6659"
			r="9.00375"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M10.6094 10.3499C10.8464 9.3283 11.7768 8.62 12.8246 8.66348C13.9973 8.59853 15.0026 9.49213 15.0756 10.6643C15.0756 12.1689 12.9247 12.6652 12.9247 13.6656"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M13.0471 16.4162C13.0471 16.4853 12.9911 16.5413 12.9221 16.5413C12.853 16.5413 12.797 16.4853 12.797 16.4162C12.797 16.3472 12.853 16.2912 12.9221 16.2912"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M12.9221 16.2912C12.9911 16.2912 13.0471 16.3472 13.0471 16.4162"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
	</SvgIcon>
);
