import React from 'react';
import { string } from 'prop-types';
import { Box } from '@material-ui/core';

// import components
import { Button } from 'components/elements';

// Import styles
import { useStyles } from './BottomBar.styles';

// Import helpers
import { useBottomBar } from './useBottomBar';

export const BottomBar = ({ submitBtnText }) => {
	const classes = useStyles();

	const { isSubmitDisabled, btnLoading, isViewOnly, onCancel } = useBottomBar();

	return (
		<Box className={classes.container}>
			{!isViewOnly && (
				<>
					<Button
						text="common.buttons.cancel"
						type="dark_text"
						onClick={onCancel}
					/>
					<Button
						text={submitBtnText}
						buttonType="submit"
						disabled={isSubmitDisabled}
						isLoading={btnLoading}
					/>
				</>
			)}
		</Box>
	);
};

BottomBar.propTypes = {
	submitBtnText: string.isRequired,
};
