import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const Suitcase = (props) => (
	<SvgIcon width="20" height="20" viewBox="0 0 25 25" fill="none" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M19.9219 21.166H5.92188C4.81687 21.166 3.92188 20.271 3.92188 19.166V10.166C3.92188 9.06102 4.81687 8.16602 5.92188 8.16602H19.9219C21.0269 8.16602 21.9219 9.06102 21.9219 10.166V19.166C21.9219 20.271 21.0269 21.166 19.9219 21.166Z"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M17.0941 8.16602V6.16602C17.0941 5.06102 16.1991 4.16602 15.0941 4.16602H10.7461C9.64109 4.16602 8.74609 5.06102 8.74609 6.16602V8.16602"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M3.92188 10.166L10.2729 14.357C10.5999 14.573 10.9829 14.688 11.3749 14.688H14.4689C14.8609 14.688 15.2439 14.573 15.5709 14.357L21.9219 10.166"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
	</SvgIcon>
);
