import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { ENDPOINTS, QUERY_KEYS } from 'helpers';

export const useSectionsList = () => {
	const { data, isError, isFetching } = useQuery(
		QUERY_KEYS.HELP_CENTER.SECTIONS.SECTIONS_LIST,
		async () =>
			await services.get(ENDPOINTS.HELP_CENTER.SECTIONS.SECTIONS_LIST),
		{ refetchOnWindowFocus: false }
	);

	const fetchedData = data?.data || [];

	return { data: fetchedData, isFetching, isError };
};
