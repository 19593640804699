import _ from 'lodash';

export const getFormData = (values, isEdit) => {
	const omitPaths = ['logo_url', 'uuid', 'coordinators', 'logo'];

	if (values.affiliated_partner_company === 'none') {
		omitPaths.push('affiliated_partner_company');
	}

	const omittedValues = _.omit(values, omitPaths);

	const convertedValues = Object.entries(omittedValues);

	let formData = new FormData();

	isEdit && formData.append('_method', 'PUT');
	convertedValues.forEach(([key, value]) => {
		value && formData.append(key, value);
	});

	formData.append('logo', values.logo.binary);

	return formData;
};
