import { findIndex } from 'lodash';

const paramConnect = (prevParam, nextParam) => {
	if (prevParam !== '') {
		return nextParam !== '' ? '&' : '';
	} else if (prevParam === '' && nextParam !== '') {
		return '?';
	} else {
		return '';
	}
};

export const setQueryParams = ({
	options,
	columns,
	isDataTable,
	customGlobalSearchColumn,
}) => {
	const { sortBy, globalFilter } = options;

	let sortParams = '';

	const customGlobalFilter = globalFilter ?? '';

	const customGlobalSearchColumnIndex =
		findIndex(columns, {
			name: customGlobalSearchColumn,
		}) || 0;

	let globalFilterParam = globalFilter
		? customGlobalSearchColumn
			? `columns[${customGlobalSearchColumnIndex}][search][value]=${globalFilter}`
			: `search[value]=${globalFilter}`
		: '';

	if (!isDataTable)
		globalFilterParam = customGlobalFilter
			? `global_search=${customGlobalFilter}`
			: '';

	if (sortBy.length > 0) {
		const findColumns = columns.findIndex(
			(columns) => columns.name === sortBy[0].id.split('.')[0]
		);
		const sortIndex = `order[0][column]=${findColumns}`;
		const sortDir = `order[0][dir]=${sortBy[0].desc ? 'asc' : 'desc'}`;
		sortParams = `${sortIndex}&${sortDir}`;
	}

	const isSortParams = sortParams === '' ? '' : `?${sortParams}`;
	const isGlobalFilterParams =
		globalFilterParam === '' ? '' : globalFilterParam;

	return `${isSortParams}${paramConnect(
		isSortParams,
		globalFilterParam
	)}${isGlobalFilterParams}`;
};

export const setFilterParams = (filters, columns, queryParams) => {
	if (filters) {
		const filtersParams = Object.entries(filters)
			.reduce((acc, [filterKey, filterValue]) => {
				const index = columns.findIndex(({ name }) => name === filterKey);
				if (index !== -1) {
					filterValue !== null &&
						acc.push(`columns[${index}][search][value]=${filterValue}`);
				} else {
					filterValue !== null && acc.push(`${filterKey}=${filterValue}`);
				}
				return acc;
			}, [])
			.join('&');

		const isQueryParams = queryParams.includes('?');
		return isQueryParams ? `&${filtersParams}` : `?${filtersParams}`;
	}

	return '';
};

export const combineQueryAndPaginationParams = ({ queryParams, body }) =>
	`${queryParams || '?'}${queryParams === '?' ? '' : '&'}start=${
		body.start
	}&length=${body.length}&is_export=${!!body.is_export}`;
