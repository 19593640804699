import React from 'react';
import { arrayOf, string, shape, bool, node } from 'prop-types';
import {
	Card,
	TextField,
	InputAdornment,
	CircularProgress,
} from '@material-ui/core';

// Import assets
import { SearchIcon } from 'assets/icons';

// Import components
import { Button, Grid, Typography } from 'components/elements';

// Import styles
import { useStyles } from './SearchSection.styles';

// Import utils and helpers
import { useTranslations, useCommonFields } from 'components/utilities';
import { useSearchSection } from './useSearchSection';

export const SearchSection = ({
	name,
	options,
	title,
	subtitle,
	showMore = false,
	filters = null,
	isFetching = false,
	disabled = false,
}) => {
	const { renderFields } = useCommonFields();
	const { t } = useTranslations();
	const {
		onSearch,
		someSelected,
		optionsContainerHeight,
		FIELDS_UNSELECTED,
		FIELDS_SELECTED,
		onShowMore,
		buttonText,
	} = useSearchSection({
		name,
		options,
		showMore,
		isFetching,
		disabled,
	});
	const classes = useStyles({ optionsContainerHeight });

	return (
		<Card className={classes.card}>
			<Typography className={classes.title}>{t(title)}</Typography>
			<Typography className={classes.subtitle}>{t(subtitle)}</Typography>

			<Grid container spacing={3} className={classes.searchContainer}>
				{filters && filters}
				<Grid item md="auto">
					<TextField
						placeholder={t('common.search')}
						variant="outlined"
						className={classes.search}
						onChange={onSearch}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<SearchIcon className={classes.searchIcon} />
								</InputAdornment>
							),
						}}
					/>
				</Grid>
			</Grid>

			{someSelected && (
				<>
					<Typography className={classes.selectedSubtitle}>
						{t('common.selected')}
					</Typography>
					<Grid container>{FIELDS_SELECTED.map(renderFields)}</Grid>
				</>
			)}
			{someSelected && (
				<Typography className={classes.unselectedSubtite}>
					{t('common.unselected')}
				</Typography>
			)}
			<Grid container>
				{isFetching ? (
					<CircularProgress />
				) : (
					FIELDS_UNSELECTED.map(renderFields)
				)}
			</Grid>
			{showMore && options?.length > 7 && (
				<Button
					text={t(buttonText)}
					type="outlined_and_primary"
					styleOverrides={classes.showMoreButton}
					onClick={onShowMore}
				/>
			)}
		</Card>
	);
};

SearchSection.propTypes = {
	name: string.isRequired,
	options: arrayOf(
		shape({
			label: string.isRequired,
			value: string.isRequired,
		})
	).isRequired,
	title: string.isRequired,
	subtitle: string.isRequired,
	showMore: bool,
	filters: node,
	isFetching: bool,
	disabled: bool,
};
