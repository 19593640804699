// Import helpers
import { FIELD_TYPES } from 'helpers';

export const GET_TITLE_FIELDS = ({ disabled }) => [
	{
		type: FIELD_TYPES.TEXT,
		name: 'name.en',
		inputLabel: 'common.fields.english_title',
		placeholder: 'common.fields.enter_english_title',
		maxLength: '30',
		redAstrick: true,
		required: true,
		md: 6,
		disabled,
	},
	{
		type: FIELD_TYPES.TEXT,
		name: 'name.ar',
		inputLabel: 'common.fields.arabic_title',
		placeholder: 'common.fields.enter_arabic_title',
		maxLength: '30',
		redAstrick: true,
		required: true,
		md: 6,
		disabled,
	},
];
