import { useFormState } from 'react-final-form';
import { useParams, useHistory } from 'react-router-dom';

// Import helpers
import { HELP_CENTER_FORM_ENTRIES } from 'helpers';

export const useBottomBar = () => {
	const { valid, submitting } = useFormState();
	const { entry } = useParams();
	const history = useHistory();

	const isViewOnly = entry === HELP_CENTER_FORM_ENTRIES.VIEW;

	const isSubmitDisabled = !valid || submitting || isViewOnly;

	const btnLoading = submitting;

	const onCancel = () => history.goBack();

	return { isSubmitDisabled, btnLoading, isViewOnly, onCancel };
};
