import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const TwentyFourSeven = (props) => (
	<SvgIcon width="20" height="20" viewBox="0 0 25 25" fill="none" {...props}>
		<path
			d="M8.55469 10.7279C8.55469 9.91391 9.21469 9.25391 10.0287 9.25391C10.8427 9.25391 11.5027 9.91391 11.5027 10.7279C11.5027 12.5699 8.55469 12.5699 8.55469 14.7519H11.5027"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M16.452 14.7526C16.452 14.7526 16.452 13.8786 16.452 13.4976H17.287H16.452H14.088C13.664 13.4976 13.397 13.0416 13.61 12.6746C14.201 11.6576 14.885 10.5066 15.512 9.49255C15.781 9.05655 16.452 9.25255 16.452 9.76455V13.4976"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M21.3461 4.80469V7.69769H18.4531H21.3461"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M20.8269 7.69795C19.3009 4.90095 16.3339 3.00195 12.9219 3.00195C7.95087 3.00195 3.92188 7.03195 3.92188 12.002C3.92188 16.972 7.95087 21.002 12.9219 21.002C17.8929 21.002 21.9219 16.972 21.9219 12.002"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
	</SvgIcon>
);
