import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const Umbrella = (props) => (
	<SvgIcon width="20" height="20" viewBox="0 0 25 25" fill="none" {...props}>
		<path
			d="M20.9219 21.666H4.92188"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M15.8264 4.3321V4.3321C19.7074 5.9791 21.7544 10.2591 20.6004 14.3141L20.5254 14.5791C20.1974 15.7341 18.9314 16.3391 17.8264 15.8701L6.13838 10.9081C5.03338 10.4391 4.58938 9.1081 5.19238 8.0691L5.33038 7.8301C7.44638 4.1851 11.9464 2.6841 15.8264 4.3321Z"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M15.8261 4.33203L16.0851 5.37903C16.8541 8.49403 16.1791 11.789 14.2461 14.35V14.35"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M15.8278 4.33203L14.8948 4.87303C12.1198 6.48303 10.2188 9.25903 9.71875 12.428V12.428"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M11.9827 13.3867L8.47266 21.6667"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
	</SvgIcon>
);
