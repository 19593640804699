export const COLUMNS_ACCESSORS = {
	CREATED_AT: 'created_at',
	QUESTIONS: 'questions',
	SOLUTIONS: 'solutions',
	ACTIONS: 'actions',
	STATUS: 'status',
};

export const columns = [
	{
		Header: 'common.fields.solutions',
		accessor: COLUMNS_ACCESSORS.SOLUTIONS,
	},
	{
		Header: 'common.fields.questions',
		accessor: COLUMNS_ACCESSORS.QUESTIONS,
	},
	{
		Header: 'common.fields.created_at',
		accessor: COLUMNS_ACCESSORS.CREATED_AT,
	},
	{
		Header: 'common.fields.status',
		accessor: COLUMNS_ACCESSORS.STATUS,
	},
	{
		Header: 'common.fields.actions',
		accessor: COLUMNS_ACCESSORS.ACTIONS,
	},
];
