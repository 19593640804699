import { useEffect, useState } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { useParams } from 'react-router-dom';

// Import utils and helpers
import { useToggle, useTranslations, useMessage } from 'components/utilities';
import { useSectionsList } from 'queries';
import { NEW_SECTION } from 'views/HelpCenter/SectionsForm/helpers';

export const useSectionPosition = () => {
	const { id: sectionUuid } = useParams();
	const { message } = useMessage();
	const {
		i18n: { language: lang },
		t,
	} = useTranslations();

	const {
		values: { name, positions },
	} = useFormState();

	const { on: positionDialogOpen, toggle: togglePositionDialog } = useToggle();

	const handleDialogOpen = () => {
		if (name?.[lang]) {
			togglePositionDialog();
		} else {
			message.warning(t('help_center.please_provide_section_titles_first'));
		}
	};

	const [currentSectionPosition, setCurrentSectionPosition] = useState(null);

	const { change } = useForm();

	const { data: sections, isFetching } = useSectionsList();

	const positionsList = sections
		.map(({ uuid, name, position }) => ({
			id: uuid,
			label: name[lang],
			position,
		}))
		.sort((a, b) => a.position - b.position)
		.map((section, index) => ({ ...section, position: index + 1 }));

	useEffect(() => {
		if (positionsList.length > 0) {
			if (sectionUuid) {
				change('positions', positionsList);
			} else {
				const newPosition =
					positionsList[positionsList.length - 1].position + 1;

				const initialPositions = [
					...positionsList,
					{ id: NEW_SECTION, label: name?.[lang], position: newPosition },
				];
				change('positions', initialPositions);
			}
		}
		// eslint-disable-next-line
	}, [isFetching, name, lang]);

	useEffect(() => {
		const currentSection = sectionUuid
			? positions?.find(({ id }) => id === sectionUuid)
			: positions?.find(({ id }) => id === 'new_section');

		setCurrentSectionPosition(currentSection?.position);
		// eslint-disable-next-line
	}, [positions]);

	return {
		positionDialogOpen,
		togglePositionDialog,
		handleDialogOpen,
		currentSectionPosition,
		isFetching,
	};
};
