/* eslint-disable react/display-name */
import React from 'react';

// Import helpers
import { FIELD_TYPES } from 'helpers';
import { SOLUTION_TYPES } from 'views/HelpCenter/helpers';

// Import components
import { TypeOption } from './components';

export const useSolutionType = () => {
	const SOLUTION_TYPES_OPTIONS = [
		{
			label: 'help_center.solution_types.quick_response_title',
			description: 'help_center.solution_types.quick_response_description',
			value: SOLUTION_TYPES.CHAT_RESPONSE,
		},
		{
			label: 'help_center.solution_types.content_article_title',
			description: 'help_center.solution_types.content_article_description',
			value: SOLUTION_TYPES.CONTENT_ARTICLE,
		},
	];

	const GET_RADIO_FIELD = ({ disabled }) => ({
		type: FIELD_TYPES.RADIO_GROUP,
		name: 'type',
		options: SOLUTION_TYPES_OPTIONS,
		labelExtractor: ({ label }) => label,
		keyExtractor: ({ value }) => value,
		disabled,
		customLabel: (option) => <TypeOption option={option} />,
	});

	return {
		GET_RADIO_FIELD,
	};
};
