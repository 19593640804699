import React from 'react';

// Import components
import { FormWrapper } from '../components';
import { FormContent } from './components';

// Import helpers
import { INITIAL_VALUES, handleSubmit } from './helpers';
import { validateSubsections } from './validation';

export const SubSectionsForm = () => {
	return (
		<FormWrapper
			title="help_center.create_new_subsection"
			initialValues={INITIAL_VALUES}
			submitBtnText="common.buttons.create_subsection"
			onSubmit={handleSubmit}
			validate={validateSubsections}
		>
			<FormContent />
		</FormWrapper>
	);
};
