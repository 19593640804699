import React from 'react';
import { object } from 'prop-types';
import { Box } from '@material-ui/core';

// Import components
import { Typography } from 'components/elements';

// Import utils
import { useTranslations } from 'components/utilities';

// Import styles
import { useStyles } from './TypeOption.styles';

export const TypeOption = ({ option }) => {
	const { t } = useTranslations();
	const classes = useStyles();

	const { label, description } = option;

	return (
		<Box>
			<Typography fontSize={16} className={classes.label}>
				{t(label)}
			</Typography>
			<Typography fontSize={12} className={classes.description}>
				{t(description)}
			</Typography>
		</Box>
	);
};

TypeOption.propTypes = {
	option: object.isRequired,
};
