import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const CarTechnicalProblem = (props) => (
	<SvgIcon width="20" height="20" viewBox="0 0 25 25" fill="none" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M14.4211 14.3655L7.62109 21.1655C6.72109 22.0655 5.32109 22.0655 4.42109 21.1655C3.52109 20.2655 3.52109 18.8655 4.42109 17.9655L11.2211 11.1655C10.4211 9.16549 10.9211 6.86549 12.5211 5.26549C14.2211 3.56549 16.7211 3.26549 18.7211 4.06549C18.8211 4.06549 18.8211 4.16549 18.7211 4.26549L15.1211 7.86549L17.7211 10.4655L21.4211 6.86549C21.4211 6.86549 21.5211 6.86549 21.6211 6.86549C22.6211 8.86549 22.1211 11.3655 20.4211 13.0655C18.7211 14.6655 16.4211 15.0655 14.4211 14.3655Z"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
	</SvgIcon>
);
