import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const Hand = (props) => (
	<SvgIcon width="20" height="20" viewBox="0 0 25 25" fill="none" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M8.87237 3.9873C8.98288 3.7867 9.19377 3.66211 9.4228 3.66211C9.65182 3.66211 9.86272 3.7867 9.97323 3.9873L10.4586 4.8762C10.5163 4.98189 10.6031 5.06876 10.7088 5.12645L11.5975 5.61194C11.7987 5.72209 11.9237 5.93316 11.9237 6.16249C11.9237 6.39182 11.7987 6.60289 11.5975 6.71304L10.7088 7.19953C10.6031 7.25722 10.5163 7.34409 10.4586 7.44978L9.97323 8.33767C9.86316 8.53884 9.65211 8.66394 9.4228 8.66394C9.19349 8.66394 8.98244 8.53884 8.87237 8.33767L8.38599 7.44878C8.32892 7.34264 8.24192 7.25561 8.13578 7.19853L7.24808 6.71304C7.04694 6.60289 6.92188 6.39182 6.92188 6.16249C6.92188 5.93316 7.04694 5.72209 7.24808 5.61194L8.13578 5.12645C8.2417 5.06908 8.32864 4.98213 8.38599 4.8762L8.87237 3.9873Z"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M16.2238 10.6661V10.6661C15.2587 9.56318 15.2587 7.91636 16.2238 6.81347L17.8962 4.90221C18.0297 4.74963 18.2225 4.66211 18.4253 4.66211C18.628 4.66211 18.8209 4.74963 18.9544 4.90221L20.6267 6.81348C21.5918 7.91637 21.5918 9.56319 20.6267 10.6661V10.6661C20.0713 11.3009 19.2688 11.6651 18.4253 11.6651C17.5817 11.6651 16.7793 11.3009 16.2238 10.6661V10.6661Z"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M6.91922 21.6693H4.91839C4.36595 21.6691 3.91817 21.2214 3.91797 20.6689V14.6664C3.91817 14.114 4.36595 13.6662 4.91839 13.666H6.91922C7.47165 13.6662 7.91944 14.114 7.91964 14.6664V20.6689C7.91944 21.2214 7.47165 21.6691 6.91922 21.6693V21.6693Z"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M13.9244 17.6681H15.8852C16.3182 17.6683 16.7395 17.5279 17.0857 17.2679L19.5027 15.4552C20.1061 15.003 20.9502 15.0631 21.4835 15.5962V15.5962C21.7679 15.8803 21.9277 16.2657 21.9277 16.6677C21.9277 17.0696 21.7679 17.4551 21.4835 17.7391L19.4097 19.813C18.8509 20.3715 18.1394 20.7523 17.3648 20.9075L14.4336 21.4937C13.8525 21.6099 13.2528 21.5956 12.6779 21.4517L10.0268 20.7894C9.70981 20.7095 9.3842 20.6692 9.05735 20.6694H7.92188"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M13.9244 17.6677H15.41C15.812 17.6678 16.1976 17.5081 16.4818 17.2239C16.7661 16.9396 16.9257 16.5541 16.9256 16.1521V15.8489C16.9258 15.1537 16.4526 14.5477 15.7781 14.3793L13.4852 13.8061C13.1125 13.7131 12.7298 13.666 12.3457 13.666V13.666C11.4181 13.6657 10.5112 13.9404 9.73963 14.4553L7.92188 15.6668"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
	</SvgIcon>
);
