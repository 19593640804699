import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
	wrapper: { margin: '10px 0' },
	editorClassName: {
		border: '1px solid',
		borderColor: theme.palette.gray.border,
		minHeight: ({ minHeight }) => minHeight,
		borderRadius: '6px',
	},
	editorErrorClassName: {
		border: `1px solid ${theme.palette.error.main}`,
		minHeight: ({ minHeight }) => minHeight,
	},
	wrapperClassName: {
		minHeight: ({ minHeight }) => minHeight,
		borderRadius: '6px',
	},
	required: (props) => ({
		color: props.redAstrick ? theme.palette.error.medium : 'inherit',
	}),
	inputLabel: {
		fontSize: '12px',
		fontWeight: 600,
		marginBottom: '16px',
		color: theme.palette.text.inputLabel,
	},
	maxLength: {
		display: 'flex',
		justifyContent: 'flex-end',
		width: '100%',
		color: theme.palette.text[1],
	},
	counter: {
		color: theme.palette.inputLabel,
	},
}));

export default useStyles;
