import React from 'react';
import { bool, object } from 'prop-types';

// Import utils and helpers
import { TRANSFERRED_INVOICE_FIELDS } from './helpers';

// Import components
import { PreviewFields } from 'views/Bookings/BookingDetails/components/PreviewFields';
import { LoadingWrapper, AlertMessage } from 'components/elements';

// Import styles
import { useStyles } from './PreviousCompanyInvoice.styles';

export const PreviousCompanyInvoice = ({ data, isFetching, isError }) => {
	const classes = useStyles();

	if (isFetching) {
		return <LoadingWrapper className={classes.loadingWrapper} />;
	}

	if (isError) {
		return <AlertMessage />;
	}

	return (
		<PreviewFields customData={data} fields={TRANSFERRED_INVOICE_FIELDS} />
	);
};

PreviousCompanyInvoice.propTypes = {
	data: object.isRequired,
	isFetching: bool.isRequired,
	isError: bool.isRequired,
};
