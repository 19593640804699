// Import helpers
import { createQuestion, editQuestion } from 'store/actions';
import { HELP_CENTER_TYPES } from 'helpers';
import { helpCenterErrorCallback, helpCenterSuccessCallback } from '../helpers';

export const INITIAL_VALUES = {
	name: { en: '', ar: '' },
	permission: 'all',
	is_active: false,
	subsection_uuids: [],
	type: null,
	searchFilter: 'all',
};

export const handleSubmit = ({ values, message, history, isEdit, uuid }) => {
	if (isEdit) {
		return editQuestion({
			uuid,
			data: values,
			successCallback: () =>
				helpCenterSuccessCallback({
					history,
					type: HELP_CENTER_TYPES.QUESTIONS,
					message,
				}),
			errorCallback: (error) => helpCenterErrorCallback({ error, message }),
		});
	}
	return createQuestion({
		data: values,
		successCallback: () =>
			helpCenterSuccessCallback({
				history,
				type: HELP_CENTER_TYPES.QUESTIONS,
				message,
			}),
		errorCallback: (error) => helpCenterErrorCallback({ error, message }),
	});
};
