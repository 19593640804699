import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const Bookmarks = (props) => (
	<SvgIcon width="20" height="20" viewBox="0 0 25 25" fill="none" {...props}>
		<path
			d="M17.1645 5.42338C19.5077 7.76653 19.5077 11.5655 17.1645 13.9087C14.8214 16.2518 11.0224 16.2518 8.67924 13.9087C6.33609 11.5655 6.33609 7.76652 8.67924 5.42338C11.0224 3.08023 14.8214 3.08023 17.1645 5.42338"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M8.92188 14.1289V21.6659L12.9219 19.6659L16.9219 21.6659V14.1289"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
	</SvgIcon>
);
