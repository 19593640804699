import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const PaymentsAndRefunds = (props) => (
	<SvgIcon width="20" height="20" viewBox="0 0 25 25" fill="none" {...props}>
		<path
			d="M3.92188 9.66602H21.9219"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M10.4919 13.666H7.92188"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M18.9219 19.666H6.92188C5.26487 19.666 3.92188 18.323 3.92188 16.666V8.66602C3.92188 7.00902 5.26487 5.66602 6.92188 5.66602H18.9219C20.5789 5.66602 21.9219 7.00902 21.9219 8.66602V16.666C21.9219 18.323 20.5789 19.666 18.9219 19.666Z"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
	</SvgIcon>
);
