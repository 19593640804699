// Import helpers
import { combineQueryAndPaginationParams, ENDPOINTS } from 'helpers';
import services from 'services/services';
import { fetchSubsectionsListUrl } from 'store/paths';

export const fetchSubsectionsList =
	({ queryParams, cancelToken, options }) =>
	async () => {
		try {
			const { pageIndex, pageSize, isExport } = options;

			const body = {
				start: pageIndex * pageSize,
				length: pageSize,
				is_export: isExport,
			};

			const params = combineQueryAndPaginationParams({ queryParams, body });

			const { data } = await services.get(
				fetchSubsectionsListUrl(params),
				cancelToken
			);

			return Promise.resolve({ data });
		} catch (error) {
			return Promise.reject(error);
		}
	};

export const createSubsection = async ({
	data,
	successCallback,
	errorCallback,
}) => {
	try {
		const resp = await services.post(
			ENDPOINTS.HELP_CENTER.SUBSECTIONS.CREATE_SUBSECTION,
			data
		);
		successCallback(resp);
	} catch (error) {
		errorCallback(error);
	}
};

export const editSubsection = async ({
	uuid,
	data,
	successCallback,
	errorCallback,
}) => {
	try {
		const resp = await services.put(
			ENDPOINTS.HELP_CENTER.SUBSECTIONS.SUBSECTION_DETAILS(uuid),
			data
		);
		successCallback(resp);
	} catch (error) {
		errorCallback(error);
	}
};
