import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const Lock = (props) => (
	<SvgIcon width="20" height="20" viewBox="0 0 25 25" fill="none" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M17.9219 21.666H7.92188C6.81687 21.666 5.92188 20.771 5.92188 19.666V12.666C5.92188 11.561 6.81687 10.666 7.92188 10.666H17.9219C19.0269 10.666 19.9219 11.561 19.9219 12.666V19.666C19.9219 20.771 19.0269 21.666 17.9219 21.666Z"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M8.92188 10.666V7.66602V7.66602C8.92188 5.45702 10.7129 3.66602 12.9219 3.66602V3.66602C15.1309 3.66602 16.9219 5.45702 16.9219 7.66602V7.66602V10.666"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
	</SvgIcon>
);
