import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const Shopping = (props) => (
	<SvgIcon width="20" height="20" viewBox="0 0 25 25" fill="none" {...props}>
		<path
			d="M8.92188 11.002V9.00195C8.92173 8.47148 9.1324 7.96268 9.5075 7.58758C9.8826 7.21247 10.3914 7.00181 10.9219 7.00195H19.9219C20.4524 7.00181 20.9611 7.21247 21.3363 7.58758C21.7114 7.96268 21.922 8.47148 21.9219 9.00195V18.002C21.922 18.5324 21.7114 19.0412 21.3363 19.4163C20.9611 19.7914 20.4524 20.0021 19.9219 20.002H14.9219"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M18.9219 21.002V20.002"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M18.9219 7.00195V4.00195C18.922 3.47148 18.7114 2.96268 18.3362 2.58758C17.9611 2.21247 17.4524 2.00181 16.9219 2.00195H13.9219C13.3914 2.00181 12.8826 2.21247 12.5075 2.58758C12.1324 2.96268 11.9217 3.47148 11.9219 4.00195V7.00195"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M6.94531 14.302V12.102C6.94531 11.4944 7.4378 11.002 8.04531 11.002H10.7953C11.4028 11.002 11.8953 11.4944 11.8953 12.102V14.302"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<rect
			x="3.92188"
			y="14.3027"
			width="11"
			height="7.7"
			rx="1.65"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
	</SvgIcon>
);
