import React from 'react';
import { bool } from 'prop-types';
import { Box, CircularProgress } from '@material-ui/core';

// Import components
import { Typography, Button } from 'components/elements';
import { PositionsDialog } from './components';

// Import utils and helpers
import { useTranslations } from 'components/utilities';
import { useSectionPosition } from './useSectionPosition';

// Import styles
import { useStyles } from './SectionPosition.styles';

export const SectionPosition = ({ disabled = false }) => {
	const { t } = useTranslations();
	const classes = useStyles();
	const {
		positionDialogOpen,
		togglePositionDialog,
		handleDialogOpen,
		currentSectionPosition,
		isFetching,
	} = useSectionPosition();

	return (
		<Box>
			<Typography className={classes.label}>
				{t('help_center.position_in_help_center')}
			</Typography>
			<Box className={classes.positionContainer}>
				{isFetching ? (
					<CircularProgress />
				) : (
					<Typography>{currentSectionPosition}</Typography>
				)}
				<Button
					text="common.buttons.change"
					type="primary_text"
					size="tiny"
					onClick={handleDialogOpen}
					disabled={disabled}
				/>
			</Box>
			<PositionsDialog
				open={positionDialogOpen}
				onClose={togglePositionDialog}
			/>
		</Box>
	);
};

SectionPosition.propTypes = {
	disabled: bool,
};
