import React from 'react';
import { bool, number, object, string } from 'prop-types';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Typography } from '@material-ui/core';

// Import styles
import useStyles from './styles';

// Import components
import { InputError } from 'components/elements';

// Import utils
import { useTranslations } from 'components/utilities';
import { useDraftWysiwygFieldAdapter } from './useDraftWysiwygFieldAdapter';

const DraftWysiwygFieldAdapter = ({
	input,
	meta,
	redAstrick = false,
	required = false,
	label = '',
	minHeight = '200px',
	maxLength,
	disabled = false,
	...rest
}) => {
	const classes = useStyles({ redAstrick, minHeight });
	const { t } = useTranslations();

	const {
		editorState,
		onEditorStateChange,
		handleBeforeInput,
		handlePastedText,
		error,
		plainTextLength,
	} = useDraftWysiwygFieldAdapter({ input, maxLength, meta });

	return (
		<>
			{label && (
				<Typography className={classes.inputLabel}>
					{t(label)}
					{required && (
						<Typography component="span" className={classes.required}>
							{' '}
							*
						</Typography>
					)}
				</Typography>
			)}
			<div className={classes.wrapper}>
				<Editor
					spellCheck
					editorState={editorState}
					wrapperClassName={classes.wrapperClassName}
					editorClassName={
						error ? classes.editorErrorClassName : classes.editorClassName
					}
					onEditorStateChange={onEditorStateChange}
					handleBeforeInput={handleBeforeInput}
					handlePastedText={handlePastedText}
					readOnly={disabled}
					{...rest}
				/>
				<InputError meta={meta} />
			</div>
			{maxLength && (
				<Typography className={classes.maxLength}>
					<Typography component="span" className={classes.counter}>
						{plainTextLength}
					</Typography>
					/{maxLength}
				</Typography>
			)}
		</>
	);
};

DraftWysiwygFieldAdapter.propTypes = {
	input: object.isRequired,
	meta: object.isRequired,
	redAstrick: bool,
	required: bool,
	label: string,
	minHeight: string,
	maxLength: number,
	disabled: bool,
};

export default DraftWysiwygFieldAdapter;
