import { makeStyles } from '@material-ui/styles';

const startRadius = {
	borderRadius: ({ isRTL }) =>
		isRTL ? '0 1.042em 1.042em 0' : '1.042em 0 0 1.042em',
	left: ({ isRTL }) => (isRTL ? '0' : '2px'),
};

const endRadius = {
	borderRadius: ({ isRTL }) =>
		isRTL ? '1.042em 0 0 1.042em' : '0 1.042em 1.042em 0',
	right: ({ isRTL }) => (isRTL ? '0' : '2px'),
};

export const useStyles = makeStyles((theme) => ({
	dateRange: {
		fontFamily: theme.typography.fontFamily,
		display: 'flex',

		'& .rdrStaticRanges': {
			alignItems: 'flex-start',
		},

		'& .rdrStaticRangeLabel': {
			fontFamily: theme.typography.fontFamily,
			lineHeight: '25px',
			fontSize: 14,
		},

		'& .rdrDayToday .rdrDayNumber span:after': {
			backgroundColor: theme.palette.text.secondary,
		},

		'& .datePicker': {
			justifyContent: 'space-around',
		},

		'& .rdrStaticRange': {
			border: 'none',
		},

		'& .rdrCalendarWrapper, .rdrDefinedRangesWrapper': {
			flex: 1,
			border: 'none',
		},

		'& .rdrMonthPicker': {
			display: 'none',
		},

		'& .rdrMonthAndYearWrapper': {
			position: 'relative',
		},

		'& .rdrMonthAndYearPickers': {
			position: 'absolute',
			right: 10,
		},

		'& .rdrNextButton': {
			position: 'absolute',
			top: 65,
			right: 10,
			transform: ({ isRTL }) => (isRTL ? 'rotate(180deg)' : 'rotate(0deg)'),
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',

			'& *': {
				margin: 0,
			},
		},

		'& .rdrPprevButton': {
			position: 'absolute',
			top: 65,
			left: 0,
			transform: ({ isRTL }) => (isRTL ? 'rotate(180deg)' : 'rotate(0deg)'),
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',

			'& *': {
				margin: 0,
			},
		},

		'& .rdrMonthName': {
			textAlign: 'center',
		},

		[theme.breakpoints.down('xs')]: {
			'& .rdrDefinedRangesWrapper': {
				display: 'none',
			},
		},

		'& .rdrStartEdge, & .rdrStartEdge.rdrEndEdge': {
			...startRadius,
		},

		'& .rdrEndEdge': {
			...endRadius,
		},

		'& .rdrDayEndOfWeek .rdrInRange': {
			...endRadius,
		},

		'& .rdrDayStartOfWeek .rdrInRange': {
			...startRadius,
		},

		'& .rdrDayStartOfWeek .rdrDayInPreview': {
			...startRadius,
		},

		'& .rdrDayEndOfWeek .rdrDayInPreview': {
			...endRadius,
		},

		'& .rdrDayEndPreview': {
			...endRadius,
		},
		'& .rdrDayStartPreview': {
			...startRadius,
		},
		'& .rdrInputRangeInput': {
			margin: ({ isRTL }) => (isRTL ? '0 0 0 10px' : '0 10px 0 0'),
		},
	},
}));
