import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

// Import utils and helpers
import { HELP_CENTER_FORM_ENTRIES } from 'helpers';
import { useSectionDetails } from 'queries';
import { useHelpCenterContextProvider } from 'views/HelpCenter/context/HelpCenterContext';

export const useFormContent = () => {
	const { id: uuid, entry } = useParams();

	const { setInitialValues } = useHelpCenterContextProvider();

	const { data: sectionDetails } = useSectionDetails({
		enabled: !!uuid,
		uuid,
	});

	const isViewOnly = entry === HELP_CENTER_FORM_ENTRIES.VIEW;

	useEffect(() => {
		if (sectionDetails) {
			setInitialValues({
				name: sectionDetails.name,
				position: sectionDetails.position,
				permission: sectionDetails.permission,
				is_active: sectionDetails.is_active,
			});
		}
		// eslint-disable-next-line
	}, [sectionDetails]);

	return { isViewOnly };
};
