import React from 'react';
import { node, object } from 'prop-types';
import { useContext, createContext, useState } from 'react';

const HelpCenterContext = createContext();

export const HelpCenterContextProvider = ({ children, initialState }) => {
	const [initialValues, setInitialValues] = useState(initialState);
	return (
		<HelpCenterContext.Provider value={{ initialValues, setInitialValues }}>
			{children}
		</HelpCenterContext.Provider>
	);
};

export const useHelpCenterContextProvider = () => {
	const context = useContext(HelpCenterContext);

	if (!context) {
		throw new Error(
			'useHelpCenterContextProvider must be used inside HelpCenterContextProvider'
		);
	}

	return context;
};

HelpCenterContextProvider.propTypes = {
	children: node.isRequired,
	initialState: object.isRequired,
};
