import axios from 'axios';
import * as types from 'store/types';
import services from 'services/services';
import {
	fetchBookingsFormResourcesUrl,
	fetchBookingsFormInfoUrl,
	recalculateBookingUrl,
	submitBookingFormUrl,
} from 'store/paths';

//Import helpers
import { catchError } from '../../helpers';
import { convertValues, reassignItem } from './helpers';

export const fetchBookingsFormResources =
	({ itemId, cancelToken }) =>
	async (dispatch) => {
		try {
			await fetchBookingsFormInfo({ itemId })(dispatch);
			dispatch({ type: types.BOOKINGS_FORM_FETCH_DATA_LOADING });

			const { data: item } = await services.get(
				fetchBookingsFormResourcesUrl(itemId),
				cancelToken
			);

			reassignItem(item);

			dispatch({
				type: types.BOOKINGS_FORM_FETCH_DATA_SUCCESS,
				payload: {
					item,
				},
			});
		} catch (error) {
			if (axios.isCancel(error)) {
				return;
			}

			catchError({
				error,
				dispatch,
				type: types.BOOKINGS_FORM_FETCH_DATA_ERROR,
				payload: {
					error,
				},
			});
		}
	};

let recalculateBookingCancelToken = axios.CancelToken.source();

export const recalculateBooking =
	({ values, itemId }) =>
	async (dispatch) => {
		recalculateBookingCancelToken.cancel();
		recalculateBookingCancelToken = axios.CancelToken.source();

		try {
			dispatch({
				type: types.BOOKINGS_FORM_RECALCULATE_DATA_LOADING,
				payload: true,
			});

			values = convertValues(values);

			const { data: item } = await services.post(
				recalculateBookingUrl(itemId),
				values,
				recalculateBookingCancelToken.token
			);

			reassignItem(item);

			dispatch({
				type: types.BOOKINGS_FORM_RECALCULATE_DATA_SUCCESS,
				payload: {
					item,
				},
			});
		} catch (error) {
			if (axios.isCancel(error)) {
				return;
			}

			catchError({
				error,
				dispatch,
				type: types.BOOKINGS_FORM_RECALCULATE_DATA_ERROR,
			});
		}
	};

export const submitBookingForm =
	({ values, itemId, callbackSuccess, errorCallback }) =>
	async () => {
		try {
			values = convertValues(values);
			await services.post(submitBookingFormUrl(itemId), values);

			callbackSuccess();
		} catch {
			errorCallback();
		}
	};

export const fetchBookingsFormInfo =
	({ itemId, cancelToken }) =>
	async (dispatch) => {
		try {
			dispatch({ type: types.BOOKINGS_FORM_FETCH_INFO_LOADING });

			const { data: item } = await services.get(
				fetchBookingsFormInfoUrl(itemId),
				cancelToken
			);

			dispatch({
				type: types.BOOKINGS_FORM_FETCH_INFO_SUCCESS,
				payload: {
					item,
				},
			});
		} catch (error) {
			catchError({
				error,
				dispatch,
				type: types.BOOKINGS_FORM_FETCH_INFO_ERROR,
			});
		}
	};
