import React from 'react';

// import components
import { Grid } from 'components/elements';
import {
	TitleSection,
	PermissionsSection,
	StatusSection,
	SearchSection,
} from 'views/HelpCenter/components';
import { SelectIcon } from './components';

// Import styles
import { useStyles } from './FormContent.styles';

// Import helpers
import { useFormContent } from './useFormContent';

export const FormContent = () => {
	const classes = useStyles();
	const { sectionOptions, isFetching, isViewOnly } = useFormContent();

	return (
		<Grid container spacing={3}>
			<Grid item md={8} xs={12} className={classes.flexCol}>
				<TitleSection
					title="help_center.subsection_title"
					disabled={isViewOnly}
				>
					<SelectIcon disabled={isViewOnly} />
				</TitleSection>
				<SearchSection
					options={sectionOptions}
					name="section_uuids"
					title="help_center.main_section"
					subtitle="help_center.main_section_description"
					isFetching={isFetching}
					disabled={isViewOnly}
				/>
			</Grid>
			<Grid item md={4} xs={12} className={classes.flexCol}>
				<PermissionsSection disabled={isViewOnly} />
				<StatusSection disabled={isViewOnly} />
			</Grid>
		</Grid>
	);
};
