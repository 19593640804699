import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		'& input': {
			backgroundColor: theme.palette.gray.field,
		},
		'& .MuiFormHelperText-contained': {
			margin: '12px 0',
			fontSize: '14px',
		},
		'& input:-webkit-autofill': {
			'-webkit-background-clip': 'text',
		},
	},
	label: {
		color: theme.palette.text.inputLabel,
	},
	inputLabel: {
		fontSize: '12px',
		fontWeight: 600,
		marginBottom: '8px',
		color: theme.palette.text.inputLabel,
	},
	required: (props) => ({
		color: props.redAstrick ? theme.palette.error.medium : 'inherit',
	}),
	maxLength: {
		display: 'flex',
		justifyContent: 'flex-end',
		width: '100%',
		color: theme.palette.text[1],
	},
	counter: {
		color: theme.palette.inputLabel,
	},
}));
