import React, { useState, useEffect } from 'react';
import PropTypes, { bool, func } from 'prop-types';
import { useDispatch } from 'react-redux';
import axios from 'axios';

// Import helpers
import {
	setQueryParams,
	setFilterParams,
	getErrorMessage,
	EXPORT_TYPES,
} from 'helpers';
import { convertDataRowsForExtendedAt, transformColumns } from './helpers';

export const TableExportContext = React.createContext();

const TableExportProvider = ({
	columns,
	fetchDataTableAction,
	columnsLoading = false,
	children,
	noPagination,
	outerOpen,
	outerClose,
	noColumns = false,
}) => {
	const inputColumnsRef = React.useRef([]);
	const cancelTokenRef = React.useRef(null);

	const [isSelectAllChecked, setIsSelectAllChecked] = useState(true);

	const [state, setState] = useState({
		filters: {},
		columns: columns.map((column) => ({ ...column, isVisible: true })),
		open: false,
		type: EXPORT_TYPES.EXCEL,
		isLoading: false,
		progress: 0,
		error: '',
	});

	const visibleColumns = state.columns.filter(({ isVisible }) => isVisible);

	const transformedCols = transformColumns(visibleColumns);

	const dispatch = useDispatch();

	const fetchInputColumns = async () => {
		const cancelToken = cancelTokenRef.current.token;

		const tableOptions = {
			pageSize: 1,
			pageIndex: 0,
			sortBy: [],
			globalFilter: '',
			isExport: true,
		};

		const {
			data: {
				input: { columns },
			},
		} = await fetchDataTableAction({
			options: tableOptions,
			queryParams: '',
			cancelToken,
		})(dispatch);

		return columns;
	};

	const fetchDataChunk = async ({ pageSize, pageIndex }) => {
		const cancelToken = cancelTokenRef.current.token;

		const tableOptions = {
			pageSize,
			pageIndex,
			sortBy: [],
			globalFilter: '',
			isExport: true,
		};

		const queryParams = setQueryParams({
			options: tableOptions,
			columns: inputColumnsRef.current,
		});

		const filterParams = setFilterParams(
			state.filters,
			inputColumnsRef.current,
			queryParams
		);

		const params = `${queryParams}${filterParams}`;

		return await fetchDataTableAction({
			options: tableOptions,
			queryParams: params,
			cancelToken,
		})(dispatch);
	};

	const recursiveFetchData = async ({ page = 1 } = {}) => {
		const perPage = 100;

		const { data } = await fetchDataChunk({
			pageSize: perPage,
			pageIndex: page - 1,
		});

		let rows = data.data;

		const totalPages = Math.ceil(data.recordsFiltered / perPage);

		const hasMore = totalPages > page;

		const progress = (page / totalPages) * 100;

		setState((state) => ({
			...state,
			progress,
		}));

		if (!noPagination) {
			if (hasMore) {
				rows = [...rows, ...(await recursiveFetchData({ page: page + 1 }))];
			}
		}

		return rows;
	};

	const fetchData = async () => {
		cancelTokenRef.current = axios.CancelToken.source();

		setState((state) => ({
			...state,
			error: '',
			isLoading: true,
		}));

		try {
			const inputColumns = noColumns ? [] : await fetchInputColumns();

			inputColumnsRef.current = inputColumns;

			const data = await recursiveFetchData();
			const convertedData = convertDataRowsForExtendedAt(data);

			setState((state) => ({
				...state,
				isLoading: false,
			}));

			return convertedData;
		} catch (err) {
			if (!axios.isCancel(err)) {
				setState((state) => ({
					...state,
					error: getErrorMessage(err),
				}));
			}

			setState((state) => ({
				...state,
				isLoading: false,
			}));

			return null;
		}
	};

	const openExport = (type) => {
		setState((state) => ({
			...state,
			type,
			open: true,
		}));
	};

	const hideExport = () => {
		cancelTokenRef.current && cancelTokenRef.current.cancel();

		setState((state) => ({
			...state,
			type: '',
			open: false,
		}));
	};

	const toggleColumnVisible = (column) => {
		const index = state.columns.findIndex(
			({ accessor }) => accessor === column.accessor
		);

		setState((state) => ({
			...state,
			columns: [
				...state.columns.slice(0, index),
				{
					...column,
					isVisible: !column.isVisible,
				},
				...state.columns.slice(index + 1),
			],
		}));
	};

	const setFilters = (filters) => {
		setState((state) => ({
			...state,
			filters,
		}));
	};

	const updateColumns = (newColumns) => {
		setState((state) => ({
			...state,
			columns: newColumns,
		}));
	};

	const handleOnSelectAllChange = ({ target: { checked } }) => {
		setState((prevState) => ({
			...prevState,
			columns: prevState.columns.map((column) => ({
				...column,
				isVisible: checked,
			})),
		}));

		setIsSelectAllChecked(checked);
	};

	useEffect(() => {
		const isEveryColumnVisible = state.columns.every(
			(column) => column.isVisible
		);

		if (isEveryColumnVisible !== isSelectAllChecked) {
			setIsSelectAllChecked(isEveryColumnVisible);
		}
	}, [state.columns, isSelectAllChecked]);

	return (
		<TableExportContext.Provider
			value={{
				openExport,
				hideExport: outerClose ? outerClose : hideExport,
				columnsLoading,
				columns,
				visibleColumns: transformedCols,
				updateColumns,
				toggleColumnVisible,
				setFilters,
				fetchData,
				isSelectAllChecked,
				onSelectAllChange: handleOnSelectAllChange,
				inputColumns: inputColumnsRef.current,
				...state,
				open: outerOpen || state.open,
			}}
		>
			{children}
		</TableExportContext.Provider>
	);
};

TableExportProvider.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.node,
		PropTypes.arrayOf(PropTypes.node),
	]),
	columns: PropTypes.array,
	columnsLoading: PropTypes.bool,
	fetchDataTableAction: PropTypes.func,
	noPagination: bool,
	outerOpen: bool,
	outerClose: func,
	noColumns: bool,
};

export default TableExportProvider;
