import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const Gift = (props) => (
	<SvgIcon width="20" height="20" viewBox="0 0 25 25" fill="none" {...props}>
		<path
			d="M14.9219 11.666H20.4219C21.2503 11.666 21.9219 10.9944 21.9219 10.166V9.16602C21.9219 8.33759 21.2503 7.66602 20.4219 7.66602H5.42188C4.59345 7.66602 3.92188 8.33759 3.92188 9.16602V10.166C3.92188 10.9944 4.59345 11.666 5.42188 11.666H10.9219"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M20.9219 11.666V19.666C20.9219 20.7706 20.0264 21.666 18.9219 21.666H6.92188C5.81731 21.666 4.92188 20.7706 4.92188 19.666V11.666"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M10.9219 3.66602V3.66602C12.0264 3.66602 12.9219 4.56145 12.9219 5.66602V7.66602H10.9219C9.81731 7.66602 8.92188 6.77059 8.92188 5.66602V5.66602C8.92188 4.56145 9.81731 3.66602 10.9219 3.66602Z"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M14.9219 3.66602V3.66602C16.0264 3.66602 16.9219 4.56145 16.9219 5.66602V5.66602C16.9219 6.77059 16.0264 7.66602 14.9219 7.66602H12.9219V5.66602C12.9219 4.56145 13.8173 3.66602 14.9219 3.66602Z"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<rect
			x="10.9219"
			y="7.66602"
			width="4"
			height="14"
			rx="1"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
	</SvgIcon>
);
