import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const Diamond = (props) => (
	<SvgIcon width="20" height="20" viewBox="0 0 25 25" fill="none" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M4.1842 10.8799L12.1752 19.6689C12.5752 20.1089 13.2662 20.1089 13.6662 19.6689C15.4802 17.6749 20.0882 12.6059 21.6592 10.8789C21.9722 10.5349 22.0082 10.0199 21.7462 9.63586L18.2092 4.44186C18.0232 4.16886 17.7152 4.00586 17.3862 4.00586H8.46021C8.13121 4.00586 7.8232 4.16886 7.6372 4.44186L4.0972 9.63386C3.8342 10.0189 3.8702 10.5349 4.1842 10.8799Z"
			stroke="#69696F"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
	</SvgIcon>
);
