import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const Mail = (props) => (
	<SvgIcon width="20" height="20" viewBox="0 0 25 25" fill="none" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M16.2965 13.384L20.8025 10.329C21.5035 9.85502 21.9225 9.06402 21.9225 8.21802V8.21802C21.9225 6.80802 20.7805 5.66602 19.3715 5.66602H6.4885C5.0795 5.66602 3.9375 6.80802 3.9375 8.21702V8.21702C3.9375 9.06302 4.3565 9.85402 5.0575 10.329L9.5635 13.384C11.5965 14.762 14.2635 14.762 16.2965 13.384V13.384Z"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M3.92188 8.2168V17.6658C3.92188 19.3228 5.26487 20.6658 6.92188 20.6658H18.9219C20.5789 20.6658 21.9219 19.3228 21.9219 17.6658V8.2178"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
	</SvgIcon>
);
