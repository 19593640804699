import React from 'react';
import { object, string } from 'prop-types';
import { TableCell, Box, Typography } from '@material-ui/core';

// Import styles
import { useStyles } from './TextWithTooltipTableCell.styles';

// Import components
import { TooltipComponent } from './components';

export const TextWithTooltipTableCell = ({
	cell,
	text = '',
	hoverText = '',
}) => {
	const classes = useStyles();

	return (
		<TableCell {...cell.getCellProps()} style={{ minWidth: '170px' }}>
			{text && (
				<Box className={classes.container}>
					<TooltipComponent hoverText={hoverText} />
					<Typography className={classes.text}>{text}</Typography>
				</Box>
			)}
		</TableCell>
	);
};

TextWithTooltipTableCell.propTypes = {
	cell: object.isRequired,
	text: string,
	hoverText: string,
};
