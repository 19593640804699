import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const Coupons = (props) => (
	<SvgIcon width="20" height="20" viewBox="0 0 25 25" fill="none" {...props}>
		<path
			d="M14.9219 19.166L19.9219 14.166"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M15.4825 12.6054C16.0683 13.1911 16.0683 14.1409 15.4825 14.7267C14.8967 15.3125 13.947 15.3125 13.3612 14.7267C12.7754 14.1409 12.7754 13.1911 13.3612 12.6054C13.947 12.0196 14.8967 12.0196 15.4825 12.6054"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M21.4825 18.6054C22.0683 19.1911 22.0683 20.1409 21.4825 20.7267C20.8967 21.3125 19.947 21.3125 19.3612 20.7267C18.7754 20.1409 18.7754 19.1911 19.3612 18.6054C19.947 18.0196 20.8967 18.0196 21.4825 18.6054"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M7.92188 8.16602H17.9219"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M7.92188 12.166H9.92188"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M9.92188 18.166H7.92188C5.71287 18.166 3.92188 16.375 3.92188 14.166V8.16602C3.92188 5.95702 5.71287 4.16602 7.92188 4.16602H17.9219C20.1309 4.16602 21.9219 5.95702 21.9219 8.16602V11.166"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
	</SvgIcon>
);
