import React from 'react';
import { useFormState } from 'react-final-form';

// import components
import { Grid } from 'components/elements';
import {
	TitleSection,
	StatusSection,
	SearchSection,
} from 'views/HelpCenter/components';
import { SolutionType } from '../SolutionType';
import { SolutionText } from '../SolutionText';
import { PermissionFilter } from './components';

// Import styles
import { useStyles } from './FormContent.styles';

// Import helpers and utils
import { useFormContent } from './useFormContent';
import { SOLUTION_TYPES } from 'views/HelpCenter/helpers';

export const FormContent = () => {
	const classes = useStyles();
	const { values } = useFormState();
	const { type } = values || {};
	const { questionsOptions, isFetching, isViewOnly } = useFormContent();

	const isQuickResponse = type === SOLUTION_TYPES.CHAT_RESPONSE;

	return (
		<Grid container spacing={3}>
			<Grid item md={8} xs={12} className={classes.flexCol}>
				<TitleSection
					title="help_center.solution_title"
					disabled={isViewOnly}
				/>
				<SolutionType disabled={isViewOnly} />
				<SolutionText disabled={isViewOnly} />
				{type && (
					<SearchSection
						options={questionsOptions}
						name="question_uuids"
						title="help_center.assign_question"
						subtitle="help_center.assign_question_description"
						showMore
						filters={
							isQuickResponse && <PermissionFilter disabled={isViewOnly} />
						}
						isFetching={isFetching}
						disabled={isViewOnly}
					/>
				)}
			</Grid>
			<Grid item md={4} xs={12} className={classes.flexCol}>
				<StatusSection disabled={isViewOnly} />
			</Grid>
		</Grid>
	);
};
