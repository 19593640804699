import React from 'react';

// Import utils and helpers
import { TABLE_NAVIGATION_LINKS } from 'views/HelpCenter/helpers';
import { columns, COLUMNS_ACCESSORS } from './columns';
import { TableRPC } from 'components/utilities';

// Import components
import { InnerNavbarElements } from 'components/elements';
import { TableBodyRow, Filters } from './components';
import { AddNewButton } from '../components';

// Import store
import { fetchSubsectionsList } from 'store/actions';

// Import helpers
import { HELP_CENTER_TYPES, replaceHelpCenterAddFormUrl } from 'helpers';

export const SubsectionsList = () => {
	const type = HELP_CENTER_TYPES.SUBSECTIONS;
	return (
		<TableRPC
			columnsAccessor={COLUMNS_ACCESSORS}
			columns={columns}
			title="nav.administrative.help_center_configuration"
			tableBodyRow={TableBodyRow}
			fetchDataTableAction={fetchSubsectionsList}
			filtersBar={<Filters />}
			tableNavigation={<InnerNavbarElements links={TABLE_NAVIGATION_LINKS} />}
			additionalNavigationElements={
				<AddNewButton
					to={replaceHelpCenterAddFormUrl({ form: type })}
					type={type}
				/>
			}
			customGlobalSearchColumn="name"
		/>
	);
};
