import i18next from 'i18next';

// Import helpers
import { FIELD_TYPES, affiliatedPartnersOptions } from 'helpers';

export const CORPORATE_FIELDS = () => [
	{
		type: FIELD_TYPES.TEXT,
		label: 'common.fields.company_name',
		name: 'name',
		md: 6,
	},
	{
		type: FIELD_TYPES.IMAGE_FILE,
		label: 'b2b.corporate.form.corporate_logo',
		name: 'logo.file_path',
		previewFileState: 'logo',
		fileNameField: 'logo.file_name',
		binaryState: 'logo.binary',
		md: 6,
	},
	{
		type: FIELD_TYPES.NUMBER,
		label: 'common.fields.corporate_limit',
		name: 'total_limit_amount_gross',
		md: 6,
	},
	{
		type: FIELD_TYPES.NUMBER,
		label: 'common.fields.vat_number',
		name: 'vat_number',
		md: 6,
	},
	{
		type: FIELD_TYPES.NUMBER,
		label: 'common.fields.cr',
		name: 'commercial_registration_number',
		md: 6,
	},
	{
		type: FIELD_TYPES.SELECT,
		label: 'common.fields.affiliated_partner_company',
		name: 'affiliated_partner_company',
		options: affiliatedPartnersOptions,
		keyExtractor: (option) => option,
		labelExtractor: (option) => i18next.t(`common.fields.${option}`),
		required: true,
		md: 6,
	},
];
