import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const Privacy = (props) => (
	<SvgIcon width="20" height="20" viewBox="0 0 25 25" fill="none" {...props}>
		<path
			d="M16.2425 11.0078L12.0945 15.1558L9.60547 12.6668"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M20.0219 6.58652C17.5629 6.39252 15.3249 5.41352 13.5589 3.89852C13.1969 3.58852 12.6459 3.58852 12.2849 3.89852C10.5189 5.41252 8.28088 6.39252 5.82187 6.58652C5.30987 6.62652 4.92188 7.06452 4.92188 7.57752V11.9075C4.92188 16.2745 8.07788 20.3695 12.3999 21.5925C12.7389 21.6885 13.1059 21.6885 13.4449 21.5925C17.7659 20.3685 20.9219 16.2755 20.9219 11.9085V7.57852C20.9219 7.06452 20.5339 6.62652 20.0219 6.58652Z"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
	</SvgIcon>
);
