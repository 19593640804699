import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const Checkmark = (props) => (
	<SvgIcon width="20" height="20" viewBox="0 0 25 25" fill="none" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M9.74888 19.323H7.26787C6.71387 19.323 6.26487 18.874 6.26487 18.32V15.838C6.26487 15.572 6.15888 15.317 5.97088 15.129L4.21587 13.374C3.82388 12.982 3.82388 12.347 4.21587 11.956L5.98887 10.183C6.16587 10.006 6.26487 9.76702 6.26487 9.51702V7.01202C6.26487 6.45802 6.71387 6.00902 7.26787 6.00902H9.74988C10.0159 6.00902 10.2709 5.90302 10.4589 5.71502L12.2139 3.96002C12.6059 3.56802 13.2409 3.56802 13.6319 3.96002L15.3869 5.71502C15.5749 5.90302 15.8299 6.00902 16.0959 6.00902H18.5779C19.1319 6.00902 19.5809 6.45802 19.5809 7.01202V9.49402C19.5809 9.76002 19.6869 10.015 19.8749 10.203L21.6299 11.958C22.0219 12.35 22.0219 12.985 21.6299 13.376L19.8749 15.131C19.6869 15.319 19.5809 15.574 19.5809 15.84V18.322C19.5809 18.876 19.1319 19.325 18.5779 19.325H16.0959C15.8299 19.325 15.5749 19.431 15.3869 19.619L13.6319 21.374C13.2399 21.766 12.6049 21.766 12.2139 21.374L10.4589 19.619C10.2699 19.428 10.0149 19.323 9.74888 19.323Z"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M15.9219 11.166L12.1719 14.916L9.92188 12.666"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
	</SvgIcon>
);
