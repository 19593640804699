import React from 'react';
import { bool } from 'prop-types';
import { Card } from '@material-ui/core';

// Import components
import { Grid, Typography } from 'components/elements';

// Import styles
import { useStyles } from './QuestionType.styles';

// Import utils and helpers
import { useCommonFields, useTranslations } from 'components/utilities';
import { GET_RADIO_FIELD } from './helpers';

export const QuestionType = ({ disabled = false }) => {
	const classes = useStyles();
	const { renderFields } = useCommonFields();
	const { t } = useTranslations();
	const RADIO_FIELD = GET_RADIO_FIELD({ disabled });

	return (
		<Card className={classes.card}>
			<Typography className={classes.title}>
				{t('help_center.question_type')}
			</Typography>
			<Typography className={classes.subtitle}>
				{t('help_center.question_type_description')}
			</Typography>
			<Grid container className={classes.radioWrapper}>
				{renderFields(RADIO_FIELD)}
			</Grid>
		</Card>
	);
};

QuestionType.propTypes = {
	disabled: bool,
};
