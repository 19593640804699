import React from 'react';
import { array, func, node, object } from 'prop-types';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import _ from 'lodash';

// Import helpers
import { setQueryParams, setFilterParams } from 'helpers';

import { INITIAL_STATE, tableCache } from './helpers';

// Import reducer
import * as actions from './store/actions';
import reducer from './store/reducer';

// Create context
export const TableContext = React.createContext();

const {
	setLoading,
	setOptions,
	setTableData,
	setInputColumns,
	updateInputColumns,
	setFilters,
	setError,
} = actions;

export const SubTableProvider = ({
	columns,
	fetchDataTableAction,
	children,
	initialResources = {},
	customResources,
}) => {
	const fetchIdRef = React.useRef(0);

	const [state, dispatchAction] = React.useReducer(reducer, {
		...INITIAL_STATE,
		tableColumns: columns,
		resources: initialResources,
	});

	const dispatch = useDispatch();

	const { id: itemId } = useParams();

	const {
		pageSize: pageSizeParameter,
		pageIndex: pageIndexParameter,
		globalFilter: globalFilterParameter,
		sortBy: sortByParameter,
	} = state.options;

	const { filters } = state;

	// eslint-disable-next-line
	const fetchData = React.useCallback(
		// eslint-disable-next-line
		async (
			{
				pageSize,
				pageIndex,
				sortBy,
				globalFilter,
				externalFilters,
			} = state.options,
			inputTableColumns = state.inputColumns,
			tableFilters,
			cancelToken
		) => {
			const tableOptions = { pageSize, pageIndex, sortBy, globalFilter };
			// Dispatch options
			setOptions(tableOptions)(dispatchAction);

			// Give this fetch an ID
			const fetchId = ++fetchIdRef.current;

			// Dispatch loading state
			setLoading(true)(dispatchAction);

			if (fetchId === fetchIdRef.current) {
				try {
					const persistedInputTableColumns = _.isEmpty(inputTableColumns)
						? tableCache.getTableColumns()
						: inputTableColumns;

					// Get query params
					const queryParams = setQueryParams({
						options: tableOptions,
						columns: persistedInputTableColumns,
					});
					const filterParams = setFilterParams(
						filters,
						persistedInputTableColumns,
						queryParams
					);

					const params = `${queryParams}${filterParams}`;

					const isCustomTable = !!customResources?.length;

					if (isCustomTable) {
						const tableData = { data: customResources };

						setTableData(tableData)(dispatchAction);
						setInputColumns(columns)(dispatchAction);
					} else {
						const tableData = await fetchDataTableAction({
							updateColumns: handleUpdateInputColumns,
							options: tableOptions,
							queryParams: params,
							externalFilters,
							filters,
							columns,
							itemId,
							cancelToken,
						})(dispatch);

						tableCache.setTableColumns(tableData);

						const {
							data: { data, input, recordsFiltered },
							resources,
							rolesData,
						} = tableData;

						const tableDataPayload = {
							data,
							pageCount: Math.ceil(recordsFiltered / pageSize),
							resources,
							rolesData,
							recordsFiltered,
						};

						setTableData(tableDataPayload)(dispatchAction);
						setInputColumns(input.columns)(dispatchAction);
					}

					setLoading(false)(dispatchAction);
				} catch (error) {
					if (error && error.response) {
						const message =
							error.response.data.message ?? error.response.data.error;

						setError(message)(dispatchAction);
					}
				}
			}
		},
		// eslint-disable-next-line
		[
			pageSizeParameter,
			pageIndexParameter,
			globalFilterParameter,
			sortByParameter,
			filters,
			customResources,
		]
	);

	const handleSetFilters = (values) => setFilters(values)(dispatchAction);

	const handleUpdateInputColumns = (columns) =>
		updateInputColumns(columns)(dispatchAction);

	return (
		<TableContext.Provider
			value={{
				...state,
				columns: state.tableColumns,
				setFilters: handleSetFilters,
				updateColumns: handleUpdateInputColumns,
				fetchData,
			}}
		>
			{children}
		</TableContext.Provider>
	);
};

SubTableProvider.propTypes = {
	columns: array.isRequired,
	fetchDataTableAction: func.isRequired,
	initialResources: object,
	children: node,
	customResources: array,
};
