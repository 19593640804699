import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const Star = (props) => (
	<SvgIcon width="20" height="20" viewBox="0 0 25 25" fill="none" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M12.9219 3.5625L15.8569 9.5555L22.4219 10.5225L17.6719 15.1845L18.7929 21.7705L12.9219 18.6595L7.05087 21.7705L8.17188 15.1845L3.42188 10.5225L9.98588 9.5555L12.9219 3.5625Z"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
	</SvgIcon>
);
