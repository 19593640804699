import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	card: {
		padding: '24px',
		borderRadius: '16px',
		boxShadow: 'none',

		'& .MuiFormLabel-asterisk': {
			color: theme.palette.error.medium,
		},
	},
	title: {
		color: theme.palette.text.inputLabel,
		marginBottom: '24px',
	},
}));
