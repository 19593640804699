import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const Flag = (props) => (
	<SvgIcon width="20" height="20" viewBox="0 0 25 25" fill="none" {...props}>
		<path
			d="M5.92188 21.6657V4.5957"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M19.9219 14.686V4.66602"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M5.92188 14.6665C5.92188 14.6665 6.79688 13.9395 9.42188 13.9395C12.0469 13.9395 13.7969 15.6665 16.4219 15.6665C19.0469 15.6665 19.9219 14.6895 19.9219 14.6895"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M5.92188 4.59802C5.92188 4.59802 6.79688 3.66602 9.42188 3.66602C12.0469 3.66602 13.7969 5.39302 16.4219 5.39302C19.0469 5.39302 19.9219 4.66602 19.9219 4.66602"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
	</SvgIcon>
);
