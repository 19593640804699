// Import helpers
import services from 'services/services';
import {
	deleteHelpCenterRecordUrl,
	updateActiveHelpCenterRecordUrl,
} from 'store/paths';

export const updateActiveHelpCenterRecord = async ({
	isActive,
	uuid,
	type,
}) => {
	const typeFormatted = type.slice(0, -1);

	await services.patch(
		updateActiveHelpCenterRecordUrl({ uuid, type: typeFormatted }),
		{
			is_active: !isActive,
		}
	);
};

export const deleteHelpCenterRecord = async ({ uuid, type }) => {
	const typeFormatted = type.slice(0, -1);
	await services.delete(
		deleteHelpCenterRecordUrl({ uuid, type: typeFormatted })
	);
};
