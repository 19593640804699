import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const Ticket = (props) => (
	<SvgIcon width="20" height="20" viewBox="0 0 25 25" fill="none" {...props}>
		<path
			d="M14.9219 9.00195H16.2136C16.6794 9.00195 17.1387 9.11039 17.5553 9.31867L18.1405 9.61125C18.6508 9.86641 19.0778 10.2618 19.3713 10.7511L21.9219 15.002"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M11.9219 18.002H5.92188C4.81731 18.002 3.92188 17.1065 3.92188 16.002V5.00195C3.92188 3.89738 4.81731 3.00195 5.92188 3.00195H12.9219C14.0264 3.00195 14.9219 3.89738 14.9219 5.00195V14.002"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M16.9219 21.0019L14.1705 19.9439C13.3601 19.6323 12.6732 19.0656 12.2133 18.3292L9.16456 13.4471C8.77041 12.816 8.86392 11.9963 9.3901 11.4701V11.4701C9.70036 11.1599 10.1245 10.9908 10.5631 11.0025C11.0017 11.0142 11.4162 11.2057 11.7095 11.5321L13.9286 14.0019H15.9219"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
	</SvgIcon>
);
