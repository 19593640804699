// Import helpers
import myAxios from 'services/myAxiosFactory';
import { createCorporateUrl, updateCorporateUrl } from 'store/paths';
import { getFormData } from './helpers';

export const createCorporate = async ({
	successCallback,
	errorCallback,
	values,
}) => {
	try {
		const formData = getFormData(values);

		const { data } = await myAxios().post(createCorporateUrl, formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		});

		successCallback(data?.uuid);
	} catch (error) {
		errorCallback(error);
	}
};

export const updateCorporate = async ({
	successCallback,
	errorCallback,
	corporateUuid,
	values,
}) => {
	try {
		const formData = getFormData(values, corporateUuid);

		const { data } = await myAxios().post(
			updateCorporateUrl(corporateUuid),
			formData,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			}
		);

		successCallback(data?.uuid);
	} catch (error) {
		errorCallback(error);
	}
};

export const UpdateCorporatePromise = ({ corporateUuid, values }) => {
	const formData = getFormData(values, corporateUuid);

	return myAxios().post(updateCorporateUrl(corporateUuid), formData, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	});
};
