import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	dialog: {
		'& .MuiPaper-root': {
			width: '50vw',
		},
	},
	dialogTitle: {
		'& > .MuiTypography-root': {
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
			width: '100%',
		},
	},
	dialogTitleText: {
		color: theme.palette.text.inputLabel,
		fontSize: '20px',
		fontWeight: 500,
	},
	closeIcon: {
		color: theme.palette.text[2],
		cursor: 'pointer',
		width: '40px',
		height: '40px',
	},
	description: {
		color: theme.palette.text[2],
		fontSize: '14px',
		fontWeight: 400,
		marginBottom: '40px',
	},
}));
