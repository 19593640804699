// Import helpers
import { FIELD_TYPES } from 'helpers';

export const CONTACTS_FIELDS = ({ section, prefixes }) => [
	{
		type: FIELD_TYPES.TEXT,
		label: 'offices.form.details.contact_person_name',
		name: `${section}.details.person_name`,
		md: 6,
		required: true,
	},
	{
		type: FIELD_TYPES.COMBINED_INPUTS,
		name: `${section}.details.contact_number`,
		md: 6,
		label: 'offices.form.details.contact_person_number',
		required: true,
		combinedFields: [
			{
				type: FIELD_TYPES.SELECT,
				name: `${section}.details.number_prefix`,
				md: 3,
				required: true,
				options: prefixes,
				labelExtractor: (prefix) => prefix,
				keyExtractor: (prefix) => prefix,
			},
			{
				type: FIELD_TYPES.NUMBER,
				name: `${section}.details.contact_number`,
				md: 9,
				required: true,
				defaultValue: '',
			},
		],
	},
	{
		type: FIELD_TYPES.COMBINED_INPUTS,
		name: `${section}.details.public_contact_number`,
		md: 6,
		label: 'offices.form.details.office_number',
		required: true,
		combinedFields: [
			{
				type: FIELD_TYPES.SELECT,
				name: `${section}.details.public_number_prefix`,
				md: 3,
				required: true,
				options: prefixes,
				labelExtractor: (prefix) => prefix,
				keyExtractor: (prefix) => prefix,
			},
			{
				type: FIELD_TYPES.NUMBER,
				name: `${section}.details.public_contact_number`,
				md: 9,
				required: true,
				defaultValue: '',
			},
		],
	},
];
