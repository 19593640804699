import { useSelector } from 'react-redux';

//Import store
import { previewBookingSelector } from 'store/selectors';

// Import utils and helpers
import { getCustomData, getEarlyReturnFields } from './helpers';
import { useProfile } from 'components/utilities';
import { useBookingInvoice, useEditBookingHistory } from 'queries';
import { BILLING_TYPES } from 'helpers';

export const useInvoice = () => {
	const booking = useSelector(previewBookingSelector);
	const { isAdmin } = useProfile();

	const {
		can_be_returned_earlier: canBeReturnedEarlier,
		early_return_details: earlyReturnDetails,
		uuid: bookingUuid,
		subscription,
		billings,
	} = booking || {};

	const { data: bookingHistory } = useEditBookingHistory({
		uuid: bookingUuid,
	});

	const bookingHistoryData = bookingHistory?.[0];

	const isClosePayment =
		billings &&
		billings.some(({ type }) => type === BILLING_TYPES.BOOKING_CLOSE);

	const isEarlyReturnDialogVisible =
		!subscription && canBeReturnedEarlier && isAdmin;

	const {
		data: currentCompanyInvoiceData,
		isFetching: isCurrentCompanyInvoiceFetching,
		isError: isCurrentCompanyInvoiceError,
	} = useBookingInvoice({ bookingUuid });

	const { before_transfer } = currentCompanyInvoiceData || {};

	const oldCompanyInvoiceDataFormatted = before_transfer && {
		...before_transfer?.data,
		options: Object.values(before_transfer?.data?.options),
		company_name: bookingHistoryData?.company_name,
		period: {
			pickDate: bookingHistoryData?.period?.pick_date,
			dropDate: bookingHistoryData?.period?.drop_date,
			days: bookingHistoryData?.period?.days,
		},
		created_at: bookingHistoryData?.created_at,
	};

	const oldCompanyInvoiceData = before_transfer
		? oldCompanyInvoiceDataFormatted
		: null;

	const isPreviousCompanyInvoice = !!oldCompanyInvoiceData && isAdmin;

	const isCancellationVisible =
		!!currentCompanyInvoiceData?.cancellation?.booking_cancelled_at;

	const earlyReturnCustomData =
		earlyReturnDetails &&
		getCustomData({
			isSubscription: subscription,
			earlyReturnDetails,
		});

	const earlyReturnFields =
		earlyReturnDetails &&
		getEarlyReturnFields({
			isSubscription: subscription,
			earlyReturnDetails,
		});

	const isEarlyReturnDetails = earlyReturnDetails && isAdmin;
	const subscriptionUuid = subscription?.uuid;

	const gridMd =
		isPreviousCompanyInvoice || isCancellationVisible || isEarlyReturnDetails
			? 6
			: 12;

	const getPriceDifference = ({
		isBookingTransferred,
		previousPrice,
		currentPrice,
	}) => {
		if (isBookingTransferred) {
			return Number(currentPrice) - Number(previousPrice);
		} else {
			return null;
		}
	};

	const previousPrice = oldCompanyInvoiceData?.total?.amount_gross;
	const currentPrice = currentCompanyInvoiceData?.total?.amount_gross;

	const priceDifference = getPriceDifference({
		isBookingTransferred: isPreviousCompanyInvoice,
		previousPrice,
		currentPrice,
	});

	return {
		isCurrentCompanyInvoiceFetching,
		isCurrentCompanyInvoiceError,
		isEarlyReturnDialogVisible,
		isPreviousCompanyInvoice,
		isCancellationVisible,
		isEarlyReturnDetails,
		currentCompanyInvoiceData,
		oldCompanyInvoiceData,
		earlyReturnCustomData,
		earlyReturnFields,
		subscriptionUuid,
		priceDifference,
		gridMd,
		isClosePayment,
		booking,
	};
};
