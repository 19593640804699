import React from 'react';
import { useParams, Redirect } from 'react-router-dom';

// Import helpers
import { HELP_CENTER_TYPES } from 'helpers';
import { URLS } from 'components/routes';

// Import components
import { SectionsList } from './SectionsList';
import { SubsectionsList } from './SubsectionsList';
import { QuestionsList } from './QuestionsList';
import { SolutionsList } from './SolutionsList';

export const HelpCenterConfiguration = () => {
	const { type } = useParams();
	const { SECTIONS, SUBSECTIONS, QUESTIONS, SOLUTIONS } = HELP_CENTER_TYPES;

	switch (type) {
		case SECTIONS:
			return <SectionsList />;
		case SUBSECTIONS:
			return <SubsectionsList />;
		case QUESTIONS:
			return <QuestionsList />;
		case SOLUTIONS:
			return <SolutionsList />;
		default:
			return <Redirect to={URLS.notFoundUrl} />;
	}
};
