import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const Heart = (props) => (
	<SvgIcon width="20" height="20" viewBox="0 0 25 25" fill="none" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M16.6219 4.66602C19.7919 4.66602 21.9219 7.64602 21.9219 10.426C21.9219 16.056 13.0819 20.666 12.9219 20.666C12.7619 20.666 3.92188 16.056 3.92188 10.426C3.92188 7.64602 6.05188 4.66602 9.22188 4.66602C11.0419 4.66602 12.2319 5.57602 12.9219 6.37602C13.6119 5.57602 14.8019 4.66602 16.6219 4.66602Z"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
	</SvgIcon>
);
