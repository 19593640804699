import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const Store = (props) => (
	<SvgIcon width="20" height="20" viewBox="0 0 25 25" fill="none" {...props}>
		<path
			d="M8.42281 10.4141C8.42281 11.6572 7.41503 12.665 6.17188 12.665"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M12.9237 10.4141C12.9237 11.6572 11.916 12.665 10.6728 12.665C9.42965 12.665 8.42188 11.6572 8.42188 10.4141"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M17.4237 10.4141C17.4237 11.6572 16.416 12.665 15.1728 12.665C13.9297 12.665 12.9219 11.6572 12.9219 10.4141"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M19.6767 12.665C18.4336 12.665 17.4258 11.6572 17.4258 10.4141"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M20.926 12.2852V21.6681"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M22.9263 21.6682H2.91797"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M4.91818 12.2832V21.6681"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<rect
			x="14.4219"
			y="15.666"
			width="4.00167"
			height="3.00125"
			rx="0.5"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M7.92188 21.6685V16.1662C7.92188 15.89 8.14583 15.666 8.42208 15.666H10.9231C11.1994 15.666 11.4233 15.89 11.4233 16.1662V21.6685"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M8.42208 10.4144V9.66411"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M12.9221 10.4144V9.66411"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M17.426 10.4144V9.66411"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M21.8372 9.66427H4.00781"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M19.6746 12.6654V12.6654C20.9177 12.6654 21.9255 11.6577 21.9255 10.4145V10.2875C21.9255 9.87962 21.8146 9.47946 21.6044 9.12997L19.5035 5.63351C19.1426 5.03207 18.4932 4.66351 17.7918 4.66211H8.05171C7.34889 4.66211 6.6976 5.03085 6.336 5.63351L4.23512 9.12997C4.02633 9.47987 3.9167 9.87999 3.91798 10.2875V10.4145C3.91798 11.6577 4.92577 12.6654 6.16893 12.6654V12.6654"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
	</SvgIcon>
);
