import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	container: {
		position: 'absolute',
		bottom: -85,
		width: '100%',
		padding: '24px',
		marginLeft: '-32px',
		backgroundColor: theme.palette.white,
		display: 'flex',
		justifyContent: 'flex-end',
	},
}));
