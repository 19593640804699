import { useState, useEffect } from 'react';
import { useForm, useFormState } from 'react-final-form';

// Import utils and helpers
import { getFields } from './helpers';
import { useTranslations } from 'components/utilities';

export const useSearchSection = ({
	name,
	options,
	isFetching,
	disabled,
	showMore,
}) => {
	const [activeOptions, setActiveOptions] = useState(options);
	const [itemsToShow, setItemsToShow] = useState(7);
	const [isShowingAll, setIsShowingAll] = useState(false);

	const { change } = useForm();
	const {
		values: { [name]: state },
	} = useFormState();
	const {
		i18n: { language: lang },
	} = useTranslations();

	const onItemSelect = (e) => {
		const targetName = e.target.name;
		const isSelected = state?.includes(targetName);
		change(
			name,
			isSelected
				? state.filter((item) => item !== targetName)
				: [...(state || []), targetName]
		);
	};

	const filteredOptions = activeOptions.filter(
		(option) => !state?.includes(option.value)
	);

	const getUnSelectedOptions = () => {
		if (!state) {
			return showMore ? activeOptions.slice(0, itemsToShow) : activeOptions;
		} else {
			return showMore ? filteredOptions.slice(0, itemsToShow) : filteredOptions;
		}
	};

	const getSelectedOptions = () => {
		if (!state) {
			return [];
		} else {
			return options.filter((option) => state.includes(option.value));
		}
	};

	const FIELDS_UNSELECTED = getFields({
		activeOptions: getUnSelectedOptions(),
		onItemSelect,
		selectedOptions: state,
		disabled,
	});

	const FIELDS_SELECTED = getFields({
		activeOptions: getSelectedOptions(),
		onItemSelect,
		selectedOptions: state,
		disabled,
	});

	const onSearch = (e) => {
		const { value } = e.target || {};
		setActiveOptions(
			options.filter(({ label }) =>
				label?.toLowerCase()?.includes(value?.toLowerCase())
			)
		);
	};

	const selectedOptions = getSelectedOptions();

	const someSelected = selectedOptions.length > 0;

	const optionsContainerHeight = (options.length - selectedOptions.length) * 42;

	const onShowMore = () => {
		if (isShowingAll && itemsToShow > 7) {
			setItemsToShow(7);
			setIsShowingAll(false);
		} else {
			const newItemsToShow = Math.min(itemsToShow + 7, filteredOptions.length);
			setItemsToShow(newItemsToShow);
			setIsShowingAll(newItemsToShow === filteredOptions.length);
		}
	};

	const buttonText = isShowingAll
		? 'help_center.show_less_questions'
		: 'help_center.show_more_questions';

	useEffect(() => {
		setActiveOptions(options);

		// eslint-disable-next-line
	}, [lang, isFetching, options?.length]);

	return {
		FIELDS_UNSELECTED,
		FIELDS_SELECTED,
		onSearch,
		onItemSelect,
		someSelected,
		optionsContainerHeight,
		onShowMore,
		buttonText,
	};
};
