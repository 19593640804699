import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const Directions = (props) => (
	<SvgIcon width="20" height="20" viewBox="0 0 25 25" fill="none" {...props}>
		<path
			d="M12.9219 13.834V10.834"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M12.9219 5.83398V3.83398"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M7.42188 5.83398H18.1069C18.3959 5.83398 18.6699 5.95898 18.8599 6.17598L20.1769 7.68498C20.5019 8.05698 20.5019 8.61098 20.1769 8.98298L18.8599 10.492C18.6699 10.709 18.3959 10.834 18.1069 10.834H7.42188C6.86988 10.834 6.42188 10.386 6.42188 9.83398V6.83398C6.42188 6.28198 6.86988 5.83398 7.42188 5.83398Z"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M18.4206 18.834H7.73563C7.44663 18.834 7.17263 18.709 6.98263 18.492L5.66563 16.983C5.34063 16.611 5.34063 16.057 5.66563 15.685L6.98263 14.176C7.17163 13.959 7.44663 13.834 7.73563 13.834H18.4206C18.9726 13.834 19.4206 14.282 19.4206 14.834V17.834C19.4206 18.386 18.9726 18.834 18.4206 18.834Z"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M12.9219 21.834V18.834"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
	</SvgIcon>
);
