import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const PlusIcon = (props) => (
	<SvgIcon width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
		<path
			id="Vector"
			d="M9 1V17M17 9H1"
			stroke="currentColor"
			fill="none"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</SvgIcon>
);
