import React from 'react';
import { number, string } from 'prop-types';
import { Box } from '@material-ui/core';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

// Import components
import { Typography } from 'components/elements';

// Import assets
import { SixDotsItem } from 'assets/icons';

// Import styles
import { useStyles } from './PositionItem.styles';

export const PositionItem = ({ name, position, label }) => {
	const classes = useStyles();
	const { attributes, listeners, setNodeRef, transform, transition } =
		useSortable({ id: name });

	const dragStyles = {
		transition,
		transform: CSS.Transform.toString(transform),
	};

	return (
		<Box
			className={classes.container}
			ref={setNodeRef}
			{...attributes}
			{...listeners}
			style={dragStyles}
		>
			<SixDotsItem className={classes.icon} />
			<Typography className={classes.name}>{label}</Typography>
			<Typography className={classes.position}>({position})</Typography>
		</Box>
	);
};

PositionItem.propTypes = {
	name: string.isRequired,
	position: number.isRequired,
	label: string.isRequired,
};
