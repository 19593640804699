import React from 'react';
import { TableCell } from '@material-ui/core';
import { bool, object } from 'prop-types';
import { format } from 'date-fns';

// Import helpers
import {
	STATUS_ACTIVE_INACTIVE_CELL,
	DEFAULT_DATE_TIME_COMMA_FORMAT,
	replaceHelpCenterViewFormUrl,
	HELP_CENTER_TYPES,
} from 'helpers';
import { STATUS_LIST } from 'views/HelpCenter/helpers';

// Import components
import { CommonStatusTableCell, LinkTableCell } from 'components/elements';
import { Actions } from 'views/HelpCenter/HelpCenterConfiguration/components';

export const Cell = ({ columnsAccessor, cell }) => {
	const {
		created_at: createdAt,
		name,
		is_active: isActive,
		uuid,
		subsections,
	} = cell.row.original;

	const { CREATED_AT, STATUS, ACTIONS, SECTION, SUBSECTION } = columnsAccessor;

	const type = HELP_CENTER_TYPES.SECTIONS;

	const renderCell = (cell) => {
		if (cell.column.isHidden) return null;

		switch (cell.column.id) {
			case SECTION:
				return (
					<LinkTableCell
						key={cell.column.id}
						cell={cell}
						to={replaceHelpCenterViewFormUrl({ form: type, uuid })}
					>
						{name}
					</LinkTableCell>
				);

			case CREATED_AT:
				return (
					<TableCell key={cell.column.id} {...cell.getCellProps()}>
						{format(new Date(createdAt), DEFAULT_DATE_TIME_COMMA_FORMAT)}
					</TableCell>
				);

			case SUBSECTION:
				return (
					<TableCell key={cell.column.id} {...cell.getCellProps()}>
						{subsections?.length}
					</TableCell>
				);

			case STATUS:
				return (
					<CommonStatusTableCell
						currentStatus={
							isActive
								? STATUS_ACTIVE_INACTIVE_CELL.ACTIVE
								: STATUS_ACTIVE_INACTIVE_CELL.INACTIVE
						}
						statusList={STATUS_LIST}
						key={cell.column.id}
						cell={cell}
					/>
				);

			case ACTIONS:
				return <Actions cell={cell} key={cell.column.id} type={type} />;

			default:
				return (
					<TableCell key={cell.column.id} {...cell.getCellProps()}>
						{cell.render('Cell')}
					</TableCell>
				);
		}
	};

	return renderCell(cell);
};

Cell.propTypes = {
	cell: object.isRequired,
	isHovered: bool,
};
