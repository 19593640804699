import React from 'react';
import { array, bool } from 'prop-types';
import { Card } from '@material-ui/core';

// Import components
import { Grid, Typography } from 'components/elements';

// Import styles
import { useStyles } from './PermissionsSection.styles';

// Import utils and helpers
import { useCommonFields, useTranslations } from 'components/utilities';
import { GET_PERMISSION_FIELD } from './helpers';
import { PERMISSIONS_OPTIONS_DEFAULT } from 'views/HelpCenter/helpers';

export const PermissionsSection = ({
	options = PERMISSIONS_OPTIONS_DEFAULT,
	disabled = false,
}) => {
	const classes = useStyles();
	const { renderFields } = useCommonFields();
	const PERMISSION_FIELD = GET_PERMISSION_FIELD({ options, disabled });
	const { t } = useTranslations();

	return (
		<Card className={classes.card}>
			<Typography className={classes.title}>
				{t('help_center.permissions')}
			</Typography>
			<Typography className={classes.subtitle}>
				{t('help_center.permissions_section_description')}
			</Typography>
			<Grid container>{renderFields(PERMISSION_FIELD)}</Grid>
		</Card>
	);
};

PermissionsSection.propTypes = {
	options: array,
	disabled: bool,
};
