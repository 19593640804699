import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import axios from 'axios';

// Import utils and helpers
import { useProfile } from 'components/utilities';

// Import store
import { previewSelector } from 'store/selectors';
import { fetchBookingPreview } from 'store/actions';

export const useEditBooking = () => {
	const { isCorporateCoordinator } = useProfile();
	const dispatch = useDispatch();
	const { id } = useParams();

	const { item, isLoaded, isError, error } = useSelector(previewSelector);

	useEffect(() => {
		const source = axios.CancelToken.source();
		const cancelToken = source.token;

		const fetchData = async () => {
			await fetchBookingPreview({ id, cancelToken, isCorporateCoordinator })(
				dispatch
			);
		};
		fetchData();
		return () => {
			source.cancel();
		};
		// eslint-disable-next-line
	}, []);

	return {
		item,
		isLoaded,
		isError,
		error,
	};
};
