import React from 'react';
import { Button, Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { bool } from 'prop-types';

// Import utils and helpers
import { useTranslations, useCommonFields } from 'components/utilities';
import { CORPORATE_FIELDS } from './helpers';
import { URLS } from 'components/routes';

// Import components
import { ButtonProgress, Card } from 'components/elements';

export const FormContent = ({ isButtonLoading }) => {
	const { renderFields } = useCommonFields();
	const { t } = useTranslations();
	const history = useHistory();

	const handleOnCancelButton = () => history.push(URLS.corporateListUrl);

	return (
		<Card title={t('b2b.corporate.form.add_corporate')} lg={12}>
			<Grid container spacing={2} alignItems="stretch">
				{CORPORATE_FIELDS().map(renderFields)}
				<Grid item xs={12}>
					<Grid container justifyContent="flex-end" spacing={2}>
						<Grid item>
							<Button
								onClick={handleOnCancelButton}
								variant="contained"
								color="primary"
							>
								{t('common.buttons.cancel')}
							</Button>
						</Grid>
						<Grid item>
							<ButtonProgress
								isLoading={isButtonLoading}
								disabled={isButtonLoading}
								variant="contained"
								color="primary"
								type="submit"
							>
								{t('common.buttons.save')}
							</ButtonProgress>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Card>
	);
};

FormContent.propTypes = {
	isButtonLoading: bool,
};
