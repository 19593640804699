import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	container: {
		display: 'flex',
		border: 'solid 1px',
		borderColor: theme.palette.background[7],
		borderRadius: '8px',
		width: '100%',
		padding: '16px',
		marginBottom: '16px',
		backgroundColor: 'white',
	},
	icon: {
		color: theme.palette.text[3],
		marginRight: '16px',
	},
	name: {
		color: theme.palette.text.inputLabel,
		fontSize: '14px',
		fontWeight: 600,
	},
	position: {
		color: theme.palette.text[3],
		fontSize: '14px',
		fontWeight: 600,
	},
}));
