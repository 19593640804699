import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const Bag = (props) => (
	<SvgIcon width="20" height="20" viewBox="0 0 25 25" fill="none" {...props}>
		<path
			d="M16.9219 10.666V6.66602C16.9219 4.45702 15.1309 2.66602 12.9219 2.66602V2.66602C10.7129 2.66602 8.92188 4.45702 8.92188 6.66602V10.666"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M19.9219 21.666H5.92188C5.36988 21.666 4.92188 21.218 4.92188 20.666V8.66602C4.92188 8.11402 5.36988 7.66602 5.92188 7.66602H19.9219C20.4739 7.66602 20.9219 8.11402 20.9219 8.66602V20.666C20.9219 21.218 20.4739 21.666 19.9219 21.666Z"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
	</SvgIcon>
);
