// Import helpers
import { createSubsection, editSubsection } from 'store/actions';
import { HELP_CENTER_TYPES } from 'helpers';
import { helpCenterErrorCallback, helpCenterSuccessCallback } from '../helpers';

export const INITIAL_VALUES = {
	name: { en: '', ar: '' },
	permission: 'all',
	is_active: false,
	icon: '',
	section_uuids: [],
};

export const handleSubmit = ({ values, message, history, isEdit, uuid }) => {
	if (isEdit) {
		return editSubsection({
			uuid,
			data: values,
			successCallback: () =>
				helpCenterSuccessCallback({
					history,
					type: HELP_CENTER_TYPES.SUBSECTIONS,
					message,
				}),
			errorCallback: (error) => helpCenterErrorCallback({ error, message }),
		});
	}
	return createSubsection({
		data: values,
		successCallback: () =>
			helpCenterSuccessCallback({
				history,
				type: HELP_CENTER_TYPES.SUBSECTIONS,
				message,
			}),
		errorCallback: (error) => helpCenterErrorCallback({ error, message }),
	});
};
