import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const FoldServiceer = (props) => (
	<SvgIcon width="20" height="20" viewBox="0 0 25 25" fill="none" {...props}>
		<ellipse
			cx="12.9219"
			cy="12.666"
			rx="10"
			ry="10"
			stroke="currentColor"
			strokeWidth="1.8"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<ellipse
			cx="12.9219"
			cy="12.666"
			rx="2.5"
			ry="2.5"
			stroke="currentColor"
			strokeWidth="1.8"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M12.0408 10.3365C12.1798 9.32997 12.7055 8.41718 13.5064 7.79186C13.7609 7.59291 13.9076 7.28631 13.9029 6.9633C13.8982 6.64029 13.7426 6.33811 13.4824 6.14666C12.8224 5.68219 11.986 5.54542 11.2124 5.77544C10.3555 6.02627 9.68976 6.70342 9.45356 7.56454C9.26452 8.2356 9.35093 8.95434 9.69362 9.56148C10.0363 10.1686 10.607 10.614 11.2792 10.799"
			stroke="currentColor"
			strokeWidth="1.8"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M13.8036 14.9957C13.6646 16.0023 13.1389 16.9151 12.338 17.5404C12.0835 17.7393 11.9368 18.0459 11.9415 18.3689C11.9462 18.6919 12.1018 18.9941 12.362 19.1855C13.0221 19.65 13.8584 19.7868 14.632 19.5568C15.489 19.306 16.1547 18.6288 16.3909 17.7677C16.58 17.0966 16.4936 16.3779 16.1509 15.7707C15.8082 15.1636 15.2375 14.7182 14.5653 14.5332"
			stroke="currentColor"
			strokeWidth="1.8"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M10.5885 13.5477C9.58191 13.4088 8.66913 12.8831 8.04381 12.0822C7.84486 11.8277 7.53826 11.6809 7.21526 11.6857C6.89225 11.6904 6.59006 11.846 6.39861 12.1062C5.93414 12.7662 5.79737 13.6025 6.0274 14.3761C6.27826 15.2331 6.9554 15.8988 7.81652 16.135C8.48758 16.3241 9.20633 16.2377 9.81347 15.895C10.4206 15.5523 10.866 14.9816 11.051 14.3094"
			stroke="currentColor"
			strokeWidth="1.8"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M15.2554 11.781C16.262 11.92 17.1748 12.4457 17.8001 13.2466C17.9991 13.5011 18.3057 13.6479 18.6287 13.6432C18.9517 13.6384 19.2539 13.4828 19.4453 13.2226C19.9098 12.5626 20.0466 11.7263 19.8165 10.9527C19.5657 10.0957 18.8885 9.43003 18.0274 9.1938C17.3563 9.00476 16.6376 9.09116 16.0305 9.43385C15.4233 9.77654 14.9779 10.3472 14.793 11.0194"
			stroke="currentColor"
			strokeWidth="1.8"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
	</SvgIcon>
);
