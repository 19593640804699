import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import clsx from 'clsx';
import { array } from 'prop-types';

// Import styles
import { useStyles } from './InnerNavbarElements.styles';

//Import utils and helpers
import { useProfile, useTranslations } from 'components/utilities';
import { LINKS } from './helpers';

export const InnerNavbarElements = ({ links = null }) => {
	const { pathname } = useLocation();
	const { t } = useTranslations();
	const classes = useStyles();
	const { isAdmin } = useProfile();

	const isLinkActive = (url) => pathname === url;

	const linksList = links || LINKS(isAdmin);

	return (
		<Grid container spacing={3}>
			{linksList.map(({ label, url }) => (
				<Grid item key={url}>
					<Link
						to={url}
						className={clsx({
							[classes.activeLink]: isLinkActive(url),
							[classes.link]: true,
						})}
					>
						{t(label)}
					</Link>
				</Grid>
			))}
		</Grid>
	);
};

InnerNavbarElements.propTypes = {
	links: array,
};
