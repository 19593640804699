import React from 'react';
import { TableCell } from '@material-ui/core';
import { bool, object } from 'prop-types';
import { format } from 'date-fns';
import { slice } from 'lodash';

// Import helpers
import {
	STATUS_ACTIVE_INACTIVE_CELL,
	DEFAULT_DATE_TIME_COMMA_FORMAT,
	replaceHelpCenterViewFormUrl,
	HELP_CENTER_TYPES,
} from 'helpers';
import { STATUS_LIST } from 'views/HelpCenter/helpers';

// Import components
import {
	CommonStatusTableCell,
	LinkTableCell,
	TextWithTooltipTableCell,
} from 'components/elements';
import { Actions } from 'views/HelpCenter/HelpCenterConfiguration/components';

export const Cell = ({ columnsAccessor, cell }) => {
	const {
		created_at: createdAt,
		name,
		uuid,
		is_active: isActive,
		questions,
	} = cell.row.original;

	const { CREATED_AT, STATUS, ACTIONS, QUESTIONS, SOLUTIONS } = columnsAccessor;

	const hoverQuestions = slice(questions, 1, questions.length);

	const type = HELP_CENTER_TYPES.SOLUTIONS;

	const renderCell = (cell) => {
		if (cell.column.isHidden) return null;

		switch (cell.column.id) {
			case SOLUTIONS:
				return (
					<LinkTableCell
						key={cell.column.id}
						cell={cell}
						to={replaceHelpCenterViewFormUrl({
							uuid,
							form: type,
						})}
					>
						{name}
					</LinkTableCell>
				);

			case QUESTIONS:
				return (
					<TextWithTooltipTableCell
						key={cell.column.id}
						text={questions?.[0]?.name}
						hoverText={hoverQuestions.map(({ name }) => name).join(' / ')}
						cell={cell}
					/>
				);

			case CREATED_AT:
				return (
					<TableCell key={cell.column.id} {...cell.getCellProps()}>
						{format(new Date(createdAt), DEFAULT_DATE_TIME_COMMA_FORMAT)}
					</TableCell>
				);

			case STATUS:
				return (
					<CommonStatusTableCell
						currentStatus={
							isActive
								? STATUS_ACTIVE_INACTIVE_CELL.ACTIVE
								: STATUS_ACTIVE_INACTIVE_CELL.INACTIVE
						}
						statusList={STATUS_LIST}
						key={cell.column.id}
						cell={cell}
					/>
				);

			case ACTIONS:
				return <Actions cell={cell} key={cell.column.id} type={type} />;

			default:
				return (
					<TableCell key={cell.column.id} {...cell.getCellProps()}>
						{cell.render('Cell')}
					</TableCell>
				);
		}
	};

	return renderCell(cell);
};

Cell.propTypes = {
	cell: object.isRequired,
	isHovered: bool,
};
