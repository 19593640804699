import React from 'react';
import { elementType, func, bool } from 'prop-types';
import { Box } from '@material-ui/core';
import clsx from 'clsx';

// Import styles
import { useStyles } from './IconContainer.styles';

export const IconContainer = ({ icon: Icon, checked, handleOnClick }) => {
	const classes = useStyles();

	return (
		<Box
			className={clsx({
				[classes.iconContainer]: !checked,
				[classes.chekedIconContainer]: checked,
			})}
			onClick={handleOnClick}
		>
			<Icon className={classes.icon} />
		</Box>
	);
};

IconContainer.propTypes = {
	icon: elementType.isRequired,
	checked: bool.isRequired,
	handleOnClick: func.isRequired,
};
