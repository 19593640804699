import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const Call = (props) => (
	<SvgIcon width="20" height="20" viewBox="0 0 25 25" fill="none" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M11.78 13.8104C10.61 12.6404 9.72797 11.3304 9.14197 10.0014C9.01797 9.72038 9.09097 9.39138 9.30797 9.17438L10.127 8.35637C10.798 7.68537 10.798 6.73638 10.212 6.15038L9.03797 4.97637C8.25697 4.19537 6.99097 4.19537 6.20997 4.97637L5.55797 5.62838C4.81697 6.36938 4.50797 7.43837 4.70797 8.49837C5.20197 11.1114 6.71997 13.9724 9.16897 16.4214C11.618 18.8704 14.479 20.3884 17.092 20.8824C18.152 21.0824 19.221 20.7734 19.962 20.0324L20.613 19.3814C21.394 18.6004 21.394 17.3344 20.613 16.5534L19.44 15.3804C18.854 14.7944 17.904 14.7944 17.319 15.3804L16.416 16.2844C16.199 16.5014 15.87 16.5744 15.589 16.4504C14.26 15.8634 12.95 14.9804 11.78 13.8104Z"
			stroke="currentColor"
			strokeWidth="1.8"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
	</SvgIcon>
);
