import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { ENDPOINTS, QUERY_KEYS } from 'helpers';

export const useSubsectionDetails = ({ enabled, uuid }) => {
	const { data, isError, isFetching } = useQuery(
		QUERY_KEYS.HELP_CENTER.SUBSECTIONS.SUBSECTION_DETAILS,
		async () =>
			await services.get(
				ENDPOINTS.HELP_CENTER.SUBSECTIONS.SUBSECTION_DETAILS(uuid)
			),
		{ refetchOnWindowFocus: false, enabled }
	);

	const fetchedData = data?.data || null;

	return { data: fetchedData, isFetching, isError };
};
