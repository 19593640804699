import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useForm, useFormState } from 'react-final-form';

// Import utils and helpers
import { useQuestionsList, useSolutionDetails } from 'queries';
import { useTranslations } from 'components/utilities';
import { HELP_CENTER_FORM_ENTRIES } from 'helpers';
import { useHelpCenterContextProvider } from 'views/HelpCenter/context/HelpCenterContext';
import { SOLUTION_PERMISSIONS } from '../SolutionType/helpers';
import { getFilteredQuestions } from './helpers';

export const useFormContent = () => {
	const { id: uuid, entry } = useParams();

	const {
		i18n: { language: lang },
	} = useTranslations();

	const { values } = useFormState();

	const { change } = useForm();

	const { permission_filter: permission, type } = values || {};

	const { setInitialValues } = useHelpCenterContextProvider();

	const { data: solutionDetails } = useSolutionDetails({
		enabled: !!uuid,
		uuid,
	});

	const { data: questionsList, isFetching } = useQuestionsList();

	const questionsOptions = getFilteredQuestions({
		questions: questionsList,
		permission,
		type,
	}).map(({ name, uuid }) => ({
		label: name[lang],
		value: uuid,
	}));

	const isViewOnly = entry === HELP_CENTER_FORM_ENTRIES.VIEW;
	const isAdd = entry === HELP_CENTER_FORM_ENTRIES.ADD;

	useEffect(() => {
		isAdd && change('question_uuids', []);
		// eslint-disable-next-line
	}, [permission, type]);

	useEffect(() => {
		if (solutionDetails) {
			setInitialValues({
				...solutionDetails,
				permission_filter: SOLUTION_PERMISSIONS.ALL_AGENTS,
			});
		}
		// eslint-disable-next-line
	}, [solutionDetails]);

	return { questionsOptions, isFetching, entry, isViewOnly };
};
