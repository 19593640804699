import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	label: {
		color: theme.palette.text.inputLabel,
		fontSize: '12px',
		fontWeight: 600,
		marginBottom: '16px',
		'& span': {
			color: theme.palette.error.medium,
		},
	},
	iconSelectionContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		width: '300px',
		border: 'solid 1px',
		borderRadius: '8px',
		borderColor: theme.palette.background[3],
		padding: '14px 22px',
	},
	flex: {
		display: 'flex',
		gap: '12px',
	},
}));
