import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	sectionTitle: {
		lineHeight: 2,
		fontSize: 8,
		fontWeight: 500,
		color: theme.palette.text.primary,
		[theme.breakpoints.up('md')]: {
			fontSize: 14,
		},
		[theme.breakpoints.up('lg')]: {
			fontSize: 16,
		},

		'& :last-child': {
			color: theme.palette.text.inputLabel,
		},
	},
	line: {
		height: '40px',
	},
	sectionPreviousParts: {
		color: theme.palette.text[3],
		fontSize: 'inherit',
		fontWeight: 500,
	},
	sectionTitleContainer: {
		flexWrap: 'nowrap',
		alignItems: 'center',
	},
	backButton: {
		'&:hover': {
			backgroundColor: 'transparent',
			boxShadow: 'none',
		},
	},
}));
