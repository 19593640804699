import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const XIcon = (props) => (
	<SvgIcon width="20" height="20" viewBox="0 0 35 35" fill="none" {...props}>
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="40"
			height="40"
			viewBox="0 0 40 40"
			fill="none"
		>
			<path
				d="M13.332 13.334L26.6654 26.6673"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M26.6654 13.334L13.332 26.6673"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	</SvgIcon>
);
