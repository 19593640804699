import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const Discounts = (props) => (
	<SvgIcon width="20" height="20" viewBox="0 0 25 25" fill="none" {...props}>
		<path
			d="M10.0876 10.8108C10.231 10.9541 10.231 11.1865 10.0876 11.3298C9.94431 11.4731 9.71194 11.4731 9.56862 11.3298C9.42529 11.1865 9.42529 10.9541 9.56862 10.8108C9.71194 10.6675 9.94431 10.6675 10.0876 10.8108"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M12.6319 6.95602L19.8419 14.166C20.6139 14.938 20.6139 16.19 19.8419 16.962L15.7179 21.086C14.9459 21.858 13.6939 21.858 12.9219 21.086L5.71187 13.876C5.52687 13.691 5.42188 13.439 5.42188 13.177V7.65502C5.42187 7.10902 5.86488 6.66602 6.41088 6.66602H11.9339C12.1959 6.66602 12.4469 6.77002 12.6319 6.95602V6.95602Z"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M20.4219 10.666L13.9159 4.24302C13.5409 3.87302 13.0369 3.66602 12.5109 3.66602H8.42188"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
	</SvgIcon>
);
