import React from 'react';
import { node, func } from 'prop-types';
import { Form } from 'react-final-form';
import { Box } from '@material-ui/core';

// Import helpers
import { useHelpCenterContextProvider } from 'views/HelpCenter/context/HelpCenterContext';

export const FormComponent = ({ children, onSubmit, validate }) => {
	const { initialValues } = useHelpCenterContextProvider();

	return (
		<Form
			initialValues={initialValues}
			onSubmit={onSubmit}
			validate={validate}
			render={({ handleSubmit }) => (
				<Box component="form" onSubmit={handleSubmit}>
					{children}
				</Box>
			)}
		/>
	);
};

FormComponent.propTypes = {
	children: node.isRequired,
	onSubmit: func.isRequired,
	validate: func.isRequired,
};
