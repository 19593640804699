import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	card: {
		padding: '24px',
		borderRadius: '16px',
		boxShadow: 'none',
	},
	title: {
		color: theme.palette.text.inputLabel,
		fontSize: '18px',
		fontWeight: 600,
		marginBottom: '8px',
	},
	subtitle: {
		color: theme.palette.text.secondary,
		fontSize: '14px',
		fontWeight: 400,
		marginBottom: '24px',
	},
	radioWrapper: {
		'& .MuiTypography-root': {
			color: theme.palette.text.inputLabel,
			fontSize: '16px',
			fontWeight: 400,
		},
	},
}));
