// Import helpers
import { FIELD_TYPES } from 'helpers';

const questionTypeOptions = [
	{
		label: 'help_center.chat_with_the_rental_company',
		value: 'chat_with_rental_company',
	},
	{ label: 'help_center.chat_with_telgani', value: 'chat_with_telgani' },
	{ label: 'help_center.content_article', value: 'content_article' },
];

export const GET_RADIO_FIELD = ({ disabled }) => ({
	type: FIELD_TYPES.RADIO_GROUP,
	name: 'type',
	options: questionTypeOptions,
	labelExtractor: ({ label }) => label,
	keyExtractor: ({ value }) => value,
	disabled,
});
