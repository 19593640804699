import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const Dashboard = (props) => (
	<SvgIcon width="20" height="20" viewBox="0 0 25 25" fill="none" {...props}>
		<path
			d="M12.9219 4.66797V7.10697"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M21.9219 13.666H19.9219"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M3.92188 13.666H5.92188"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M19.2802 7.30664L14.3242 12.2626"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M12.9023 11.666C14.0073 11.666 14.9023 12.561 14.9023 13.666C14.9023 14.771 14.0073 15.666 12.9023 15.666C11.7973 15.666 10.9023 14.771 10.9023 13.666C10.9023 12.561 11.7973 11.666 12.9023 11.666"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M6.5625 7.30664L8.6825 9.42664"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M17.1621 17.9063L19.2821 20.0263L19.2861 20.0303C22.8011 16.5153 22.8011 10.8173 19.2861 7.30227C15.7711 3.78727 10.0731 3.78727 6.55813 7.30227C3.04312 10.8173 3.04312 16.5153 6.55813 20.0303L6.56212 20.0263L8.56213 18.0263"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
	</SvgIcon>
);
