import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const OtherBookingIssues = (props) => (
	<SvgIcon width="20" height="20" viewBox="0 0 25 25" fill="none" {...props}>
		<path
			d="M19.4262 12.6643C19.4262 12.9405 19.2022 13.1645 18.926 13.1645C18.6497 13.1645 18.4258 12.9405 18.4258 12.6643C18.4258 12.388 18.6497 12.1641 18.926 12.1641C19.2022 12.1641 19.4262 12.388 19.4262 12.6643"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M13.4223 12.6643C13.4223 12.9405 13.1983 13.1645 12.9221 13.1645C12.6458 13.1645 12.4219 12.9405 12.4219 12.6643C12.4219 12.388 12.6458 12.1641 12.9221 12.1641C13.1983 12.1641 13.4223 12.388 13.4223 12.6643"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M7.42229 12.6643C7.42229 12.9405 7.19834 13.1645 6.92208 13.1645C6.64583 13.1645 6.42188 12.9405 6.42188 12.6643C6.42188 12.388 6.64583 12.1641 6.92208 12.1641C7.19834 12.1641 7.42229 12.388 7.42229 12.6643"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
	</SvgIcon>
);
