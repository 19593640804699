import React, { useEffect } from 'react';
import clsx from 'clsx';
import { string, array, func, bool, object, number, element } from 'prop-types';
import axios from 'axios';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
	Card,
	CardActions,
	CardContent,
	Table as MaterialTable,
	TableBody,
	TableHead,
	CircularProgress,
	Typography,
	Divider,
} from '@material-ui/core';
import {
	useTable,
	useSortBy,
	usePagination,
	useGlobalFilter,
} from 'react-table';
import _ from 'lodash';
import { useLocation } from 'react-router-dom';

// Import helpers
import { TABLE_OPTIONS } from 'helpers';
import { setDefaultLabelDisplayRow } from './helpers';

// Import styles
import { useStyles } from './Table.styles';

// Import components
import { useTableContextProvider } from 'components/context';
import { Alert } from 'components/elements';
import {
	TableBodyRow,
	TableHeadRow,
	TablePagination,
	SearchToolbar,
	InnerNavbar,
} from './components';

// Import utilities
import {
	useTranslations,
	useQueryStringParams,
	useIsRTL,
} from 'components/utilities';

const { ROWS_PER_PAGE, PAGE_INDEX, PAGE_SIZE } = TABLE_OPTIONS;

const Table = ({
	className,
	rowsPerPageOptions = ROWS_PER_PAGE,
	initialPageSize = PAGE_SIZE,
	isDefaultRow = true,
	labelDisplayedRows = setDefaultLabelDisplayRow,
	children,
	editLinkPath = '/bookings',
	externalFilters,
	filtersBar = null,
	additionalNavigationElements,
	tableNavigation,
	showSearchInput = true,
	title,
	tableInnerToolbar,
	searchBarPlaceholder,
	isPaginationVisible = true,
	...rest
}) => {
	const isRTL = useIsRTL();
	const classes = useStyles({ isRTL });

	const { t } = useTranslations();

	const location = useLocation();

	const { parsedFilters, parsedOptions } = useQueryStringParams();

	const {
		columns,
		inputColumns,
		data,
		roles: userRolesData,
		fetchData,
		loading,
		filters,
		pageCount: controlledPageCount,
		error,
		recordsFiltered,
		setFilters,
	} = useTableContextProvider();

	// Use the state and functions returned from useTable to build your UI
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page,
		gotoPage,
		setPageSize,
		pageOptions,
		setGlobalFilter,
		state,
		pageCount,
	} = useTable(
		{
			columns,
			data,
			initialState: {
				pageIndex: parsedOptions.pageIndex || PAGE_INDEX,
				pageSize: parsedOptions.pageSize || initialPageSize,
				globalFilter: parsedOptions.globalFilter || '',
			}, // Pass our hoisted table state
			manualPagination: true,
			manualGlobalFilter: true,
			manualSortBy: true, // Tell the usePagination
			// hook that we'll handle our own data fetching
			// This means we'll also have to provide our own
			// pageCount.
			pageCount: controlledPageCount,
		},
		useGlobalFilter,
		useSortBy,
		usePagination
	);

	const { pageIndex, pageSize, sortBy, globalFilter } = state;

	const currentPageRowsCount = data.length;

	// Listen for changes in pagination etc and use the state to fetch our new data
	useEffect(() => {
		const source = axios.CancelToken.source();
		const cancelToken = source.token;

		fetchData(
			{ pageIndex, pageSize, sortBy, globalFilter, externalFilters },
			inputColumns,
			filters,
			cancelToken
		);

		return () => {
			source.cancel();
		};
		// eslint-disable-next-line
	}, [
		fetchData,
		pageIndex,
		pageSize,
		sortBy,
		globalFilter,
		filters,
		externalFilters,
	]);

	useEffect(() => {
		_.isNumber(parsedOptions.pageIndex) &&
			!_.isEqual(parsedOptions.pageIndex, pageIndex) &&
			gotoPage(parsedOptions.pageIndex);

		_.isNumber(parsedOptions.pageSize) &&
			!_.isEqual(parsedOptions.pageSize, pageSize) &&
			parsedOptions.pageSize !== pageSize &&
			setPageSize(parsedOptions.pageSize);

		_.isString(parsedOptions.globalFilter) &&
			!_.isEqual(parsedOptions.globalFilter, globalFilter) &&
			setGlobalFilter(parsedOptions.globalFilter);

		// Important to compare with JSON.stringify because of different date types
		!(JSON.stringify(filters) === JSON.stringify(parsedFilters)) &&
			setFilters(parsedFilters);

		// eslint-disable-next-line
	}, [location.search]);

	return (
		<>
			{error && <Alert message={error} />}
			<Card {...rest} className={clsx(classes.root, className)}>
				{title && (
					<>
						<Typography className={classes.title}>{title}</Typography>
						<Divider />
					</>
				)}

				{(additionalNavigationElements || tableNavigation) && (
					<InnerNavbar
						additionalNavigationElements={additionalNavigationElements}
						tableNavigation={tableNavigation}
					/>
				)}
				<SearchToolbar
					setGlobalFilter={setGlobalFilter}
					globalFilter={parsedOptions.globalFilter || globalFilter}
					filtersBar={filtersBar}
					searchBarPlaceholder={searchBarPlaceholder}
					showSearchInput={showSearchInput}
					TableInnerToolbar={tableInnerToolbar}
				/>

				<CardContent className={classes.content}>
					{loading && (
						<div className={classes.loader}>
							<CircularProgress size={48} color="primary" />
						</div>
					)}

					{!loading && currentPageRowsCount === 0 && (
						<Typography className={classes.noData} variant="subtitle1">
							{t('table.no_data')}
						</Typography>
					)}

					<PerfectScrollbar>
						<div className={classes.inner}>
							<MaterialTable {...getTableProps()} aria-label="simple table">
								<TableHead className={classes.tHead}>
									{headerGroups.map((headerGroup, index) => (
										<TableHeadRow
											currentPageRowsCount={currentPageRowsCount}
											key={headerGroup.headers[index].id}
											headerGroup={headerGroup}
										/>
									))}
								</TableHead>
								<TableBody {...getTableBodyProps()}>
									{page.map((row) => {
										prepareRow(row);
										return isDefaultRow ? (
											<TableBodyRow
												editLinkPath={editLinkPath}
												key={row.id}
												row={row}
												userRolesData={userRolesData}
											/>
										) : (
											children({ row, editLinkPath, userRolesData })
										);
									})}
								</TableBody>
							</MaterialTable>
						</div>
					</PerfectScrollbar>
				</CardContent>
				{isPaginationVisible && (
					<CardActions>
						<TablePagination
							labelDisplayedRows={labelDisplayedRows}
							rowsPerPageOptions={rowsPerPageOptions}
							gotoPage={gotoPage}
							pageCount={pageCount}
							pageOptions={pageOptions}
							pageIndex={parsedOptions.pageIndex || pageIndex}
							pageSize={parsedOptions.pageSize || pageSize}
							setPageSize={setPageSize}
							recordsFiltered={recordsFiltered}
							currentPageRowsCount={currentPageRowsCount}
						/>
					</CardActions>
				)}
			</Card>
		</>
	);
};

Table.propTypes = {
	className: string,
	rowsPerPageOptions: array,
	initialPageSize: number,
	children: func,
	labelDisplayedRows: func,
	isDefaultRow: bool,
	editLinkPath: string,
	externalFilters: object,
	tableInnerToolbar: func,
	additionalNavigationElements: element,
	tableNavigation: element,
	filtersBar: element,
	showSearchInput: bool,
	title: string,
	searchBarPlaceholder: string,
	isPaginationVisible: bool,
};

export default Table;
