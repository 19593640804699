import React from 'react';

// import components
import { Grid } from 'components/elements';
import {
	TitleSection,
	PermissionsSection,
	StatusSection,
} from 'views/HelpCenter/components';
import { SectionPosition } from './components';

// import helpers
import { useFormContent } from './useFormContent';

export const FormContent = () => {
	const { isViewOnly } = useFormContent();
	return (
		<Grid container spacing={3}>
			<Grid item md={8} xs={12}>
				<TitleSection title="help_center.section_title" disabled={isViewOnly}>
					<SectionPosition disabled={isViewOnly} />
				</TitleSection>
			</Grid>
			<Grid item md={4} xs={12} container spacing={3}>
				<Grid item md={12}>
					<PermissionsSection disabled={isViewOnly} />
				</Grid>
				<Grid item md={12}>
					<StatusSection disabled={isViewOnly} />
				</Grid>
			</Grid>
		</Grid>
	);
};
