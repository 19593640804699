import { FIELD_TYPES } from 'helpers';

export const getFields = ({
	activeOptions,
	onItemSelect,
	selectedOptions,
	disabled,
}) =>
	activeOptions.map((option) => ({
		type: FIELD_TYPES.CHECKBOX,
		name: option.value,
		label: option.label,
		onChange: onItemSelect,
		checked:
			selectedOptions &&
			selectedOptions.findIndex((item) => item === option.value) !== -1,
		disabled,
	}));
