import React from 'react';
import { Card } from '@material-ui/core';
import { useFormState } from 'react-final-form';
import { bool } from 'prop-types';

// Import components
import { Grid, Typography } from 'components/elements';

// Import styles
import { useStyles } from './SolutionText.styles';

// Import utils and helpers
import { useCommonFields, useTranslations } from 'components/utilities';
import { CONTENT_ARTICLE_FIELDS, QUICK_RESPONSE_FIELDS } from './helpers';
import { SOLUTION_TYPES } from 'views/HelpCenter/helpers';

export const SolutionText = ({ disabled = false }) => {
	const classes = useStyles();
	const { t } = useTranslations();
	const { renderFields } = useCommonFields();

	const { values } = useFormState();

	const { type } = values || {};

	if (!type) return null;

	const FIELDS =
		type === SOLUTION_TYPES.CHAT_RESPONSE
			? QUICK_RESPONSE_FIELDS(disabled)
			: CONTENT_ARTICLE_FIELDS(disabled);

	return (
		<Card className={classes.card}>
			<Typography fontSize={18} fontWeight={600} className={classes.title}>
				{t('help_center.solution_text')}
			</Typography>

			<Grid container spacing={3} className={classes.radioButtonsWrapper}>
				{FIELDS.map(renderFields)}
			</Grid>
		</Card>
	);
};

SolutionText.propTypes = {
	disabled: bool,
};
