// Import helpers
import { ENDPOINTS, combineQueryAndPaginationParams } from 'helpers';
import services from 'services/services';
import { fetchSolutionsListUrl } from 'store/paths';

export const fetchSolutionsList =
	({ queryParams, cancelToken, options }) =>
	async () => {
		try {
			const { pageIndex, pageSize, isExport } = options;

			const body = {
				start: pageIndex * pageSize,
				length: pageSize,
				is_export: isExport,
			};

			const params = combineQueryAndPaginationParams({ queryParams, body });

			const { data } = await services.get(
				fetchSolutionsListUrl(params),
				cancelToken
			);

			return Promise.resolve({ data });
		} catch (error) {
			return Promise.reject(error);
		}
	};

export const createSolution = async ({
	data,
	successCallback,
	errorCallback,
}) => {
	try {
		const resp = await services.post(
			ENDPOINTS.HELP_CENTER.SOLUTIONS.CREATE_SOLUTION,
			data
		);
		successCallback(resp);
	} catch (error) {
		errorCallback(error);
	}
};

export const editSolution = async ({
	uuid,
	data,
	successCallback,
	errorCallback,
}) => {
	try {
		const resp = await services.put(
			ENDPOINTS.HELP_CENTER.SOLUTIONS.SOLUTION_DETAILS(uuid),
			data
		);
		successCallback(resp);
	} catch (error) {
		errorCallback(error);
	}
};
