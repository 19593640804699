import React, { useState, useEffect } from 'react';
import { bool, object, string, number } from 'prop-types';
import { useForm, useFormState } from 'react-final-form';
import clsx from 'clsx';
import _ from 'lodash';
import {
	InputLabel,
	Grid,
	Button,
	Typography as MuiTypography,
	CircularProgress,
	Typography,
	Box,
} from '@material-ui/core';

// Import styles
import { useStyles } from './styles';

// Import icons
import { OutlinedCloseIcon, UploadFileIcon, PaperPin } from 'assets/icons';

// Import components
import { IconButton, ImagePreview } from 'components/elements';

// Import utilities
import { useToggle, useTranslations } from 'components/utilities';
import services from 'services/services';

const fileUrl = '/v2/content/media/upload';

export const FileFieldAdapter = ({
	input,
	type = 'image',
	fileNameField,
	previewFileState = '',
	token = '',
	required = false,
	uploadButtonWidth = 5,
	label,
	disabled = false,
	size = '',
	binaryState = null,
	styleOverride,
	fullWidth = true,
	...rest
}) => {
	const { on, toggle } = useToggle();
	const { t } = useTranslations();
	const state = useFormState();
	const classes = useStyles({ fullWidth });
	const form = useForm();
	const previewImgValues = previewFileState.split('.');
	const imgPreviewObject = previewFileState.includes('.')
		? state.values[previewImgValues[0]][previewImgValues[1]]
		: state.values[previewFileState];

	const imgPreview = imgPreviewObject?.file_path;
	const imgPreviewName =
		imgPreviewObject?.file_name || t('common.buttons.preview');

	const touchedField = _.get(state.touched, `${previewFileState}.file_path`);
	const validationError = touchedField && _.get(state.errors, fileNameField);

	const [isError, setIsError] = useState(null);
	const [errorMessage, setErrorMessage] = useState('');
	const [loading, setLoading] = useState(false);
	const [imgUrl, setImgUrl] = useState(imgPreview || '');
	const [fileName, setFileName] = useState(imgPreviewName);

	const getFormData = (target) => {
		// Get uploaded file
		const imgFile = target.files[0];
		// Format uploaded file
		const formData = new FormData();
		// Append uploaded file
		formData.append(type, imgFile, imgFile.name);

		return formData;
	};

	const handleChange = async ({ target }) => {
		if (target.files[0]) {
			try {
				binaryState && form.change(binaryState, target.files[0]);
				setIsError(false);
				setErrorMessage('');
				setLoading(true);
				// Format uploaded file
				const formData = getFormData(target);
				const { data } = await services.post(fileUrl, formData);
				const { file_name, file_path, file_url } = data.data;
				setFileName(file_name);
				setImgUrl(file_url);
				setLoading(false);
				input.onChange(file_path);
				form.change(fileNameField, file_name);
			} catch (error) {
				setLoading(false);
				setIsError(true);
				const errors = error?.response?.data?.errors || {};
				const errorMessage =
					Object.values(errors)?.[0]?.join(' ') ||
					t('offices.form.logo.update_image_error');
				setErrorMessage(errorMessage);
			}
		}
	};

	const handleOnResetFile = () => {
		setFileName('');
		setImgUrl('');
		input.onChange('');
		form.change(fileNameField, '');
		binaryState && form.change(binaryState, '');
	};

	useEffect(() => {
		const handleOnValidationError = () => {
			if (!input.value) {
				setIsError(true);
				setErrorMessage(t('common.files.validation_error'));
			}
		};

		document.addEventListener('invalid', handleOnValidationError, true);

		return () => {
			document.removeEventListener('invalid', handleOnValidationError, true);
		};
		// eslint-disable-next-line
	}, [input.value]);

	useEffect(() => {
		if (touchedField) {
			setErrorMessage(validationError);
			setIsError(!!validationError);
		}
	}, [touchedField, validationError]);

	return (
		<Grid container spacing={3}>
			<Grid
				item
				md={uploadButtonWidth}
				xs={uploadButtonWidth}
				className={clsx({
					[classes.newStyleContainer]: styleOverride,
				})}
			>
				{label && (
					<Typography
						className={clsx({
							[classes.label]: true,
							[classes.error]: isError,
						})}
					>
						{`${t(label)} ${required ? '*' : ''}`}
					</Typography>
				)}

				<Box className={classes.buttonContainer}>
					<InputLabel
						className={clsx({
							[classes.newStyleButton]: styleOverride,
							[classes.buttonContent]: !styleOverride,
							[classes.buttonContentLarge]: size === 'large',
							[classes.buttonContentMedium]: size === 'medium',
						})}
						htmlFor={fileNameField}
					>
						{!styleOverride && <UploadFileIcon />}
						{t('common.files.choose_a_file')}
						{styleOverride && <PaperPin />}
						{loading && (
							<Box className={classes.loadingWrapper}>
								<CircularProgress size={24} className={classes.progress} />
							</Box>
						)}
					</InputLabel>

					{imgUrl && (
						<Box className={classes.previewWrapper}>
							<Button onClick={toggle}>{fileName}</Button>
							<IconButton size="small" onClick={handleOnResetFile}>
								<OutlinedCloseIcon className={classes.closeIcon} />
							</IconButton>
						</Box>
					)}

					<input
						className={classes.inputFile}
						id={fileNameField}
						type="file"
						onChange={handleChange}
						required={required && !input.value}
						disabled={disabled}
						{...rest}
					/>
					<input {...input} {...rest} type="hidden" onChange={handleChange} />
				</Box>
				{isError && (
					<MuiTypography className={classes.error}>
						{errorMessage}
					</MuiTypography>
				)}
			</Grid>
			<ImagePreview imgUrl={imgUrl} on={on} close={toggle} token={token} />
		</Grid>
	);
};

FileFieldAdapter.propTypes = {
	input: object.isRequired,
	meta: object.isRequired,
	fileNameField: string.isRequired,
	previewFileState: string,
	type: string,
	token: string,
	required: bool,
	uploadButtonWidth: number,
	label: string,
	disabled: bool,
	size: string,
	binaryState: string,
	styleOverride: bool,
	fullWidth: bool,
};
