import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

// Import utils and helpers
import { useMessage, useTranslations } from 'components/utilities';
import { convertInitialCoordinators } from '../../helpers';
import { useCoordinator, useCorporation } from 'queries';
import { convertSubmitCoordinators } from 'helpers';
import { getCoordinatorErrors } from './helpers';

//Import store
import {
	UpdateCorporatePromise,
	createOrUpdateCoordinatorPromise,
} from 'store/actions';

export const useWrapper = () => {
	const [isLoading, setLoading] = useState(false);
	const { id: corporateUuid } = useParams();
	const { message } = useMessage();
	const { t } = useTranslations();

	const {
		data: corporateData,
		isFetching: corporateFetching,
		isError: corporateError,
	} = useCorporation(corporateUuid);

	const {
		data: coordinatorData,
		isFetching: coordinatorFetching,
		isError: coordinatorError,
	} = useCoordinator(corporateUuid);

	const isError = coordinatorError || corporateError;
	const isFetching = coordinatorFetching || corporateFetching;

	const handleOnSubmit = async (values) => {
		let apiErrors = { coordinators: [] };
		let coordinatorErrorUuids = [];

		setLoading(true);

		const corporatePromise = UpdateCorporatePromise({ corporateUuid, values });

		const corporateSuccessCallback = () => {
			message.success(
				t('b2b.corporate.form.messages.corporate_was_updated_successfully')
			);
		};

		const coordinatorsSuccessCallback = () => {
			convertedCoordinators.forEach((coordinator, index) => {
				message.success(
					t(
						`b2b.corporate.form.messages.${
							coordinator?.uuid
								? 'coordinator_was_updated_successfully'
								: 'coordinator_was_created_successfully'
						}`,
						{ index: index + 1 }
					)
				);
			});
		};

		const convertedCoordinators = convertSubmitCoordinators({
			coordinators: values.coordinators,
			corporateUuid,
		});

		const coordinatorsPromises = convertedCoordinators.map((coordinator) =>
			createOrUpdateCoordinatorPromise({ coordinator, corporateUuid })
		);

		const results = await Promise.allSettled([
			corporatePromise,
			...coordinatorsPromises,
		]);

		const allSucceed = results.every((result) => result.status === 'fulfilled');

		const [corporateResult, ...coordinatorsResults] = results;

		if (allSucceed) {
			corporateSuccessCallback();
			coordinatorsSuccessCallback();
		} else {
			if (
				corporateResult.status === 'rejected' &&
				corporateResult.reason.response
			) {
				apiErrors = {
					...apiErrors,
					...corporateResult.reason.response.data.errors,
				};
			}

			coordinatorsResults.forEach((coordinatorResult, index) => {
				if (coordinatorResult.status === 'rejected') {
					coordinatorErrorUuids = [
						...coordinatorErrorUuids,
						{ index, error: coordinatorResult.reason.response.data.errors },
					];
					if (coordinatorResult.reason.response) {
						const coordinatorErrors = getCoordinatorErrors({
							convertedCoordinators,
							coordinatorErrorUuids,
						});
						apiErrors = coordinatorErrors;
					}
				}
			});

			message.error(t('common.messages.error_message'));
		}

		setLoading(false);

		return apiErrors;
	};

	const initialValues = useMemo(
		() => ({
			...corporateData,
			...(!corporateData?.affiliated_partner_company && {
				affiliated_partner_company: 'none',
			}),
			coordinators: convertInitialCoordinators(coordinatorData),
			logo: {
				file_path: corporateData?.logo_url,
				file_name: 'Uploaded File',
			},
		}),
		[corporateData, coordinatorData]
	);

	return {
		handleOnSubmit,
		initialValues,
		isFetching,
		isLoading,
		isError,
	};
};
