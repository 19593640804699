import React from 'react';
import { useParams, Redirect } from 'react-router-dom';

// Import components
import { SectionsForm } from '../SectionsForm';
import { SubSectionsForm } from '../SubSectionsForm';
import { QuestionsForm } from '../QuestionsForm';
import { SolutionsForm } from '../SolutionsForm';

// Import helpers
import { HELP_CENTER_TYPES } from 'helpers';
import { URLS } from 'components/routes';

export const HelpCenterFormController = () => {
	const { form } = useParams();
	const { SECTIONS, SUBSECTIONS, QUESTIONS, SOLUTIONS } = HELP_CENTER_TYPES;

	switch (form) {
		case SECTIONS:
			return <SectionsForm />;
		case SUBSECTIONS:
			return <SubSectionsForm />;
		case QUESTIONS:
			return <QuestionsForm />;
		case SOLUTIONS:
			return <SolutionsForm />;
		default:
			return <Redirect to={URLS.notFoundUrl} />;
	}
};
