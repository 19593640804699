// Import utils
import services from 'services/services';

// Import store
import {
	createCoordinatorUrl,
	deleteCoordinatorUrl,
	updateCoordinatorUrl,
} from 'store/paths';

export const createOrUpdateCoordinator = async ({
	successCallback,
	errorCallback,
	corporateUuid,
	coordinator,
}) => {
	try {
		const coordinatorUuid = coordinator?.uuid;
		await services[coordinatorUuid ? 'put' : 'post'](
			coordinatorUuid
				? updateCoordinatorUrl({ corporateUuid, coordinatorUuid })
				: createCoordinatorUrl(corporateUuid),
			coordinator
		);

		successCallback();
	} catch (error) {
		errorCallback(error);
	}
};

export const createOrUpdateCoordinatorPromise = ({
	corporateUuid,
	coordinator,
}) => {
	const coordinatorUuid = coordinator?.uuid;
	return services[coordinatorUuid ? 'put' : 'post'](
		coordinatorUuid
			? updateCoordinatorUrl({ corporateUuid, coordinatorUuid })
			: createCoordinatorUrl(corporateUuid),
		coordinator
	);
};

export const deleteCoordinator = async ({
	successCallback,
	errorCallback,
	corporateUuid,
	coordinatorUuid,
}) => {
	try {
		await services.delete(
			deleteCoordinatorUrl({ corporateUuid, coordinatorUuid })
		);

		successCallback();
	} catch {
		errorCallback();
	}
};
