import React from 'react';
import { node, func, object } from 'prop-types';
import { Box } from '@material-ui/core';

// Import components
import { Typography } from 'components/elements';
import { BottomBar, FormComponent } from './components';

// Import styles
import { useStyles } from './FormWrapper.styles';

// import utils and helpers
import { useTranslations } from 'components/utilities';
import { useFormWrapper } from './useFormWrapper';
import { HelpCenterContextProvider } from 'views/HelpCenter/context/HelpCenterContext';

export const FormWrapper = ({
	children,
	initialValues,
	onSubmit,
	validate,
}) => {
	const classes = useStyles();
	const { t } = useTranslations();
	const { message, history, isEdit, uuid, title, btnText } = useFormWrapper();

	return (
		<HelpCenterContextProvider initialState={initialValues}>
			<Box className={classes.container}>
				<Typography className={classes.title}>{t(title)}</Typography>
				<FormComponent
					onSubmit={(values) =>
						onSubmit({ values, message, history, isEdit, uuid })
					}
					validate={validate}
				>
					{children}
					<BottomBar submitBtnText={btnText} />
				</FormComponent>
			</Box>
		</HelpCenterContextProvider>
	);
};

FormWrapper.propTypes = {
	children: node.isRequired,
	initialValues: object.isRequired,
	onSubmit: func.isRequired,
	validate: func.isRequired,
};
