import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const Folder = (props) => (
	<SvgIcon width="20" height="20" viewBox="0 0 25 25" fill="none" {...props}>
		<path
			d="M13.9219 8.66602H3.92188"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M21.9219 18.4182V10.666C21.9219 9.56145 21.0264 8.66602 19.9219 8.66602H13.9219L11.8489 5.55662C11.478 5.00023 10.8536 4.66603 10.1849 4.66602H5.92188C4.81731 4.66602 3.92188 5.56145 3.92188 6.66602V18.666C3.92188 19.7706 4.81731 20.666 5.92188 20.666H19.9219C21.0264 20.666 21.9219 19.7706 21.9219 18.666"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
	</SvgIcon>
);
