const breakpoints = {
	xxs: 0,
	xs: 320,
	sm: 600,
	md: 768,
	lg: 1280 + 24 * 2, // 1280px wide + horizontal paddings 24px
	xl: 1680,
};
const directions = { MIN: 'min', MAX: 'max' };

export const customResponsive = Object.values(directions).reduce(
	(dirAccumulator, direction) => {
		dirAccumulator[direction] = Object.keys(breakpoints).reduce(
			(breakAccumulator, breakpoint) => {
				breakAccumulator[breakpoint] = (styles) => ({
					[`@media (${direction}-width: ${breakpoints[breakpoint]}px)`]: styles,
				});

				return breakAccumulator;
			},
			{}
		);

		return dirAccumulator;
	},
	{}
);
