// import helpers
import { combineQueryAndPaginationParams, ENDPOINTS } from 'helpers';
import services from 'services/services';
import { fetchQuestionsListUrl } from 'store/paths';

export const fetchQuestionsList =
	({ queryParams, cancelToken, options }) =>
	async () => {
		try {
			const { pageIndex, pageSize, isExport } = options;

			const body = {
				start: pageIndex * pageSize,
				length: pageSize,
				is_export: isExport,
			};

			const params = combineQueryAndPaginationParams({ queryParams, body });

			const { data } = await services.get(
				fetchQuestionsListUrl(params),
				cancelToken
			);

			return Promise.resolve({ data });
		} catch (error) {
			return Promise.reject(error);
		}
	};

export const createQuestion = async ({
	data,
	successCallback,
	errorCallback,
}) => {
	try {
		const resp = await services.post(
			ENDPOINTS.HELP_CENTER.QUESTIONS.CREATE_QUESTION,
			data
		);
		successCallback(resp);
	} catch (error) {
		errorCallback(error);
	}
};

export const editQuestion = async ({
	uuid,
	data,
	successCallback,
	errorCallback,
}) => {
	try {
		const resp = await services.put(
			ENDPOINTS.HELP_CENTER.QUESTIONS.QUESTION_DETAILS(uuid),
			data
		);
		successCallback(resp);
	} catch (error) {
		errorCallback(error);
	}
};
