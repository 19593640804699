import React from 'react';
import { bool, func } from 'prop-types';
import { DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import { DndContext, closestCorners } from '@dnd-kit/core';
import {
	SortableContext,
	verticalListSortingStrategy,
} from '@dnd-kit/sortable';

// import assets
import { XIcon } from 'assets/icons';

// Import components
import { Dialog, Typography, Button } from 'components/elements';
import { PositionItem } from './components';

// Import styles
import { useStyles } from './PositionsDialog.styles';

// Import utils and helpers
import { usePositionsDialog } from './usePositionsDialog';
import { useTranslations } from 'components/utilities';

export const PositionsDialog = ({ open, onClose }) => {
	const classes = useStyles();
	const { sectionPositions, handleDragEnd } = usePositionsDialog();
	const { t } = useTranslations();

	return (
		<Dialog open={open} onClose={onClose} className={classes.dialog}>
			<DialogTitle className={classes.dialogTitle}>
				<Typography className={classes.dialogTitleText}>
					{t('help_center.position')}
				</Typography>
				<XIcon className={classes.closeIcon} onClick={onClose} />
			</DialogTitle>
			<DialogContent>
				<Typography className={classes.description}>
					{t('help_center.drag_and_drop_to_change_position')}
				</Typography>
				<DndContext
					collisionDetection={closestCorners}
					onDragEnd={handleDragEnd}
				>
					<SortableContext
						items={sectionPositions}
						strategy={verticalListSortingStrategy}
					>
						{sectionPositions?.map(({ id, position, label }) => (
							<PositionItem
								name={id}
								position={position}
								key={id}
								label={label}
							/>
						))}
					</SortableContext>
				</DndContext>
			</DialogContent>
			<DialogActions>
				<Button
					text="common.buttons.cancel"
					type="dark_text"
					size="large"
					onClick={onClose}
				/>
				<Button text="common.buttons.save" size="large" onClick={onClose} />
			</DialogActions>
		</Dialog>
	);
};

PositionsDialog.propTypes = {
	open: bool.isRequired,
	onClose: func.isRequired,
};
