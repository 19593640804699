import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	dialog: {
		'& .MuiPaper-root': {
			width: 'fit-content',
		},
	},
	dialogTitle: {
		'& > .MuiTypography-root': {
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
			width: '100%',
		},
	},
	dialogTitleText: {
		color: theme.palette.text.inputLabel,
		fontSize: '20px',
		fontWeight: 500,
	},
	closeIcon: {
		color: theme.palette.text[2],
		cursor: 'pointer',
		width: '40px',
		height: '40px',
	},
	iconsContainer: {
		display: 'flex',
		flexWrap: 'wrap',
		gap: '16px',
		minWidth: '512px',
	},
}));
