export const initialValues = {
	is_active: 'all',
	questions: ['all'],
};

export const formatValues = (values) => {
	return {
		is_active: values.is_active === 'all' ? null : values.is_active,
		questions: values.questions?.includes('all') ? null : values.questions,
	};
};
